import { useEffect, useCallback, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { ApolloProvider, ApolloLink, Observable, type ApolloClient } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import Env from "config/environment";
import { SandboxMeetingName } from "graphql_globals";
import { clientFactory } from "common/providers/apollo";
import LoadingIndicator from "common/core/loading_indicator";
import Meeting from "common/meeting/notary";
import { useQuery, useMutation } from "util/graphql";

import { makeResponder, setup } from "./server";
import CompleteSandboxMeetingMutation from "./complete_sandbox_meeting_mutation.graphql";
import MeetingSimulatorInfoQuery from "./index_query.graphql";

type SimulatedClient =
  | { kind: "loading" }
  | { kind: "error"; error: Error }
  | { kind: "done"; client: ApolloClient<unknown> };

function getMeetingNameFromId(meetingId: string) {
  if (meetingId.startsWith("meeting-POA-")) {
    return SandboxMeetingName.POWEROFATTORNEY;
  } else if (meetingId.startsWith("meeting-PS1583-")) {
    return SandboxMeetingName.PS1583;
  }
  return SandboxMeetingName.BASICBUSINESS;
}

function linkFactory(introspectionData: Parameters<typeof makeResponder>[0]) {
  const respond = makeResponder(introspectionData);
  return new ApolloLink((operation) => {
    return new Observable((observer) => {
      try {
        respond(operation)
          .then((result) => {
            setTimeout(
              () => {
                observer.next(result);
                observer.complete();
              },
              process.env.NODE_ENV === "test" ? 0 : 350,
            );
          })
          .catch((e) => {
            observer.error(e);
          });
      } catch (e) {
        observer.error(e);
      }
    });
  });
}

function useSimulatedClient(): null | ApolloClient<unknown> {
  const [simulatedClient, setSimulatedClient] = useState<SimulatedClient>({ kind: "loading" });

  useEffect(() => {
    fetch(Env.graphQLSchema.introspectionDataEndpoint)
      .then((response) => response.json())
      .then((json) => {
        const client = clientFactory(() => linkFactory(json.data));
        setSimulatedClient({ kind: "done", client });
      })
      .catch((error) => {
        setSimulatedClient({ kind: "error", error });
      });
  }, []);

  if (simulatedClient.kind === "error") {
    throw simulatedClient.error;
  }
  return simulatedClient.kind === "done" ? simulatedClient.client : null;
}

function MeetingSimulator() {
  const meetingId = useParams().meetingId!;
  const navigate = useNavigate();
  const handlePostMeeting = useCallback(() => {
    navigate("/");
  }, [navigate]);
  const [handlers, setHandlers] = useState<null | ReturnType<typeof setup>>(null);
  const { data, loading } = useQuery(MeetingSimulatorInfoQuery);
  const completeSandboxMeetingMutateFn = useMutation(CompleteSandboxMeetingMutation);
  const viewer = data?.viewer;
  useEffect(() => {
    if (viewer && !handlers) {
      const name = getMeetingNameFromId(meetingId);
      const meetingHandlers = setup(meetingId, name, viewer, ({ duration, annotationCount }) => {
        const input = { duration, totalAnnotations: annotationCount, name };
        return completeSandboxMeetingMutateFn({
          variables: { input },
        });
      });
      setHandlers(meetingHandlers);
    }
  });
  const simulatedClient = useSimulatedClient();
  return loading || !handlers || !viewer || !simulatedClient ? (
    <LoadingIndicator />
  ) : (
    <ApolloProvider client={simulatedClient}>
      <Meeting
        simulatedNotaryProfile={viewer.user!.notaryProfile!}
        onSelectDocument={handlers.onSelectDocument}
        onPostCompleteMeeting={handlePostMeeting}
        onPostTerminateMeeting={handlePostMeeting}
      />
    </ApolloProvider>
  );
}

export default MeetingSimulator;
