import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import { Heading, Paragraph } from "common/core/typography";
import Button from "common/core/button";
import type { RetakeManager } from "common/identity_verification/retake/notary";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./index.module.scss";
import {
  getGenericDocumentTitleFromType,
  getIndividualRetakeButtonTextFromType,
  type IdentityDocumentViewerEngine,
} from "..";
import { type PhotoRequirements, PhotoType } from "../engine";

type Props = {
  retakeManager?: RetakeManager;
  className?: string;
  engine: IdentityDocumentViewerEngine;
  isSimplifiedViewer?: boolean;
};

function getActionableDocumentContent(type: PhotoType) {
  switch (type) {
    case PhotoType.Primary:
      return (
        <FormattedMessage
          id="2a7ce96e-f25f-4e15-866b-b229c370e59c"
          defaultMessage="A government issued ID is necessary to be captured."
        />
      );
    case PhotoType.Secondary:
      return (
        <FormattedMessage
          id="00c6cb6e-142a-4a93-9639-9b80cc8f257f"
          defaultMessage="Add an additional government ID or piece of evidence that helps resolve any document or state related notarization compliance."
        />
      );
    case PhotoType.Selfie:
      return (
        <FormattedMessage
          id="a49f41fa-7d41-436e-b0e1-92cc90895ae2"
          defaultMessage="A selfie of the signer is used to compare to the ID to complete the meeting."
        />
      );
  }
}

function getReadOnlyDocumentContent(type: PhotoType) {
  switch (type) {
    case PhotoType.Primary:
      return (
        <FormattedMessage
          id="c45aba88-a44d-4c18-bff3-3723553288a6"
          defaultMessage="A government ID was not captured."
        />
      );
    case PhotoType.Secondary:
      return (
        <FormattedMessage
          id="2da10a6a-5647-4d50-b5a2-c572da732c16"
          defaultMessage="A secondary ID was not captured in this transaction. To capture a secondary ID, require a secondary ID or initiate a new IAL2 Proof transaction."
        />
      );
    case PhotoType.Selfie:
      return (
        <FormattedMessage
          id="8c76fd32-205c-4ab6-8885-790d3c05a2b5"
          defaultMessage="Selfie comparison was not captured in this transaction. To capture a selfie comparison, initiate a new IAL2 Proof transaction."
        />
      );
  }
}

function documentTypeIsRequired({
  requirements,
  currentPhotoType,
}: {
  requirements: PhotoRequirements;
  currentPhotoType: PhotoType;
}) {
  switch (currentPhotoType) {
    case PhotoType.Primary:
      return true;
    case PhotoType.Secondary:
      return requirements.secondary;
    case PhotoType.Selfie:
      return requirements.selfie;
  }
}

function ActionableDocumentTitle({
  documentTitle,
  engine: { requirements, currentPhotoType },
}: {
  documentTitle: ReactNode;
  engine: IdentityDocumentViewerEngine;
}) {
  const required = documentTypeIsRequired({ currentPhotoType, requirements });

  return (
    <FormattedMessage
      id="ebcadaea-47ef-4d9c-bb3d-3ccd7fdb2cce"
      defaultMessage="{documentTitle} ({required, select, true {required} other {optional}})"
      values={{ documentTitle, required }}
    />
  );
}

export default function DocumentPlaceholder(props: Props) {
  const { className, retakeManager, engine, isSimplifiedViewer } = props;
  const { currentPhotoType } = engine;
  const singleCredentialRetake = useFeatureFlag("single-credential-retake");

  const documentTitle = getGenericDocumentTitleFromType(currentPhotoType);

  return (
    <div className={className}>
      <div className={Styles.body}>
        <Heading textStyle="headingFive" level="h2">
          {retakeManager ? (
            <ActionableDocumentTitle documentTitle={documentTitle} engine={engine} />
          ) : (
            <FormattedMessage
              id="fc1940af-8218-49bc-9c4c-fa575538ca72"
              defaultMessage="{documentTitle} not captured"
              values={{ documentTitle }}
            />
          )}
        </Heading>
        <Paragraph>
          {!isSimplifiedViewer && retakeManager
            ? getActionableDocumentContent(currentPhotoType)
            : getReadOnlyDocumentContent(currentPhotoType)}
        </Paragraph>
        {!isSimplifiedViewer && retakeManager && (
          <div className={Styles.buttons}>
            {retakeManager.inProgress && (
              <Button onClick={retakeManager.cancel} buttonColor="action" variant="secondary">
                <FormattedMessage
                  id="54212288-622d-4b28-a1ef-36f835e310f7"
                  defaultMessage="Cancel"
                />
              </Button>
            )}

            <Button
              onClick={() =>
                singleCredentialRetake
                  ? retakeManager.initiate({ individualRetake: currentPhotoType })
                  : retakeManager.initiate()
              }
              buttonColor="action"
              variant="secondary"
              isLoading={retakeManager.inProgress}
            >
              {singleCredentialRetake ? (
                getIndividualRetakeButtonTextFromType(currentPhotoType)
              ) : (
                <FormattedMessage
                  id="2073bcde-3f6f-4f7d-beba-acfa8fa715e9"
                  defaultMessage="Prompt retake"
                />
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
