import type { ComponentProps } from "react";

import SignerValidation from "common/signer/items/signer_validation";

import { SignerDetailsHeader } from "./signer_details_header";

type SignerValidationProps = ComponentProps<typeof SignerValidation>;
type Props = {
  headerText: ComponentProps<typeof SignerDetailsHeader>["children"];
  signerIdentity: SignerValidationProps["signerIdentity"];
  notaryState: SignerValidationProps["notaryState"];
  completionRequirements: SignerValidationProps["completionRequirements"];
  organizationFeatures: SignerValidationProps["organizationFeatures"];
  validatedByCredibleWitness: SignerValidationProps["validatedByCredibleWitness"];
};

function MeetingSignerDetails(props: Props) {
  return (
    <div className="MeetingSignerDetails">
      <SignerDetailsHeader>{props.headerText}</SignerDetailsHeader>
      <SignerValidation
        signerIdentity={props.signerIdentity}
        notaryState={props.notaryState}
        completionRequirements={props.completionRequirements}
        organizationFeatures={props.organizationFeatures}
        validatedByCredibleWitness={props.validatedByCredibleWitness}
      />
    </div>
  );
}

export default MeetingSignerDetails;
