import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Icon from "common/core/icon";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";

type Props = {
  completionRequirements: ComponentProps<
    typeof CompletionRequirementsText
  >["completionRequirements"];
  titleClassName?: string;
};

function ValidationStatus({ completionRequirements, titleClassName }: Props) {
  return (
    <DeprecatedDetailGridRow
      title={
        <div className={titleClassName}>
          <CompletionRequirementsText
            stringId="verificationMethod"
            completionRequirements={completionRequirements}
          />
        </div>
      }
    >
      <div className="NotarizationDetails-user--validation-status" data-automation-id="kba-status">
        <Icon name="tick" />{" "}
        <FormattedMessage id="65a5cc8a-23fa-462d-9fdb-eb22d716bc85" defaultMessage="Passed" />
      </div>
    </DeprecatedDetailGridRow>
  );
}

export default ValidationStatus;
