import { Fragment } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import classnames from "classnames";

import { durationString } from "util/meeting";
import { dateComparator } from "util/date";
import { usePermissions } from "common/core/current_user_role";
import { ModalScrollContent } from "common/core/modal_scroll_content";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import EmptyMeeting from "assets/images/no-meeting-state.svg";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";

import Styles from "./index.module.scss";
import Video from "./video";
import VideoQuery, {
  type BundleVideoDetails_documentBundle_DocumentBundle as DocumentBundle,
} from "./index.graphql";

const videoMessages = defineMessages({
  meetingHeader: {
    id: "2d60413f-dd92-4819-9737-6743eae062fc",
    defaultMessage: "Meeting {index, number} - {meetingId}",
  },
  meetingInProgressHeader: {
    id: "9f4472b5-4914-43f2-bfd0-448cc4ceee4d",
    defaultMessage: "Meeting In Progress",
  },
  recording: {
    id: "f0eff35c-f95a-4173-9593-5b916015ecbd",
    defaultMessage: "Recording",
  },
  emptyContent: {
    id: "894a283c-c125-461e-a520-0e280b4bf508",
    defaultMessage:
      "This transaction has no meetings or is still processing, please come back later",
  },
  noPermissionContent: {
    id: "f04b6eee-253c-48a9-a70c-cb2d2adb86c9",
    defaultMessage:
      "Your role in Keystone does not have permission to view this asset. Please reach out to your manager if you feel you need access.",
  },
  noMeetingImage: {
    id: "d8f9e848-2612-4a1b-9a16-729994ad0b30",
    defaultMessage: "no meeting state",
  },
});

type VideoProps = {
  bundle: DocumentBundle;
  showResponsiveView?: boolean;
};

function Videos({ bundle, showResponsiveView }: VideoProps) {
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: intl.formatMessage(useDocumentTitles().documentDetailsVideo),
  });
  const { hasPermissionFor } = usePermissions();

  const meetings = bundle.meetings.edges
    .map((m) => m.node)
    .sort((a, b) => {
      if (b.timeFrame?.startedAt && a.timeFrame?.startedAt) {
        return dateComparator(b.timeFrame.startedAt, a.timeFrame.startedAt);
      } else if (a.timeFrame?.startedAt && !b.timeFrame?.startedAt) {
        return 1;
      }
      return -1;
    });

  if (meetings.some((meeting) => meeting.video?.recording?.url?.includes("restricted-id"))) {
    return (
      <div className="NotarizationDetails--emptyContainer">
        <div className="NotarizationDetails--emptyContent">
          <div className="NotarizationDetails--emptyContent--text">
            {intl.formatMessage(videoMessages.noPermissionContent)}
          </div>
        </div>
      </div>
    );
  }

  if (meetings.length === 0) {
    return (
      <div className="NotarizationDetails--emptyContainer">
        <div className="NotarizationDetails--emptyContent">
          <img
            className="NotarizationDetails--emptyContent--image"
            alt={intl.formatMessage(videoMessages.noMeetingImage)}
            src={EmptyMeeting}
          />
          <div className="NotarizationDetails--emptyContent--text">
            {intl.formatMessage(videoMessages.emptyContent)}
          </div>
        </div>
      </div>
    );
  }
  if (meetings.length === 1) {
    // don't confuse users with no multiple meetings by even suggesting multiple meetings are possible
    return (
      <div
        className={classnames(Styles.videoWrapper, {
          "NotarizationDetails-videos NotarizationDetails-responsive": showResponsiveView,
          [Styles.responsiveWrapper]: showResponsiveView,
        })}
      >
        {showResponsiveView && <div className={Styles.date}>{durationString(meetings[0])}</div>}
        <Video meeting={meetings[0]} useWrapperDiv={!showResponsiveView} />
      </div>
    );
  }

  return (
    <div
      className={classnames(
        {
          "NotarizationDetails-videos NotarizationDetails-responsive": showResponsiveView,
          [Styles.responsiveWrapper]: showResponsiveView,
        },
        Styles.videoDetails,
      )}
    >
      <CollapsibleList isResponsive={showResponsiveView}>
        {meetings.map((meeting, index) => {
          const isLiveMeeting = Boolean(meeting.video?.token);

          if (isLiveMeeting && !hasPermissionFor("observeMeeting")) {
            return null;
          }

          const headerText = isLiveMeeting
            ? intl.formatMessage(videoMessages.meetingInProgressHeader, {
                index: meetings.length - index,
              })
            : intl.formatMessage(videoMessages.meetingHeader, {
                index: meetings.length - index,
                meetingId: meeting.id,
              });
          const centerHeader = isLiveMeeting ? (
            <span className={Styles.meetingRecordingIndicator}>
              {intl.formatMessage(videoMessages.recording)}
            </span>
          ) : null;

          return (
            <CollapsibleListItem
              header={headerText}
              centerHeader={centerHeader}
              subheader={!showResponsiveView && durationString(meeting)}
              key={meeting.id}
              isResponsive={showResponsiveView}
            >
              {showResponsiveView && <div className={Styles.date}>{durationString(meeting)}</div>}
              <Video meeting={meeting} />
            </CollapsibleListItem>
          );
        })}
      </CollapsibleList>
    </div>
  );
}

export default function Container({
  bundle,
  showResponsiveView,
}: {
  bundle?: { id: string };
  showResponsiveView?: boolean;
}) {
  // signer portal uses globalID, but we expect bundle to passed in from transaction details
  const documentBundleId = bundle?.id;
  const { globalID } = useParams();
  const { data, loading } = useQuery(VideoQuery, {
    variables: { documentBundleId: documentBundleId || globalID || "" },
  });

  const Wrapper = showResponsiveView ? Fragment : ModalScrollContent;

  if (loading || !data) {
    return (
      <Wrapper>
        <div className="NotarizationDetails--emptyContainer">
          <LoadingIndicator />
        </div>
      </Wrapper>
    );
  }

  const { documentBundle } = data;

  if (documentBundle?.__typename !== "DocumentBundle") {
    throw new Error(`Expected a document bundle, got ${documentBundle?.__typename}`);
  }
  return (
    <Wrapper>
      <Videos bundle={documentBundle} showResponsiveView={showResponsiveView} />
    </Wrapper>
  );
}
