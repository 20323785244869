import type { ComponentProps } from "react";

import SignerValidation from "common/signer/items/signer_validation";

import { SignerDetailsHeader } from "./signer_details_header";

type SignerValidationProps = ComponentProps<typeof SignerValidation>;
type Props = {
  signerIdentity: SignerValidationProps["signerIdentity"];
  organizationFeatures: SignerValidationProps["organizationFeatures"];
  headerText: ComponentProps<typeof SignerDetailsHeader>["children"];
};

function EsignAuthSignerDetails({ signerIdentity, organizationFeatures, headerText }: Props) {
  return (
    <div>
      <SignerDetailsHeader>{headerText}</SignerDetailsHeader>
      <SignerValidation
        signerIdentity={signerIdentity}
        organizationFeatures={organizationFeatures}
      />
    </div>
  );
}

export default EsignAuthSignerDetails;
