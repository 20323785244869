import { FormattedMessage } from "react-intl";

import { PhotoIdVerificationStatus } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Icon from "common/core/icon";
import { isCredentialAnalysisRequired } from "util/meeting";

type Props = {
  notaryState?: string;
  signerIdentity: null | {
    photoId: null | {
      documentClassification: string | null;
      documentIssuer: string | null;
      statusV2: PhotoIdVerificationStatus;
    };
  };
  titleClassName?: string;
};

function CredentialStatus(props: Props) {
  const photoId = props.signerIdentity?.photoId;
  if (!photoId) {
    return null;
  }

  const { documentIssuer, documentClassification, statusV2 } = photoId;
  const isRequired = Boolean(props.notaryState && isCredentialAnalysisRequired(props.notaryState));
  const isFailureStatus = statusV2 === PhotoIdVerificationStatus.FAILURE;

  // If credential analysis is not required and returns 'unknown', 'action required', or 'pending', we do not show value
  if (!isRequired && !isFailureStatus && statusV2 !== PhotoIdVerificationStatus.SUCCESS) {
    return null;
  }

  return (
    <DeprecatedDetailGridRow
      title={
        <div className={props.titleClassName}>
          <FormattedMessage
            id="072c10a0-cf79-4b1b-bd05-78be7cf6c8d4"
            defaultMessage="Credential Analysis"
          />
        </div>
      }
    >
      <div
        className="NotarizationDetails-user--credential-status"
        data-automation-id="credential-analysis-status"
      >
        <FormattedMessage
          id="0dc13795-ab83-4e19-b5e3-45552590f8d4"
          defaultMessage="{icon} {statusSelect, select, failed{Failed} required{{documentIssuer} {documentClassification}} other{Passed}}"
          values={{
            icon: <Icon name={isFailureStatus ? "x" : "tick"} />,
            // If credential analysis is required, we show the values
            statusSelect: isFailureStatus ? "failed" : isRequired ? "required" : "other",
            documentIssuer,
            documentClassification,
          }}
        />
      </div>
    </DeprecatedDetailGridRow>
  );
}

export default CredentialStatus;
