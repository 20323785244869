import { memo } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import Icon from "common/core/icon";
import { useDocumentTitles } from "util/document_title";
import { Heading, Paragraph } from "common/core/typography";

import Styles from "./confirm_delete.module.scss";

type Props = {
  confirm: () => void;
  cancel: () => void;
  isDeleting: boolean;
};

function ConfirmDelete({ confirm, cancel, isDeleting }: Props) {
  const intl = useIntl();
  return (
    <WorkflowModal
      isSensitive={false}
      documentTitle={intl.formatMessage(useDocumentTitles().deleteDocument)}
      autoFocus
    >
      <div className={Styles.confirmDelete} aria-live="assertive">
        <Heading level="h1" textStyle="headingFive">
          <FormattedMessage
            id="d24866e7-3d9d-4032-b168-33938e8287f1"
            defaultMessage="{icon} Delete this transaction?"
            values={{ icon: <Icon className={Styles.warning} name="doc-warning" size="large" /> }}
          />
        </Heading>
        <Paragraph size="large" aria-live="assertive">
          <FormattedMessage
            id="30027222-972b-4d4e-bbb6-1d8be5dfeff9"
            defaultMessage="Deleted transactions cannot be recovered."
          />
        </Paragraph>
        <div className={Styles.actions}>
          <Button onClick={cancel} variant="tertiary" buttonColor="dark">
            <FormattedMessage id="20adf941-634b-4193-a172-b7d6e61adba6" defaultMessage="Cancel" />
          </Button>
          <Button
            automationId="confirm-delete-button"
            onClick={confirm}
            isLoading={isDeleting}
            buttonColor="danger"
            variant="primary"
          >
            <FormattedMessage id="b34aee65-76a1-4cb9-8aa8-986cb5ac422a" defaultMessage="Delete" />
          </Button>
        </div>
      </div>
    </WorkflowModal>
  );
}

export default memo(ConfirmDelete);
