import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { OrganizationTransactionDetailedStatus, UserRole } from "graphql_globals";
import AlertMessage from "common/core/alert_message";
import Link from "common/core/link";
import { usePermissions } from "common/core/current_user_role";

function AwaitingPaymentAlert() {
  return (
    <AlertMessage banner kind="info" centerText>
      <FormattedMessage
        id="7b4fd21e-2e11-4e2c-af71-3763ee138782"
        defaultMessage="This transaction is awaiting payment. Documents can be accessed once the signer completes payment."
      />
    </AlertMessage>
  );
}

function PaymentFailedAlert() {
  return (
    <AlertMessage banner kind="warning" centerText>
      <FormattedMessage
        id="783c03dd-2fe8-40c5-8586-f9b487895c84"
        defaultMessage="Payment failed. Documents can be accessed once payment is complete. <link>Update payment details</link>"
        values={{
          link: (text: ReactNode) => (
            <Link href="/settings/billing/payment-settings" openInCurrentTab={false}>
              {text}
            </Link>
          ),
        }}
      />
    </AlertMessage>
  );
}

export function TransactionDetailsAlertMessages({
  detailedStatus,
}: {
  detailedStatus: OrganizationTransactionDetailedStatus;
}) {
  const { currentUserRole, hasPermissionFor } = usePermissions();

  const showAlertsToOrgMembers =
    currentUserRole === UserRole.ORGANIZATION_MEMBER &&
    hasPermissionFor("viewOrganizationTransactions");
  const showAwaitingPaymentAlert =
    showAlertsToOrgMembers &&
    detailedStatus === OrganizationTransactionDetailedStatus.AWAITING_PAYMENT;
  const showPaymentFailedAlert =
    showAlertsToOrgMembers &&
    detailedStatus === OrganizationTransactionDetailedStatus.PAYMENT_REQUIRED;

  return (
    <>
      {showAwaitingPaymentAlert && <AwaitingPaymentAlert />}
      {showPaymentFailedAlert && <PaymentFailedAlert />}
    </>
  );
}
