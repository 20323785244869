import { useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import type { SigningAsset } from "common/signer/utils";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import Checkbox from "common/form/inputs/checkbox";
import { userFullName } from "util/user";
import { useDocumentTitles } from "util/document_title";
import { useId } from "util/html";
import { forceAssetRecreation, type SignatureOptionsOrganization } from "util/signature_options";

import Styles from "./index.module.scss";

type Props = {
  onReuse: (size: { height: number; width: number }) => void;
  onCancel: () => void;
  onRecreate?: () => void;
  type: "SIGNATURE" | "INITIALS";
  participant: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    signingAssets: {
      initialsAsset: SigningAsset | null;
      signatureAsset: SigningAsset | null;
    } | null;
  };
  organization?: SignatureOptionsOrganization;
};

export default function ReuseVectorGraphicModal({
  onCancel,
  onRecreate,
  onReuse,
  participant,
  type,
  organization,
}: Props) {
  const intl = useIntl();
  const labelId = useId();
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [assetLoaded, setAssetLoaded] = useState(false);
  const assetRef = useRef<HTMLImageElement>(null);
  const handleAgreementChange = () => {
    setAgreementAccepted(!agreementAccepted);
  };
  const disabled = !agreementAccepted || !assetLoaded;

  const documentTitles = useDocumentTitles();

  const handleConfirmUse = () => {
    if (!assetRef.current) {
      throw new Error("Image data non-existent");
    }

    const { height, width } = assetRef.current;
    onReuse({ height, width });
  };

  // and onRecreate must be defined to check sig options for this dialog
  if (
    onRecreate &&
    forceAssetRecreation({ organization, signingAssets: participant.signingAssets, type })
  ) {
    onRecreate();
    // early return after onRecreate is called
    return null;
  }

  return (
    <WorkflowModal
      autoFocus
      automationId={"reuse-vectorgraphic-modal"}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      title={
        <FormattedMessage
          id="de4427ab-f04f-4f84-8bd8-bd1632bba477"
          defaultMessage={"Confirm {isSignature, select, true {signature} other {initials}}"}
          values={{ isSignature: type === "SIGNATURE" }}
        />
      }
      documentTitle={intl.formatMessage(
        type === "SIGNATURE" ? documentTitles.confirmSignature : documentTitles.confirmInitials,
      )}
      buttons={[
        <Button key="cancel-button" buttonColor="dark" variant="tertiary" onClick={onCancel}>
          <FormattedMessage id="15a9a5cd-f80f-46e8-ab8f-1edc21f2ee9e" defaultMessage="Cancel" />
        </Button>,
        onRecreate && (
          <Button
            buttonColor="action"
            variant="secondary"
            onClick={onRecreate}
            automationId={"recreate-button"}
            key="create-new-button"
          >
            <FormattedMessage
              id="61f36b32-3fcf-4641-9c7f-3c37a59ae631"
              defaultMessage="Create new"
            />
          </Button>
        ),
        <Button
          buttonColor="action"
          variant="primary"
          onClick={handleConfirmUse}
          disabled={disabled}
          automationId="confirm-reuse-button"
          key="reuse-button"
        >
          <FormattedMessage id="3326157e-e206-442e-bc24-089cce95406c" defaultMessage="Reuse" />
        </Button>,
      ]}
    >
      <span className={Styles.name}>{userFullName(participant)}</span>
      <FormattedMessage
        tagName="span"
        id="30bb0f01-c7d5-4233-a5ed-e00b7a8b8e18"
        defaultMessage="We have {isSignature, select, true {this signature} other {these initials}} on file for you."
        values={{ isSignature: type === "SIGNATURE" }}
      />
      <div className={Styles.assetPreview}>
        <img
          crossOrigin="anonymous"
          alt={type.toLowerCase()}
          src={
            type === "SIGNATURE"
              ? participant.signingAssets!.signatureAsset!.png!.url!
              : participant.signingAssets!.initialsAsset!.png!.url!
          }
          ref={assetRef}
          onLoad={() => {
            setAssetLoaded(true);
          }}
          data-automation-id={type === "SIGNATURE" ? "signature-img" : "initials-img"}
        />
      </div>

      <div className={Styles.disclaimer}>
        <Checkbox
          automationId={"reuse-disclaimer-checkbox"}
          checked={agreementAccepted}
          onChange={handleAgreementChange}
          className={Styles.checkbox}
          aria-labelledby={labelId}
        />
        <div id={labelId} onClick={handleAgreementChange} data-automation-id="reuse-disclaimer">
          <FormattedMessage
            id="32fe710e-78b2-4c15-a0c6-c111c72b1978"
            defaultMessage="I agree that the {sigType} shown above will be my electronic {sigType}, and that when applied on a document at my direction, they will be just as legally binding as my pen-and-ink {sigType}."
            values={{ sigType: type.toLowerCase() }}
          />
        </div>
      </div>
    </WorkflowModal>
  );
}
