import { FormattedMessage } from "react-intl";

import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";

import WitnessInfo from "./witness_info";
import type {
  MeetingForWitnessDetail as Meeting,
  MeetingForWitnessDetail_meetingParticipants as MeetingParticipant,
  MeetingForWitnessDetail_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./witness_detail_fragment.graphql";

type Props = {
  meeting: Meeting;
};

function isWitness(participant: MeetingParticipant): participant is WitnessParticipant {
  return participant.__typename === "WitnessParticipant";
}

function WitnessDetail({ meeting }: Props) {
  const witnesses = meeting.meetingParticipants.filter(isWitness);
  const onlyOneWitness = witnesses.length === 1;
  return (
    <div className="NotarizationDetails-witness">
      <CollapsibleList>
        {witnesses.map((witness, index) => (
          <CollapsibleListItem
            key={witness.id}
            header={witness.fullName}
            subheader={
              <FormattedMessage
                id="ba6541aa-8075-4c32-8d98-e11740321455"
                defaultMessage="Witness {witnessNumber, number}"
                values={{ witnessNumber: index + 1 }}
              />
            }
            hideToggle={onlyOneWitness}
          >
            <WitnessInfo witness={witness} />
          </CollapsibleListItem>
        ))}
      </CollapsibleList>
    </div>
  );
}

export default WitnessDetail;
