import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import {
  DocumentBundleMembershipRole,
  AnnotationDesignationType,
  type NotarialActs,
} from "graphql_globals";
import { getCurrentDocumentNode, getCurrentPenholderInSignerParties } from "common/meeting/util";
import { isProofingDocumentInteraction } from "util/meeting";
import { ChatTool } from "common/meeting/toolbar/tool";

import Styles from "./index.module.scss";
import AddWitnessTool from "./add_witness";
import NormalTools from "./normal_tools";
import PrePrintedStatementWalkthroughTools from "./pre_printed_statement";
import ProofingTools from "./proofing_tools";
import type {
  NotaryMeeting_meeting_Meeting as Meeting,
  NotaryMeeting_viewer_user as NotaryUser,
  NotaryMeeting_meeting_Meeting_meetingParticipants_SignerParticipant as SignerParticipant,
  NotaryMeeting_meeting_Meeting_meetingParticipants_WitnessParticipant as WitnessParticipant,
  NotaryMeeting_meeting_Meeting_meetingParticipants_CredibleWitnessParticipant as CredibleWitnessParticipant,
} from "../meeting_query.graphql";

type Props = {
  meeting: Meeting;
  notaryUser: NotaryUser;
  onClickAddWitness: ComponentProps<typeof AddWitnessTool>["onClick"];
  onQuickStampActivate: ComponentProps<typeof NormalTools>["onQuickStampActivate"];
  onCompletePrePrintedWalkthrough: ComponentProps<
    typeof PrePrintedStatementWalkthroughTools
  >["onComplete"];
  onClickCancelOdnWitness: () => void;
  odnRemoteWitnessEnabled: boolean;
} & (
  | {
      /** truthy, means pre printed is active */
      prePrintedWalkthroughPrincipals: string[];
      notarialAct: NotarialActs;
    }
  | {
      prePrintedWalkthroughPrincipals?: null;
      notarialAct?: null;
    }
);

type PenholderParticipant = SignerParticipant | CredibleWitnessParticipant | WitnessParticipant;

function FreeFormTools({
  meeting,
  notaryUser,
  onClickAddWitness,
  onQuickStampActivate,
  onClickCancelOdnWitness,
  odnRemoteWitnessEnabled,
}: Props) {
  const currentDocumentNode = getCurrentDocumentNode(meeting);
  const unfulfilledRequirements = meeting.unfulfilledRequirements;
  const notaryState = notaryUser.notaryProfile!.usState.name;
  const disabled =
    currentDocumentNode.isEnote || unfulfilledRequirements.missingParticipantRoles.length > 0;
  const numWitnessParticipants = meeting.meetingParticipants.filter(
    (p) => p.__typename === "WitnessParticipant",
  ).length;
  const outstandingOdnWitnessRequest = meeting.queuedWitnessRequestCount;
  const hasUnfulfilledNotaryDesignations = currentDocumentNode.annotationDesignations.edges.some(
    ({ node }) => {
      return (
        node.signerRole.role === DocumentBundleMembershipRole.NOTARY &&
        !node.fulfilled &&
        node.type !== AnnotationDesignationType.SEAL
      );
    },
  );
  const activeParticipant = getCurrentPenholderInSignerParties(meeting) as PenholderParticipant;
  const credibleWitness = meeting.meetingParticipants.find(
    (p) => p.__typename === "CredibleWitnessParticipant",
  );

  const textParams = {
    notaryUser,
    activeParticipant,
    credibleWitness,
  };

  return (
    <>
      <FormattedMessage
        id="07518bb0-76b8-4df7-acab-736ee6998346"
        tagName="h4"
        defaultMessage="Actions"
      />
      <AddWitnessTool
        onClick={onClickAddWitness}
        onClickCancel={onClickCancelOdnWitness}
        odnRemoteWitnessEnabled={odnRemoteWitnessEnabled}
        disabled={numWitnessParticipants >= 2}
        waitingForOdnWitness={Boolean(outstandingOdnWitnessRequest)}
      />
      {isProofingDocumentInteraction(currentDocumentNode, notaryState) ? (
        <ProofingTools textParams={textParams} notaryState={notaryState} disabled={disabled} />
      ) : (
        <NormalTools
          textParams={textParams}
          documentId={currentDocumentNode.id}
          disabled={disabled}
          meetingId={meeting.id}
          hasUnfulfilledNotaryDesignations={hasUnfulfilledNotaryDesignations}
          missingParticipantRoles={unfulfilledRequirements.missingParticipantRoles}
          isSequentiallySigning={meeting.sequentiallySigning}
          notaryUser={notaryUser}
          onQuickStampActivate={onQuickStampActivate}
        />
      )}
      <FormattedMessage
        id="c7eca546-8baf-49ad-8c2a-5c7f702f74c7"
        tagName="h4"
        defaultMessage="Support"
      />
      <ChatTool />
    </>
  );
}

function NotaryToolbar(props: Props) {
  return (
    <div className={Styles.toolbar}>
      {props.prePrintedWalkthroughPrincipals ? (
        <PrePrintedStatementWalkthroughTools
          principalIds={props.prePrintedWalkthroughPrincipals}
          onComplete={props.onCompletePrePrintedWalkthrough}
          meeting={props.meeting}
          notaryUser={props.notaryUser}
          notarialAct={props.notarialAct}
        />
      ) : (
        <FreeFormTools {...props} />
      )}
    </div>
  );
}

export default NotaryToolbar;
