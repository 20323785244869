import {
  memo,
  useState,
  useRef,
  useEffect,
  type ReactNode,
  type ReactElement,
  type ComponentProps,
} from "react";
import { defer, tap, switchMap, Observable } from "rxjs";
import type { MeetingSession, AudioVideoObserver, VideoTileState } from "amazon-chime-sdk-js";

import { MeetingParticipantRoles } from "graphql_globals";
import { retryBackoffWithCaptureException, type Subscribed } from "util/rxjs";
import { useSignerMeetingContext } from "common/meeting/context/signer";
import { useFaceDetection } from "common/identity/video_analysis/face";
import type Channel from "socket/channel";
import type { ChildParams } from "common/video_conference";
import type { Devices } from "common/selected_devices_controller";

import type {
  VideoConference_videoConference as MeetingConference,
  VideoConference_videoConference_ChimeVideoConference as ChimeConference,
} from "../index_fragment.graphql";
import { LocalTracks } from "./local";
import {
  createMutedHook,
  createVolumeHook,
  createNetworkQualityHook,
  createVideoDimensionsHook,
} from "./indicators";
import { ChimeLogger } from "./logger";

type MeetingParticipant = {
  id: string;
  userId: string | null;
  parentId: string | null;
  isConnected: boolean;
  role: MeetingParticipantRoles;
};
type Tiles = {
  local: null | VideoTileState;
  remote: Record<string, VideoTileState | undefined>;
};
type SDKInstance = Subscribed<ReturnType<typeof makeSDKInstance>>;
type Props<MP extends MeetingParticipant> = {
  user: { id: string };
  muted: boolean;
  conference: ChimeConference;
  meetingParticipants: MP[];
  publishVideo: boolean;
  selectedDevices: Devices;
  localVideoBackground: ComponentProps<typeof LocalTracks>["videoBackground"];
  children: (p: ChildParams<MP>) => Exclude<ReactNode, undefined>;
};

export const RETRY_BACKOFF_CONFIG = { startWait: 500, maxWait: 3_000 };
const DEFAULT_TILES: Tiles = { local: null, remote: {} };

function makeSDKInstance(
  conference: ChimeConference,
  thisUserAttendee: ChimeConference["attendees"][number],
) {
  return defer(() => import("amazon-chime-sdk-js")).pipe(
    switchMap((module) => {
      module.CSPMonitor.disable();
      const logger = new ChimeLogger(module.LogLevel);
      const deviceController = new module.DefaultDeviceController(logger);
      const { metadata } = conference;
      const configuration = new module.MeetingSessionConfiguration(
        {
          MeetingId: conference.id,
          MediaRegion: metadata.mediaRegion,
          MediaPlacement: {
            AudioFallbackUrl: metadata.audioFallbackUrl,
            AudioHostUrl: metadata.audioHostUrl,
            EventIngestionUrl: metadata.eventIngestionUrl,
            SignalingUrl: metadata.signalingUrl,
            TurnControlUrl: metadata.turnControlUrl,
          },
        },
        {
          AttendeeId: thisUserAttendee.id,
          JoinToken: conference.joinToken,
        },
      );
      const session = new module.DefaultMeetingSession(configuration, logger, deviceController);
      return new Observable<{ session: MeetingSession; module: typeof module }>((observer) => {
        observer.next({ session, module });
        return session.destroy.bind(session);
      }).pipe(
        tap({
          unsubscribe() {
            logger.setLogLevel(module.LogLevel.OFF);
          },
        }),
      );
    }),
    retryBackoffWithCaptureException(RETRY_BACKOFF_CONFIG),
  );
}

function useChimeInstance<MP extends MeetingParticipant>(props: Props<MP>) {
  const [instance, setInstance] = useState<SDKInstance | undefined>();
  const { user, conference } = props;
  const userId = user.id;
  useEffect(() => {
    const thisUserAttendee = conference.attendees.find((attendee) => attendee.userId === userId);
    if (!thisUserAttendee) {
      throw new Error(
        `Unable to find attendee with userId ${userId} in ${conference.attendees.map((a) => a.userId).join(", ")}`,
      );
    }
    const sub = makeSDKInstance(conference, thisUserAttendee).subscribe(setInstance);
    return () => sub.unsubscribe();
  }, [userId]);
  return instance;
}

function useTiles(instance: SDKInstance | undefined): Tiles {
  const session = instance?.session;
  const [tiles, setTiles] = useState(DEFAULT_TILES);
  useEffect(() => {
    if (!session) {
      return setTiles(DEFAULT_TILES);
    }
    const { audioVideo } = session;
    const observer: AudioVideoObserver = {
      videoTileWasRemoved(removedTileId) {
        setTiles((old) => {
          if (removedTileId === old.local?.tileId) {
            return { ...old, local: null };
          }
          const newRemote = Object.fromEntries(
            Object.values(old.remote)
              .filter((oldTile) => oldTile && oldTile.tileId !== removedTileId)
              .map((oldTile) => [oldTile!.boundExternalUserId, oldTile]),
          );
          return { ...old, remote: newRemote };
        });
      },
      videoTileDidUpdate(tileState) {
        setTiles((old) => {
          if (tileState.localTile) {
            return { ...old, local: tileState };
          } else if (tileState.boundExternalUserId && tileState.boundAttendeeId) {
            return {
              ...old,
              remote: { ...old.remote, [tileState.boundExternalUserId]: tileState },
            };
          }
          return old;
        });
      },
    };
    audioVideo.addObserver(observer);
    return () => {
      audioVideo.removeObserver(observer);
    };
  }, [session]);
  return tiles;
}

function RemoteTracks(props: {
  /** Enables facedetection if truthy */
  faceDetectionParticipantId: string | null;
  session: MeetingSession;
  tile: VideoTileState;
}) {
  const ref = useRef<HTMLVideoElement>(null);
  useFaceDetection(props.faceDetectionParticipantId, ref);
  const tileId = props.tile.tileId!;
  useEffect(() => {
    props.session.audioVideo.bindVideoElement(tileId, ref.current!);
    return () => {
      props.session.audioVideo.unbindVideoElement(tileId);
    };
  }, [tileId]);
  return <video ref={ref} />;
}

function useDominantSpeakerAttendeeId(
  instance: SDKInstance | undefined,
  localAttendeeId: string | undefined | null,
) {
  const [dominantSpeakerAttendeeId, setDominantSpeakerAttendeeId] = useState<string>();
  useEffect(() => {
    if (!instance || !localAttendeeId) {
      return;
    }
    const { audioVideo } = instance.session;
    const callback = (rankedActiveSpeakers: string[]) => {
      setDominantSpeakerAttendeeId(rankedActiveSpeakers.find((id) => id !== localAttendeeId));
    };
    audioVideo.subscribeToActiveSpeakerDetector(
      new instance.module.DefaultActiveSpeakerPolicy(),
      callback,
    );
    return () => {
      audioVideo.unsubscribeFromActiveSpeakerDetector(callback);
    };
  }, [instance, localAttendeeId]);
  return dominantSpeakerAttendeeId;
}

function useParties<MP extends MeetingParticipant>(
  props: Props<MP>,
  instance: SDKInstance | undefined,
): ChildParams<MP> {
  const { meetingParticipants, muted } = props;
  const localUserId = props.user.id;
  const session = instance?.session;
  const audioVideo = session?.audioVideo;
  const tiles = useTiles(instance);
  const localAttendeeId = tiles.local?.boundAttendeeId;
  const dominantSpeakerAttendeeId = useDominantSpeakerAttendeeId(instance, localAttendeeId);
  // Assert this as undefined sometimes since this might be a non-signer meeting
  const channel = (useSignerMeetingContext() as undefined | { channel: Channel })?.channel;

  const connectedParentParticipants = meetingParticipants.filter(
    (participant) => participant.isConnected && !participant.parentId,
  );
  const localPartyParent = connectedParentParticipants.find((p) => p.userId === localUserId)!;
  const isLocalPartyNotASpectator = localPartyParent.role !== MeetingParticipantRoles.SPECTATOR;
  const isLocalPartyANotary = localPartyParent.role === MeetingParticipantRoles.NOTARY;
  return {
    localParty: {
      id: localPartyParent.id,
      isLocal: true,
      role: localPartyParent.role,
      participants: [localPartyParent].concat(
        meetingParticipants.filter((p) => p.parentId === localPartyParent.id),
      ),
      track: session && (
        <LocalTracks
          session={session}
          muted={muted}
          signerResetConnectionParams={
            channel ? { userId: localPartyParent.userId!, channel } : undefined
          }
          publishVideo={props.publishVideo}
          participantRole={localPartyParent.role}
          selectedDevices={props.selectedDevices}
          sdkModule={instance.module}
          videoBackground={props.localVideoBackground}
        />
      ),
      useNetworkQuality: createNetworkQualityHook(
        audioVideo,
        localAttendeeId,
        isLocalPartyNotASpectator,
      ),
      useMuted: () => muted,
      useVolume: createVolumeHook(audioVideo, localAttendeeId, isLocalPartyNotASpectator),
    },
    remoteParties: connectedParentParticipants
      .filter((p) => p.id !== localPartyParent.id)
      .map((participant) => {
        const { id: participantId } = participant;
        const tile = session && tiles.remote[participant.userId!];
        const tileAttendeeId = tile?.boundAttendeeId;
        const enableFaceDetectionReporting =
          isLocalPartyANotary && participant.role === MeetingParticipantRoles.SIGNER;
        return {
          id: participantId,
          isLocal: false,
          role: participant.role,
          participants: [participant].concat(
            meetingParticipants.filter((p) => p.parentId === participantId),
          ),
          track: tile ? (
            <RemoteTracks
              session={session}
              tile={tile}
              faceDetectionParticipantId={enableFaceDetectionReporting ? participantId : null}
            />
          ) : null,
          screenTrack: null,
          isDominantSpeaker: Boolean(
            tileAttendeeId && dominantSpeakerAttendeeId === tileAttendeeId,
          ),
          useVideoIsConnected: () => Boolean(tile?.boundVideoElement),
          useVideoTrackDimensions: createVideoDimensionsHook(tile),
          useNetworkQuality: createNetworkQualityHook(audioVideo, tileAttendeeId, true),
          useMuted: createMutedHook(audioVideo, tileAttendeeId),
          useVolume: createVolumeHook(audioVideo, tileAttendeeId, true),
        };
      }),
  };
}

function AmazonChime<MP extends MeetingParticipant>(props: Props<MP>) {
  const instance = useChimeInstance(props);
  return props.children(useParties(props, instance)) as ReactElement;
}

export function isChimeConference(
  meetingConference: MeetingConference,
): meetingConference is ChimeConference {
  return meetingConference.__typename === "ChimeVideoConference";
}

export default memo(AmazonChime) as typeof AmazonChime;
