import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import {
  PhotoIdVerificationStatus,
  UserRole,
  ValidationProvider,
  VerificationMethod,
} from "graphql_globals";
import { isCredentialAnalysisRequired } from "util/meeting";
import { AVAILABLE_FEATURES } from "constants/organization";
import { usePermissions } from "common/core/current_user_role";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Icon from "common/core/icon";
import { FormattedValidationSource } from "common/core/format/formatted_validation_source";
import { FormattedVerificationMethod } from "common/core/format/formatted_verification_method";
import { PROOF_TRANSACTIONS } from "constants/feature_gates";
import { useFeatureFlag } from "common/feature_gating";

import PhotoId from "./photo_id";
import { LivenessCheck } from "./liveness_check";
import CredentialStatus from "./credential_status";
import ValidationStatus from "./validation_status";
import { BiometricVerificationResults } from "./biometric_verification_results";
import type { SignerIdentityForValidation as SignerIdentity } from "./signer_validation_fragment.graphql";

type Props = {
  signerIdentity: SignerIdentity;
  notaryState?: ComponentProps<typeof CredentialStatus>["notaryState"];
  completionRequirements?: ComponentProps<typeof ValidationStatus>["completionRequirements"];
  organizationFeatures: string[];
  validatedByCredibleWitness?: boolean;
};

function PersonallyKnownStatus({ passed }: { passed?: boolean }) {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="3ee464cc-6acc-46df-b17d-b1d1978aff92"
          defaultMessage="Personally Known to Notary"
        />
      }
    >
      <FormattedMessage
        id="3ee464cc-6acc-46df-b17d-b1d1978aff92"
        defaultMessage="{icon} {passed, select, true{Confirmed} other{Unconfirmed}}"
        values={{
          icon: <Icon name={passed ? "tick" : "x"} />,
          passed: Boolean(passed),
        }}
      />
    </DeprecatedDetailGridRow>
  );
}

function CredibleWitnessStatus() {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="fd666467-e7f5-4876-a02e-6bdc38c4876f"
          defaultMessage="Identification by Credible Witness"
        />
      }
    >
      <FormattedMessage
        id="921a412e-b6fa-4209-8141-445188e8ab54"
        defaultMessage="{icon} Passed"
        values={{ icon: <Icon name="tick" /> }}
      />
    </DeprecatedDetailGridRow>
  );
}

function VerificationMethodRow(props: {
  verificationMethod: ComponentProps<typeof FormattedVerificationMethod>["verificationMethod"];
}) {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="9323f990-91bb-4e96-99e9-6247b79ce850"
          defaultMessage="Verification Method"
        />
      }
    >
      <FormattedVerificationMethod verificationMethod={props.verificationMethod} />
    </DeprecatedDetailGridRow>
  );
}

function ValidationSource(props: {
  validationSource: ComponentProps<typeof FormattedValidationSource>["validationSource"];
}) {
  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="788edc7e-3d0a-4fc2-b050-2e1cfca9ca0a"
          defaultMessage="Validation Source"
        />
      }
    >
      <FormattedValidationSource validationSource={props.validationSource} />
    </DeprecatedDetailGridRow>
  );
}

function Verification(props: {
  signerIdentity: SignerIdentity;
  notaryState: ComponentProps<typeof CredentialStatus>["notaryState"];
}) {
  const { signerIdentity, notaryState } = props;
  const { photoId } = signerIdentity;
  const esignAuthEnabled = useFeatureFlag(PROOF_TRANSACTIONS);
  if (esignAuthEnabled && photoId?.verificationMethod === VerificationMethod.BIOMETRIC) {
    return <BiometricVerificationResults photoId={photoId} />;
  }
  if (
    Boolean(notaryState && isCredentialAnalysisRequired(notaryState)) ||
    photoId?.statusV2 === PhotoIdVerificationStatus.SUCCESS
  ) {
    return <CredentialStatus signerIdentity={signerIdentity} notaryState={notaryState} />;
  }
  return null;
}

function SignerValidation(props: Props) {
  const { signerIdentity, notaryState } = props;
  const { photoId } = signerIdentity;
  const { hasPermissionFor, currentUserRole } = usePermissions();
  const canViewPhotoId =
    hasPermissionFor("viewPhotoId") ||
    props.organizationFeatures.includes(AVAILABLE_FEATURES.SHOW_PICTURE_IDS);
  const selfiePicture = photoId?.selfiePicture;
  const esignAuthEnabled = useFeatureFlag(PROOF_TRANSACTIONS);

  return (
    <DeprecatedDetailGridSection>
      {props.validatedByCredibleWitness ? (
        <CredibleWitnessStatus />
      ) : signerIdentity.personallyKnownToNotary ? (
        <PersonallyKnownStatus passed={signerIdentity.notaryConfirmedPersonallyKnownToNotary} />
      ) : (
        <>
          {signerIdentity.kbaRequired && (
            <ValidationStatus completionRequirements={props.completionRequirements!} />
          )}
          {(currentUserRole !== UserRole.ADMIN || hasPermissionFor("credentialVerification")) && (
            <Verification signerIdentity={signerIdentity} notaryState={notaryState} />
          )}
          {photoId?.verificationMethod && (
            <VerificationMethodRow verificationMethod={photoId.verificationMethod} />
          )}
          {photoId?.provider === ValidationProvider.PERSONA && (
            <ValidationSource validationSource={photoId.provider} />
          )}
        </>
      )}
      {canViewPhotoId && photoId?.frontPicture && photoId.backPicture && (
        <PhotoId
          signerIdentity={
            signerIdentity as {
              secondaryId: null | { url: string };
              photoId: { frontPicture: string; backPicture: string };
            }
          }
        />
      )}
      {esignAuthEnabled && canViewPhotoId && selfiePicture && (
        <LivenessCheck selfiePicture={selfiePicture} />
      )}
    </DeprecatedDetailGridSection>
  );
}

export default SignerValidation;
