import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { PhotoIdVerificationStatus } from "graphql_globals";
import Icon from "common/core/icon";

type Props = {
  status: PhotoIdVerificationStatus;
};

function FormattedPhotoIdStatus({ status }: Props) {
  switch (status) {
    case PhotoIdVerificationStatus.SUCCESS:
      return (
        <>
          <Icon name="tick" />{" "}
          <FormattedMessage id="759b7ac3-da6e-4e59-bc3e-ee24230005d4" defaultMessage="Passed" />
        </>
      );
    case PhotoIdVerificationStatus.FAILURE:
      return (
        <>
          <Icon name="x" />{" "}
          <FormattedMessage id="3b1f201a-a426-4daf-b06e-999b9dfcd14b" defaultMessage="Failed" />
        </>
      );
    case PhotoIdVerificationStatus.PENDING:
      return (
        <FormattedMessage id="c017c76f-c3b0-4b82-bc65-d749cef34e98" defaultMessage="Pending" />
      );
    case PhotoIdVerificationStatus.ACTION_REQUIRED:
      return (
        <FormattedMessage
          id="7a985cdd-9c4d-4e21-990b-96c6c513d0a0"
          defaultMessage="Action required"
        />
      );
    case PhotoIdVerificationStatus.UNKNOWN:
      return (
        <FormattedMessage id="59be2049-89cf-411c-b58d-24d808ad340e" defaultMessage="Unknown" />
      );
  }
}

const Memoized = memo(FormattedPhotoIdStatus);
export { Memoized as FormattedPhotoIdStatus };
