import type { ComponentProps } from "react";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";

import PointsOfContact from "./points_of_contact";
import Signer from "./signer";

export function RecipientsContainer({
  bundle,
  meetingIds,
  refetch,
  organization,
  transaction,
  canEdit,
}: ComponentProps<typeof Signer> & ComponentProps<typeof PointsOfContact>) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const isBusinessPortal = CURRENT_PORTAL === "business";
  return isTxnDetailsRedesign ? (
    <>
      <Signer bundle={bundle} meetingIds={meetingIds} refetch={refetch} />
      {!isBusinessPortal && (
        <PointsOfContact
          organization={organization}
          transaction={transaction}
          canEdit={canEdit}
          refetch={refetch}
        />
      )}
    </>
  ) : (
    <Signer bundle={bundle} meetingIds={meetingIds} refetch={refetch} />
  );
}
