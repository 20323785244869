import "./index.scss";

import { useState, type ReactElement } from "react";
import { defineMessages, useIntl, type IntlShape } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import FormGroupErrors from "common/form/group_errors";
import FormRow from "common/form/elements/row";
import TextareaField from "common/form/fields/text_area";
import WorkflowModal from "common/modals/workflow_modal";
import { addError } from "redux/actions/errors";
import store from "redux/store";
import { validatePresence } from "validators/form";
import compose from "util/compose";
import { getFormErrors, getFormValues } from "util/form";
import { ERROR_TYPES } from "constants/errors";

type FormValues = {
  recallReason: string;
};
type Props = {
  onRecall: (values: FormValues) => Promise<unknown>;
  onClose: () => void;
};
type InnerProps = Props &
  InjectedFormProps<FormValues, Props> & {
    intl: IntlShape;
    formErrors: Record<keyof FormValues, boolean>;
    formValues: FormValues;
  };

const messages = defineMessages({
  recall: {
    id: "7fa9f834-43b3-4c41-b894-e5c12a0a748b",
    defaultMessage: "Recall",
  },
  cancel: {
    id: "93f87933-fc2c-4247-893d-931616977c95",
    defaultMessage: "Cancel",
  },
  recallError: {
    id: "fb391fd9-f1fc-4fb8-9a59-ec17b83180b2",
    defaultMessage: "The transaction cannot be recalled.",
  },
  recallConvertedToWetSignError: {
    id: "53365b66-c09a-4810-88c4-4ee742f69f82",
    defaultMessage:
      "Transactions that have been converted to wet sign cannot be recalled. Please redraw the documents if they need to be changed.",
  },
  title: {
    id: "f883a067-22f9-4f1c-8110-39da7cfdf9e6",
    defaultMessage: "Recall this transaction?",
  },
  warning: {
    id: "3b4c4232-a377-4ace-86ce-4849eb2d0d9b",
    defaultMessage:
      "The transaction will be put back into draft state, and will have to be resent to the signers.",
  },
  recallReasonPlaceholder: {
    id: "9d8df87e-c2e4-462f-8690-f15f5065a290",
    defaultMessage:
      "Let the closing team, signers and others know why this transaction is being recalled.",
  },
});

const CX_NAME = "RecallTransactionModal";

function validate(values: FormValues) {
  return validatePresence({ field: "recallReason", label: "Recall reason" })(values);
}

function RecallTransactionModal(props: InnerProps) {
  const [isRecalling, setIsRecalling] = useState(false);
  const intl = useIntl();

  const recallTransaction = () => {
    setIsRecalling(true);
    props.onRecall(props.formValues).catch((error) => {
      const errorString =
        error?.message === "invalid_transaction_status"
          ? messages.recallConvertedToWetSignError
          : messages.recallError;
      store.dispatch(addError(intl.formatMessage(errorString), ERROR_TYPES.REGULAR));
      setIsRecalling(false);
    });
  };

  return (
    <WorkflowModal
      className={CX_NAME}
      title={intl.formatMessage(messages.title)}
      buttons={[
        <Button
          variant="tertiary"
          buttonColor="dark"
          key="cancel"
          onClick={() => {
            setIsRecalling(false);
            props.onClose();
          }}
          automationId="cancel-recall-button"
        >
          {intl.formatMessage(messages.cancel)}
        </Button>,
        <Button
          buttonColor="danger"
          variant="primary"
          key="recall"
          onClick={recallTransaction}
          isLoading={isRecalling}
          automationId="confirm-recall-button"
          disabled={Boolean(props.formErrors.recallReason)}
        >
          {intl.formatMessage(messages.recall)}
        </Button>,
      ]}
      footerSeparator={false}
      spaceBetweenButtons
    >
      {intl.formatMessage(messages.warning)}
      <FormRow>
        <TextareaField
          className={`${CX_NAME}--reason`}
          name="recallReason"
          placeholder={intl.formatMessage(messages.recallReasonPlaceholder)}
          useStyledInput
          data-automation-id="recall-reason"
        />

        <FormGroupErrors fields={["recallReason"]} alert />
      </FormRow>
    </WorkflowModal>
  );
}

export default compose(
  reduxForm<FormValues, Props>({ form: "recallTransaction", validate }),
  getFormErrors("recallTransaction"),
  getFormValues("recallTransaction"),
)(RecallTransactionModal as unknown as () => ReactElement) as unknown as (
  props: Props,
) => ReactElement;
