import { useEffect, useState, memo, type ComponentProps } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";

import { useToggleBooleanState } from "util/effects";
import { SENSITIVE_CLASS } from "common/core/sensitive_label";
import { fromSocketEvent } from "socket/util";
import type useSignerConnectionState from "common/meeting/notary/signer_connection_state";
import { MeetingSidebarParty, MeetingSidebarPartyInfo } from "common/meeting/sidebar/party";
import UserFullName from "common/user/user_full_name";
import { Track } from "common/meeting/sidebar/party/track";
import { SignerJoinTimer } from "common/meeting/notary/sidebar/timer";
import { TrustedRefereeAlertAVIssue } from "common/meeting/notary/sidebar/alert_av_issue_action";
import PopoutMenuItem from "common/core/popout_menu/item";
import { NOTIFICATION_TYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import AVResetIcon from "assets/images/refresh_video.svg";
import InfoIcon from "assets/images/info_outline_black.svg";
import { VideoCondenseAction } from "common/meeting/sidebar/party/actions";
import { TrustedRefereeNotaryMeetingInfo } from "common/meeting/notary/sidebar/meeting_info";
import { useTrustedRefereeNotaryMeetingContext } from "common/meeting/trusted_referee/context";
import type { RemoteParty } from "common/video_conference";

import type {
  TrustedRefereeNotaryMeetingSidebar as Meeting,
  TrustedRefereeNotaryMeetingSidebar_meetingParticipants as MeetingParticipant,
  TrustedRefereeNotaryMeetingSidebar_meetingParticipants_SignerParticipant as SignerParticipant,
} from "./index.graphql";

import Styles from "common/meeting/notary/sidebar/signer_party.module.scss";

type Props = {
  meeting: Meeting;
  party: RemoteParty<MeetingParticipant>;
  signerConnectionState?: ReturnType<typeof useSignerConnectionState>;
};
type DeviceStatus = ComponentProps<typeof TrustedRefereeNotaryMeetingInfo>["deviceStatus"];

const MESSAGES = defineMessages({
  popoutButtonLabel: {
    id: "b37c00bc-5b66-4778-8c96-843aeda24129",
    defaultMessage: "Expand signer party controls",
  },
});

function useDeviceInfo(channel: Parameters<typeof fromSocketEvent>[0], party: Props["party"]) {
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>();
  useEffect(() => {
    const sub = fromSocketEvent<DeviceStatus>(channel, "device_status").subscribe({
      next: (data) => {
        const signerUserId = party.participants[0].userId;
        if (signerUserId === data?.actingUserId) {
          setDeviceStatus(data);
        }
      },
    });
    return () => sub.unsubscribe();
  }, [channel]);

  const [deviceInfoOpen, toggleDeviceInfo] = useToggleBooleanState(false);
  return {
    toggleDeviceInfo,
    deviceInfoOpen,
    deviceStatus,
  };
}

function getSignerResetNotification() {
  return {
    type: NOTIFICATION_TYPES.MEETING,
    title: (
      <FormattedMessage
        id="1b0482c3-cf6f-422c-a095-fd099aee41b6"
        defaultMessage="Reset Signer Connection Sent"
      />
    ),
    message: (
      <FormattedMessage
        id="acffaffc-aff3-4b63-9d39-f067b550ca0c"
        defaultMessage="Signer will attempt to reset their connection to the meeting"
      />
    ),
  };
}

function SignerParty({ party, meeting, signerConnectionState }: Props) {
  const { participants } = party;
  const intl = useIntl();
  const { channel, analytics } = useTrustedRefereeNotaryMeetingContext();
  const { toggleDeviceInfo, deviceInfoOpen, deviceStatus } = useDeviceInfo(channel, party);
  const videoStatus =
    signerConnectionState === "Disconnected_Callback" ||
    signerConnectionState === "Disconnected_Time"
      ? null
      : deviceStatus?.signerView === "retake_photo"
        ? "retake_photo"
        : deviceStatus?.appStatus === "background"
          ? "background"
          : null;

  const networkQuality = party.useNetworkQuality();
  const primarySignerParticipant = participants.find(
    (participant) => !participant.parentId,
  )! as SignerParticipant;

  return (
    <>
      <MeetingSidebarParty
        track={(trackProps) => (
          <Track
            track={
              <div className={Styles.timerTrack}>
                {signerConnectionState !== "Connected" && <SignerJoinTimer />}
                {party.track}
              </div>
            }
            networkQuality={networkQuality}
            isCompleted={false}
            videoStatus={videoStatus}
            trackButtonAriaLabel={intl.formatMessage(MESSAGES.popoutButtonLabel)}
            popoutMenuProps={{
              children: ({ close }) => (
                <>
                  <TrustedRefereeAlertAVIssue
                    meeting={meeting}
                    activeParticipantId={primarySignerParticipant.id}
                  />
                  <PopoutMenuItem
                    onClick={() => {
                      channel.sendMessage("reset_connection", {
                        user_id: primarySignerParticipant.userId,
                      });
                      analytics.onResetConnection();
                      pushNotification(getSignerResetNotification());
                      close();
                    }}
                    backgroundImage={AVResetIcon}
                    data-automation-id="reset-connection"
                  >
                    <FormattedMessage
                      id="9878cbaf-6fd8-430d-beb9-6bd80499aa8c"
                      defaultMessage="Reset Signer A/V"
                    />
                  </PopoutMenuItem>

                  <PopoutMenuItem onClick={toggleDeviceInfo} backgroundImage={InfoIcon}>
                    <FormattedMessage
                      id="b6a61be1-71b9-4a2d-a3f7-18cb12afc9c4"
                      defaultMessage="Meeting Information"
                    />
                  </PopoutMenuItem>

                  <VideoCondenseAction
                    videoCondensed={trackProps.videoCondensed}
                    toggleVideoCondensed={trackProps.toggleVideoCondensed}
                  />
                </>
              ),
            }}
          />
        )}
        connectionState={signerConnectionState}
      >
        <MeetingSidebarPartyInfo
          header={
            <span className={SENSITIVE_CLASS}>
              <UserFullName user={participants[0]} wrap />
              {participants[0].pronouns && (
                <span className={Styles.pronouns}> ({participants[0].pronouns})</span>
              )}
            </span>
          }
        />
        {deviceInfoOpen && (
          <TrustedRefereeNotaryMeetingInfo
            organizationName={meeting.organizationName}
            participant={primarySignerParticipant}
            deviceStatus={deviceStatus}
            onExit={toggleDeviceInfo}
          />
        )}
      </MeetingSidebarParty>
    </>
  );
}

export default memo(SignerParty);
