import { useEffect, type MutableRefObject } from "react";
import { defer, timer, repeat, switchMap, scan, takeWhile, takeLast } from "rxjs";

import { useMutation } from "util/graphql";
import { useFeatureFlag } from "common/feature_gating";
import { retryWhenWithCaptureException, type Subscribed } from "util/rxjs";

import CollectParticipantFaceDetectionMutation, {
  type CollectParticipantFaceDetection,
  type CollectParticipantFaceDetectionVariables,
} from "./collect_participant_face_detection_mutation.graphql";

// eslint-disable-next-line @typescript-eslint/consistent-type-imports
type FaceAPI = typeof import("@vladmandic/face-api");
type FaceDetectorResult = Subscribed<ReturnType<typeof makeFaceDetectorForElement>>;
type FlaggedFaceDetectionBehavior = {
  rateMs?: number;
  maxSlowResults?: number;
  slowResultThresholdMs?: number;
};

const LOG_PREFIX = "[CLIENT-FACE-DETECTION] ";
const MODEL_URL = "/face-detection-assets";

async function loadFaceDetection(): Promise<FaceAPI> {
  const faceApi = await import("@vladmandic/face-api");
  await Promise.all([
    faceApi.nets.ssdMobilenetv1.load(MODEL_URL),
    faceApi.nets.ageGenderNet.load(MODEL_URL),
    faceApi.nets.faceLandmark68Net.load(MODEL_URL),
  ]);
  return faceApi;
}

async function configureTensorflow(faceApi: FaceAPI) {
  const tf = faceApi.tf as unknown as FaceAPI["tf"] & {
    setBackend: (be: "webgl" | "wasm") => Promise<void>;
    ready: () => Promise<void>;
  };
  await tf.setBackend("webgl");
  await tf.ready();
}

async function setupFaceDetection() {
  const faceApi = await loadFaceDetection();
  await configureTensorflow(faceApi);
  return faceApi;
}

function makeFaceDetectorForElement(
  faceApi: FaceAPI,
  videoElementRef: MutableRefObject<HTMLVideoElement | null>,
) {
  const options = new faceApi.SsdMobilenetv1Options({
    minConfidence: 0.2,
    maxResults: 3,
  });
  return defer(async () => {
    const videoElement = videoElementRef.current;
    if (!videoElement || videoElement.paused) {
      return;
    }
    const startMs = window.performance.now();
    const detections = await faceApi
      .detectAllFaces(videoElement, options)
      .withFaceLandmarks()
      .withAgeAndGender()
      .run();
    return {
      detections,
      timingMs: window.performance.now() - startMs,
    };
  });
}

async function collectResult(
  collectParticipantFaceDetectionMutateFn: ReturnType<
    typeof useMutation<CollectParticipantFaceDetection, CollectParticipantFaceDetectionVariables>
  >,
  meetingParticipantId: string,
  result: FaceDetectorResult,
): Promise<FaceDetectorResult> {
  if (!result) {
    return;
  }
  const detections = result.detections.map((item) => {
    const { angle, detection } = item;
    const { box } = detection;
    return {
      faceConfidence: detection.score,

      age: Math.round(item.age),

      anglePitch: angle.pitch,
      angleRoll: angle.roll,
      angleYaw: angle.yaw,

      sex: item.gender,
      sexEvalConfidence: item.genderProbability,

      imageWidth: detection.imageWidth,
      imageHeight: detection.imageHeight,

      boundingBoxWidth: Math.round(box.width),
      boundingBoxHeight: Math.round(box.height),
      boundingBoxX: Math.round(box.x),
      boundingBoxY: Math.round(box.y),
    };
  });
  await collectParticipantFaceDetectionMutateFn({
    variables: { input: { meetingParticipantId, detections } },
  });
  return result;
}

/** Attach client-side face detection to a participant's video element */
export function useFaceDetection(
  /** Falsy means disabled */
  participantId: string | null,
  videoElementRef: MutableRefObject<HTMLVideoElement | null>,
) {
  const flaggedBehavior = useFeatureFlag<FlaggedFaceDetectionBehavior | null>(
    "client-face-detection-behavior",
    {},
  );
  const collectParticipantFaceDetectionMutateFn = useMutation(
    CollectParticipantFaceDetectionMutation,
  );
  useEffect(() => {
    if (!participantId) {
      return;
    }
    const { rateMs, maxSlowResults = 4, slowResultThresholdMs = 200 } = flaggedBehavior || {};
    if (!rateMs || rateMs <= 0) {
      // Without a rate, we don't detect. (We use this in LD to turn off the feature).
      return;
    }

    const faceDetection$ = defer(setupFaceDetection).pipe(
      switchMap((faceApi) => {
        return makeFaceDetectorForElement(faceApi, videoElementRef).pipe(
          switchMap((result) =>
            collectResult(collectParticipantFaceDetectionMutateFn, participantId, result),
          ),
          repeat({ delay: () => timer(rateMs) }),
        );
      }),
      retryWhenWithCaptureException({ delay: () => timer(15_000) }),
    );
    const slowResultsTracking$ = faceDetection$.pipe(
      scan<FaceDetectorResult, number[]>((slowResults, result) => {
        return result && result.timingMs >= slowResultThresholdMs
          ? slowResults.concat(result.timingMs)
          : slowResults;
      }, []),
      takeWhile((slowResults) => slowResults.length < maxSlowResults, true),
      takeLast(1),
    );
    const sub = slowResultsTracking$.subscribe((slowResults) => {
      // eslint-disable-next-line no-console
      console.log(
        `${LOG_PREFIX}Giving up after ${maxSlowResults} slow results: ${JSON.stringify(slowResults)}`,
      );
    });
    return () => sub.unsubscribe();
  }, [flaggedBehavior, collectParticipantFaceDetectionMutateFn, participantId]);
}
