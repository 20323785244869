import { v4 } from "uuid";

import { format } from "common/core/format/date";
import { DATE_ANNOTATION_FORMAT } from "constants/annotations";
import { AnnotationSubtype, AnnotationGraphicTypes } from "graphql_globals";

import { getNotaryUser } from "./user";
import { byDocId } from "./document";
import type { OverrideLookup } from ".";

type FakeAnnotation = {
  id: string;
  documentId: string;
  size: { width: number; height: number };
  kind?: AnnotationGraphicTypes;
  location: {
    page: number;
    point: Record<string, unknown>;
  };
};

let ANNOTATIONS_DB: FakeAnnotation[];

function countPage(accum: Record<string, number>, { location: { page } }: FakeAnnotation) {
  return { ...accum, [page]: (accum[page] || 0) + 1 } as Record<string, number>;
}

function makeId() {
  return `at-${v4()}`;
}

export function setupAnnotations() {
  ANNOTATIONS_DB = [];
}

export function getAnnotations(documentId?: string) {
  return documentId
    ? ANNOTATIONS_DB.filter((anno) => anno.documentId === documentId)
    : ANNOTATIONS_DB;
}

export const ANNOTATION_TYPE_RESOLVERS: OverrideLookup = Object.freeze({
  RemoveManyMeetingAnnotationsPayload: (root, args) => {
    const annotationIds = new Set(args.input.annotationIds);
    ANNOTATIONS_DB = ANNOTATIONS_DB.filter((anno) => !annotationIds.has(anno.id));
    return {};
  },
  RemoveAnnotationPayload: (root, args) => {
    const { id } = args.input;
    ANNOTATIONS_DB = ANNOTATIONS_DB.filter((anno) => anno.id !== id);
    return { errors: null };
  },
  UpdateAnnotationTextPayload: (root, args) => {
    const { id, text, width } = args.input;
    const oldAnno = ANNOTATIONS_DB.find((a) => a.id === id)!;
    const newAnno = {
      ...oldAnno,
      size: {
        ...oldAnno.size,
        width,
      },
      text,
    };
    ANNOTATIONS_DB = ANNOTATIONS_DB.map((anno) => {
      return anno.id === id ? newAnno : anno;
    });
    return {
      annotation: newAnno,
      errors: null,
    };
  },
  UpdateAnnotationSizePayload: (root, args) => {
    const { id, size } = args.input;
    const oldAnno = ANNOTATIONS_DB.find((a) => a.id === id)!;
    const newAnno = {
      ...oldAnno,
      size: {
        ...oldAnno.size,
        ...size,
      },
    };
    ANNOTATIONS_DB = ANNOTATIONS_DB.map((anno) => {
      return anno.id === id ? newAnno : anno;
    });
    return {
      annotation: newAnno,
      errors: null,
    };
  },
  UpdateAnnotationLocationPayload: (root, args) => {
    const { id, location } = args.input;
    const oldAnno = ANNOTATIONS_DB.find((a) => a.id === id)!;
    const newAnno = {
      ...oldAnno,
      location: {
        ...oldAnno.location,
        point: {
          ...oldAnno.location.point,
          ...location.point,
        },
      },
    };
    ANNOTATIONS_DB = ANNOTATIONS_DB.map((anno) => {
      return anno.id === id ? newAnno : anno;
    });
    return {
      annotation: newAnno,
      errors: null,
    };
  },
  AddWhiteboxAnnotationPayload: (root, args) => {
    const { size, location, documentId, authorId, annotationDesignationId, meetingId } = args.input;
    const newAnnotation = {
      id: makeId(),
      createdAt: new Date().toISOString(),
      annotationDesignationId: annotationDesignationId || null,
      authorId,
      meetingId,
      documentId,
      editable: true,
      static: false,
      location,
      size,
      subtype: AnnotationSubtype.WHITEBOX,
      text: null,
      __typename: "WhiteboxAnnotation",
    };
    ANNOTATIONS_DB.push(newAnnotation);
    return {
      annotation: newAnnotation,
      annotationDesignation: null,
      errors: null,
    };
  },
  AddTextAnnotationPayload: (root, args) => {
    const {
      size,
      editable,
      location,
      text,
      documentId,
      authorId,
      annotationDesignationId,
      newSubtype,
      meetingId,
    } = args.input;
    const newAnnotation = {
      id: makeId(),
      createdAt: new Date().toISOString(),
      annotationDesignationId: annotationDesignationId || null,
      authorId,
      documentId,
      meetingId,
      editable: Boolean(editable),
      static: !editable,
      location,
      size,
      subtype: newSubtype || AnnotationSubtype.FREE_TEXT,
      text,
      __typename: "TextAnnotation",
    };
    ANNOTATIONS_DB.push(newAnnotation);
    return {
      annotation: newAnnotation,
      annotationDesignation: null,
      errors: null,
    };
  },
  AddVectorGraphicAnnotationPayload: (root, args) => {
    const {
      size,
      location,
      documentId,
      authorId,
      annotationDesignationId,
      notarialAct,
      meetingId,
      subtype,
    } = args.input;
    const notaryUser = getNotaryUser();
    const isSignatureSubtype =
      subtype === AnnotationSubtype.SIGNATURE || subtype === AnnotationSubtype.NOTARY_SIGNATURE;
    const asset = isSignatureSubtype
      ? notaryUser.notaryProfile!.signatureInfo!.assetUrl
      : notaryUser.notaryProfile!.initialsInfo!.assetUrl;
    const newAnnotation = {
      id: makeId(),
      createdAt: new Date().toISOString(),
      annotationDesignationId: annotationDesignationId || null,
      asset,
      pngAsset: asset,
      authorId,
      documentId,
      meetingId,
      editable: true,
      static: false,
      notarialActEnum: notarialAct,
      notarialActPrincipals: [],
      kind: isSignatureSubtype ? AnnotationGraphicTypes.SIGNATURE : AnnotationGraphicTypes.INITIALS,
      location,
      size,
      text: null,
      subtype,
      __typename: "VectorGraphicAnnotation",
    };
    ANNOTATIONS_DB.push(newAnnotation);
    return {
      annotation: newAnnotation,
      annotationDesignation: null,
      errors: null,
    };
  },
  AddSealImageAnnotationPayload: (root, args) => {
    const {
      size,
      location,
      documentId,
      authorId,
      meetingId,
      annotationDesignationId,
      notarialAct,
    } = args.input;
    const notaryUser = getNotaryUser();
    const newAnnotation = {
      id: makeId(),
      createdAt: new Date().toISOString(),
      annotationDesignationId: annotationDesignationId || null,
      asset: notaryUser.notaryProfile!.sealInfo!.assetUrl,
      authorId,
      documentId,
      meetingId,
      editable: true,
      static: false,
      notarialActEnum: notarialAct,
      notarialActPrincipals: [],
      kind: AnnotationGraphicTypes.SEAL,
      location,
      size,
      text: null,
      subtype: AnnotationSubtype.SEAL,
      __typename: "ImageAnnotation",
    };
    ANNOTATIONS_DB.push(newAnnotation);
    return {
      annotation: newAnnotation,
      annotationDesignation: null,
      errors: null,
    };
  },
  AddDateAnnotationPayload: (root, args) => {
    const { size, location, editable, documentId, meetingId, authorId, annotationDesignationId } =
      args.input;
    const newAnnotation = {
      id: makeId(),
      createdAt: new Date().toISOString(),
      annotationDesignationId: annotationDesignationId || null,
      authorId,
      documentId,
      meetingId,
      editable: Boolean(editable),
      static: !editable,
      location,
      size,
      subtype: AnnotationSubtype.DATE,
      text: format({
        value: new Date(),
        formatStyle: DATE_ANNOTATION_FORMAT,
      }),
      __typename: "TextAnnotation",
    };
    ANNOTATIONS_DB.push(newAnnotation);
    return {
      annotation: newAnnotation,
      annotationDesignation: null,
      errors: null,
    };
  },
  AddCheckmarkAnnotationPayload: (root, args) => {
    const { size, location, documentId, authorId, meetingId, annotationDesignationId } = args.input;
    const newAnnotation = {
      id: makeId(),
      createdAt: new Date().toISOString(),
      annotationDesignationId: annotationDesignationId || null,
      authorId,
      documentId,
      editable: true,
      meetingId,
      location,
      size,
      subtype: AnnotationSubtype.CHECKMARK,
      text: null,
      __typename: "CheckmarkAnnotation",
    };
    ANNOTATIONS_DB.push(newAnnotation);
    return {
      annotation: newAnnotation,
      annotationDesignation: null,
      errors: null,
      updatedDesignations: [],
      designationGroup: null,
    };
  },
});

export function isSeal(annotation: { kind?: AnnotationGraphicTypes }) {
  return annotation.kind === AnnotationGraphicTypes.SEAL;
}

export function isSignature(annotation: { kind?: AnnotationGraphicTypes }) {
  return annotation.kind === AnnotationGraphicTypes.SIGNATURE;
}

export function mismatchedSignatures(docId?: string) {
  const seals = getAnnotations().filter(byDocId(docId)).filter(isSeal).reduce(countPage, {});
  const signatures = getAnnotations()
    .filter(byDocId(docId))
    .filter(isSignature)
    .reduce(countPage, {});
  return Object.entries(seals).reduce((accum, [sealPage, sealCount]) => {
    return accum + (sealCount - (signatures[sealPage] || 0));
  }, 0);
}
