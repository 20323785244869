import { CustomFormattedDateTime } from "common/core/format/date";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { EXPIRY_DATE_TIMEZONE_FORMAT } from "constants/transaction";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import type { SigningTimeSchedule_organizationTransaction_OrganizationTransaction as OrganizationTransaction } from "common/details/summary/signing_time_schedule/index.query.graphql";

type Props = {
  transaction: OrganizationTransaction;
};

function TransactionActivation({ transaction }: Props) {
  const { activationTime, activationTimezone } = transaction;
  if (!activationTime) {
    return null;
  }
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title="Transaction Activation">
        <CustomFormattedDateTime
          value={activationTime}
          asTimeZone={activationTimezone}
          formatStyle={EXPIRY_DATE_TIMEZONE_FORMAT}
        />
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default TransactionActivation;
