import PropTypes from "prop-types";

import imageIdIcon from "assets/images/id-card.svg";
import { CAPTURE_ID_TYPE, CAPTURE_ID_SIDE } from "constants/id_validation";
import Icon from "common/core/icon";

function constructIdUrl(props) {
  const { primaryIdUrl, primaryIdBackUrl, secondaryIdUrl, idType, idSide } = props;

  switch (idType) {
    case CAPTURE_ID_TYPE.PRIMARY:
      return idSide === CAPTURE_ID_SIDE.FRONT ? primaryIdUrl : primaryIdBackUrl;
    case CAPTURE_ID_TYPE.SECONDARY:
      return secondaryIdUrl;
    default:
      return primaryIdUrl;
  }
}

function IdImage({
  primaryIdUrl,
  primaryIdBackUrl,
  secondaryIdUrl,
  idType,
  idSide,
  imageLoadedError,
  onClick,
  onError,
  onLoad,
}) {
  return (
    <div className="Customer-Id">
      <div className="Customer-Id-image">
        {imageLoadedError ? (
          <div className="Customer-Id-image-unavailable">
            <p>
              Click <Icon name="rotate-clockwise" /> to refresh ID images.
            </p>
            <img className="Customer-Id-icon" alt="ID" src={imageIdIcon} />
          </div>
        ) : (
          <img
            className="Customer-Id-image-img"
            src={constructIdUrl({ primaryIdUrl, primaryIdBackUrl, secondaryIdUrl, idType, idSide })}
            alt="primary id"
            onClick={onClick}
            onError={onError}
            onLoad={onLoad}
          />
        )}
      </div>
    </div>
  );
}

IdImage.propTypes = {
  primaryIdUrl: PropTypes.string,
  primaryIdBackUrl: PropTypes.string,
  secondaryIdUrl: PropTypes.string,
  idSide: PropTypes.string,
  idType: PropTypes.string,
  imageLoadedError: PropTypes.bool,
  onClick: PropTypes.func,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

export default IdImage;
