import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import type { SigningRequirementEnum } from "graphql_globals";
import FormattedSigningRequirement from "common/core/format/formatted_signing_requirements";

type Props = {
  signingRequirement: SigningRequirementEnum;
};

export function SigningRequirements({ signingRequirement }: Props) {
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage
            id="a07edb58-8de9-48a2-9324-5e9fbfbe518c"
            defaultMessage="Signing Requirements"
          />
        }
      >
        <FormattedSigningRequirement signingRequirement={signingRequirement} />
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}
