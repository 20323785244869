import { FormattedMessage } from "react-intl";

import IllustrationModal from "common/modals/illustration_modal";
import Button from "common/core/button";
import { userFullName } from "util/user";
import { b } from "util/html";
import PhoneWithWarning from "assets/images/phone-with-warning.svg";

type Props = {
  contact: Parameters<typeof userFullName>[0];
  onClose: () => void;
  onRemove: () => void;
  isLoading?: boolean;
};

function RemoveContactModal({ onClose, isLoading, onRemove, contact }: Props) {
  return (
    <IllustrationModal
      className="CosignerCheck"
      src={PhoneWithWarning}
      title={
        <FormattedMessage
          id="d78af4c9-cbb2-4536-8224-cf2a72b73d7f"
          defaultMessage="Are you sure you want to remove <b>{name}</b> from the list of contacts?"
          values={{ name: userFullName(contact), b }}
        />
      }
      onClose={onClose}
      buttons={[
        <Button key="cancel" variant="tertiary" buttonColor="dark" onClick={onClose}>
          <FormattedMessage id="a23d6878-c0e1-4ce3-94e0-92cd232b79b5" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="delete"
          buttonColor="danger"
          variant="primary"
          isLoading={isLoading}
          onClick={onRemove}
        >
          <FormattedMessage
            id="0e544054-4924-4978-970e-c089587680ac"
            defaultMessage="Remove Contact"
          />
        </Button>,
      ]}
    >
      <FormattedMessage
        id="1348036e-ed29-44e4-965a-4dd699864472"
        defaultMessage="This action cannot be reversed."
      />
    </IllustrationModal>
  );
}

export default RemoveContactModal;
