import { FormattedMessage } from "react-intl";

import { NotaryComplianceStatuses } from "graphql_globals";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";
import { DEFAULT_ROUTE as MEMBER_MANAGEMENT_ROUTE } from "common/organization/member_management/routing";
import { SETTINGS_PATH } from "util/routes";

import { TitleOnboardingHowToItem } from "./common";
import { NSTRoot } from "../common/root";
import { DeprecatedHeader } from "../common/header";
import { NSTSection, TaskSection } from "../common/section";
import {
  SetupNotaryProfileTaskItem,
  InviteTaskItem,
  AddLogoTaskItem,
  SetupBillingTaskItem,
} from "../common/task_item";
import { NotaryKnowledgeHowToItem, NotaryToolsHowToItem } from "../common/how_to_item";
import type { TitleGetStarted_viewer_user_notaryProfile as NotaryProfile } from "./title_get_started_query.graphql";

type Props = {
  name: string;
  hasSeats: boolean;
  notaryProfile: null | NotaryProfile;
  hasLogo: boolean;
  billingSetup: boolean;
  notaryEnabled: boolean;
};

export function TitleAdminGetStarted(props: Props) {
  const { name, hasSeats, hasLogo, notaryProfile, billingSetup, notaryEnabled } = props;

  return (
    <NSTRoot>
      <DeprecatedHeader name={name} />

      <TaskSection
        title={
          <FormattedMessage
            id="f9ba30b5-5ba6-4ba8-83a6-c5349c1903db"
            defaultMessage="Finish setting up your account"
          />
        }
      >
        {notaryProfile && notaryProfile.complianceStatus !== NotaryComplianceStatuses.COMPLIANT && (
          <SetupNotaryProfileTaskItem complete={false} notaryProfile={notaryProfile} />
        )}
        <InviteTaskItem
          complete={!hasSeats}
          onClick={() => redirectToSubdomain(AppSubdomains.title_agency, MEMBER_MANAGEMENT_ROUTE)}
        />
        <AddLogoTaskItem
          complete={hasLogo}
          onClick={() => redirectToSubdomain(AppSubdomains.title_agency, SETTINGS_PATH)}
        />
        <SetupBillingTaskItem
          complete={billingSetup}
          onClick={() => redirectToSubdomain(AppSubdomains.title_agency, SETTINGS_PATH)}
        />
      </TaskSection>

      <NSTSection
        title={
          <FormattedMessage
            id="494e0191-760e-4f1f-b651-32fddee69657"
            defaultMessage="Proof Resources"
          />
        }
      >
        <TitleOnboardingHowToItem />
        {notaryEnabled && <NotaryKnowledgeHowToItem />}
        {notaryProfile?.trainingEnabled && <NotaryToolsHowToItem notaryProfile={notaryProfile} />}
      </NSTSection>
    </NSTRoot>
  );
}
