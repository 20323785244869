import { FormattedMessage } from "react-intl";

import { CustomFormattedDateTime } from "common/core/format/date";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { EXPIRY_DATE_FORMAT } from "constants/transaction";

type Props = {
  transaction: {
    notaryMeetingTime: string | null;
    notaryMeetingTimezone: string | null;
  };
};

function NotaryMeetingTime({ transaction }: Props) {
  const { notaryMeetingTime, notaryMeetingTimezone } = transaction;
  if (!notaryMeetingTime) {
    return null;
  }
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage
            id="18b3a7aa-f9d0-49f0-85ec-06d805039621"
            defaultMessage="Notary Meeting Time"
          />
        }
      >
        <CustomFormattedDateTime
          value={notaryMeetingTime}
          asTimeZone={notaryMeetingTimezone}
          formatStyle={EXPIRY_DATE_FORMAT}
        />
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default NotaryMeetingTime;
