import { useState, useCallback, memo, type ComponentProps } from "react";

import { MeetingEndedState } from "graphql_globals";
import SelectedDevicesController from "common/selected_devices_controller";
import VideoConference from "common/video_conference";
import AudioVideoSettingsContainer from "common/video_conference/audio_video_settings/meeting_container";
import MeetingSidebarContainer from "common/meeting/sidebar/container";

import SignerParty from "./signer_party";
import NotaryLocalParty from "./local_party";
import type { TrustedRefereeNotaryMeetingSidebar as Meeting } from "./index.graphql";

type SignerPartyProps = ComponentProps<typeof SignerParty>;
type RemoteParty = { id: string } & SignerPartyProps["party"];

type PartyProps = {
  party: RemoteParty;
  meeting: Meeting;
  signerConnectionState: SignerPartyProps["signerConnectionState"];
};
type Props = {
  user: { id: string };
  meeting: Meeting;
  signerConnectionState: PartyProps["signerConnectionState"];
};

function renderParty({ party, meeting, signerConnectionState }: PartyProps) {
  return (
    <SignerParty
      key={party.id}
      party={party}
      meeting={meeting}
      signerConnectionState={signerConnectionState}
    />
  );
}

function NotarySidebar({ user, meeting, signerConnectionState }: Props) {
  const { endedState } = meeting;
  const [muted, setMuted] = useState(false);
  const toggleMute = useCallback(() => setMuted((m) => !m), []);
  const [showAVSettings, setAVSettings] = useState(false);
  const toggleAVSettings = useCallback(() => setAVSettings((av) => !av), []);
  const meetingStillRunning = endedState === MeetingEndedState.NOT_COMPLETED;

  return (
    <MeetingSidebarContainer>
      <SelectedDevicesController>
        {({ onChangeDevices, selectedDevices }) => (
          <VideoConference
            meeting={meeting}
            user={user}
            muted={muted}
            publishAudio
            publishVideo
            selectedDevices={selectedDevices}
          >
            {({ localParty, remoteParties }) => (
              <>
                {meetingStillRunning &&
                  remoteParties.map((party) =>
                    renderParty({
                      party,
                      meeting,
                      signerConnectionState,
                    }),
                  )}
                <NotaryLocalParty
                  meeting={meeting}
                  party={localParty}
                  onToggleAVSettings={toggleAVSettings}
                  muted={muted}
                  onToggleMute={toggleMute}
                />
                {showAVSettings && (
                  <AudioVideoSettingsContainer
                    onClose={toggleAVSettings}
                    selectedDevices={selectedDevices}
                    onChangeDevices={onChangeDevices}
                  />
                )}
              </>
            )}
          </VideoConference>
        )}
      </SelectedDevicesController>
    </MeetingSidebarContainer>
  );
}

export default memo(NotarySidebar);
