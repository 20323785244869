import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useCallback, useState } from "react";

import { AutomaticFormRow, SpacedMultipartFormRow } from "common/core/form/layout";
import { useForm, useWatch } from "common/core/form";
import { useMutation } from "util/graphql";
import { isAriaInvalid, emailPatternValidation } from "common/core/form/error";
import { TextInput, EmailTextInput } from "common/core/form/text";
import { Select } from "common/core/form/select";
import BinaryToggle from "common/form/inputs/binary_toggle";
import type { Language } from "graphql_globals";
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
  OptionBar,
  RadioGroup,
  RadioInput,
} from "common/core/form/option";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { isGraphQLError } from "util/graphql/query";
import { useMobileScreenClass } from "common/core/responsive";

import {
  FormHeading,
  HelperText,
  Footer,
  Error,
  CheckboxesWrapper,
  NotaryStateSelect,
  useNotaryLanguages,
  type Viewer,
  type Organization,
  AccountSetupHeading,
  MobileDeviceContentWrapper,
} from "../../common";
import CreateOrganizationNotaryMutation from "../../common/create_organization_notary_mutation.graphql";
import CreateOrganizationMembershipMutation from "./create_organization_membership_mutation.graphql";
import Styles from "./index.module.scss";
import { STEPS } from "..";

const MESSAGES = defineMessages({
  firstName: {
    id: "9b07911c-4934-49ed-a999-ddcb2f713921",
    defaultMessage: "First name",
  },
  lastName: {
    id: "6b070c6e-d47a-4cff-b4b3-065d239948d3",
    defaultMessage: "Last name",
  },
  email: {
    id: "1ae2b933-aaed-409a-af67-2a9873477d32",
    defaultMessage: "Email",
  },
  role: {
    id: "d651dd39-275a-48d1-8bd0-af84bb2d5ba9",
    defaultMessage: "Role",
  },
  firstNameError: {
    id: "e42bda7e-66c0-4b05-b822-f84b01ca7dcb",
    defaultMessage: "First name is required to invite a coworker.",
  },
  lastNameError: {
    id: "c2c760c4-1781-4451-96ea-9a0bbea11665",
    defaultMessage: "Last name is required to invite a coworker.",
  },
  emailError: {
    id: "29407015-9326-4321-ad9a-0be51c19fa48",
    defaultMessage: "Email is required to invite a coworker.",
  },
  memberExistsError: {
    id: "288e06f2-634a-477b-8609-0acf5a1dc717",
    defaultMessage: "This user already exists.",
  },
  memberInOrgError: {
    id: "29407015-9326-4321-ad9a-0be51c19fa48",
    defaultMessage: "This user is already a member.",
  },
  memberLimitError: {
    id: "3ce8cea0-64db-4ad4-9594-bb81f46e8c8b",
    defaultMessage: "Your employee limit has been reached.",
  },
});

type FormValues = {
  firstName: string | null;
  lastName: string | null;
  email: string;
  role: string;
  isNotary: boolean;
  notaryState: string;
  notaryLangs: Language[];
};

type Props = {
  portal: "business" | "title";
  organizationId: Organization["id"];
  roles: Organization["assignableRoles"];
  notaryUsStates: Viewer["notaryUsStates"];
  isIhnEnabled: boolean;
  onNext: () => Promise<void>;
  onSkip: () => void;
};

export function InviteCoworkerStep({
  portal,
  organizationId,
  roles,
  notaryUsStates,
  isIhnEnabled,
  onNext,
  onSkip,
}: Props) {
  const isMobileScreenSize = useMobileScreenClass();
  const isMobileBusiness = isMobileScreenSize && portal === "business";
  const intl = useIntl();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  /* We reverse to show Team Member option first. Array should only be 2 roles. */
  const filteredRoles = roles
    .flatMap((role) => {
      if (role.name === "admin") {
        return {
          ...role,
          subLabel: (
            <FormattedMessage
              id="7fccad89-f33e-459c-90b8-1ea2e36bfbc7"
              defaultMessage="Can send and manage transactions, invite team members, and edit organization settings."
            />
          ),
        };
      } else if (role.name === "employee") {
        return {
          ...role,
          subLabel: (
            <FormattedMessage
              id="d6d45ed4-04ba-4a6f-9d26-c20e496b758c"
              defaultMessage="Can send and manage transactions within your organization."
            />
          ),
        };
      }
      return [];
    })
    .reverse();

  const defaultRole = filteredRoles.find((role) => role.name === "employee");

  const languageOptions = useNotaryLanguages();

  const form = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      role: defaultRole?.id,
      isNotary: false,
      notaryState: undefined,
      notaryLangs: [],
    },
  });

  const isNotary = useWatch({ control: form.control, name: "isNotary" });

  const handleErrors = useCallback((error) => {
    const isMutationGraphError = isGraphQLError(error);
    if (isMutationGraphError && error.message.includes("employee_limit_reached")) {
      form.setError("email", {
        type: "custom",
        message: intl.formatMessage(MESSAGES.memberLimitError),
      });
    } else if (
      isMutationGraphError &&
      (error.message.includes("membership_already_exists") ||
        error.message.includes("email_already_registered"))
    ) {
      form.setError("email", {
        type: "custom",
        message: intl.formatMessage(MESSAGES.memberExistsError),
      });
    } else if (isMutationGraphError && error.message.includes("membership_already_in_org")) {
      form.setError("email", {
        type: "custom",
        message: intl.formatMessage(MESSAGES.memberInOrgError),
      });
    } else {
      setError(true);
    }
  }, []);

  const trackStepCompleted = ({
    email,
    firstName,
    lastName,
    roleSchedules,
    notaryState,
    isNotary,
  }: Partial<FormValues> & { roleSchedules: { userRoleId: string }[] }) => {
    // Generic event for inviting a member
    segmentTrack(SEGMENT_EVENTS.MEMBER_INVITED, {
      source: `${portal} onboarding flow`,
      invitee_email: email,
      firstName,
      lastName,
      member_role: roleSchedules,
    });

    if (portal === "title") {
      // Specific event for inviting a member during onboarding step
      segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_INVITE_ONBOARDING_SUCCEEDED, {
        first_name: firstName,
        last_name: lastName,
        invitee_email: email,
        is_notary: isNotary,
        notary_state: isNotary
          ? notaryUsStates.find((state) => state?.value === notaryState)?.label
          : undefined,
        member_role: roleSchedules,
        skipped: false,
      });
    }
  };

  const createOrganizationNotary = useMutation(CreateOrganizationNotaryMutation);
  const createOrganizationMembership = useMutation(CreateOrganizationMembershipMutation);

  const save = async (formValues: FormValues) => {
    const { firstName, lastName, role, email, isNotary, notaryState, notaryLangs } = formValues;
    const roleSchedules = [{ userRoleId: role }];

    try {
      setSaving(true);
      if (isNotary) {
        await createOrganizationNotary({
          variables: {
            input: {
              organizationId,
              firstName,
              lastName,
              email,
              roleSchedules,
              languages: notaryLangs,
              usStateId: notaryState,
            },
          },
        });
      } else {
        await createOrganizationMembership({
          variables: {
            input: {
              organizationId,
              firstName,
              lastName,
              email,
              roleSchedules,
            },
          },
        });
      }
      trackStepCompleted({ firstName, lastName, email, roleSchedules, isNotary, notaryState });
      await onNext();
    } catch (error) {
      handleErrors(error);
    } finally {
      setSaving(false);
    }
  };

  const formHeading = (
    <FormattedMessage
      id="034bd28a-ae3d-4452-9381-d08f30a1ae73"
      defaultMessage="{isTitle, select, true {Lastly} other {Next}}, invite a coworker to join your account"
      values={{ isTitle: portal === "title" }}
    />
  );

  const coreFooterProps = {
    portal,
    onSkip: async () => {
      onSkip();
      await onNext();
    },
    step: STEPS.INVITE,
    loading: saving,
    saveButtonAutomationId: "invite-member-modal-primary-btn",
    skipButtonAutomationId: "invite-member-modal-skip-btn",
  };

  const firstNameInput = (
    <AutomaticFormRow
      fullWidth={isMobileBusiness}
      highlightLabelOnError
      form={form}
      name="firstName"
      registerOptions={{ required: intl.formatMessage(MESSAGES.firstNameError) }}
      label={intl.formatMessage(MESSAGES.firstName)}
      as={TextInput}
    />
  );

  const lastNameInput = (
    <AutomaticFormRow
      fullWidth={isMobileBusiness}
      highlightLabelOnError
      form={form}
      name="lastName"
      registerOptions={{ required: intl.formatMessage(MESSAGES.lastNameError) }}
      label={intl.formatMessage(MESSAGES.lastName)}
      as={TextInput}
    />
  );

  const emailInput = (
    <AutomaticFormRow
      highlightLabelOnError
      fullWidth
      form={form}
      name="email"
      registerOptions={{
        required: intl.formatMessage(MESSAGES.emailError),
        pattern: emailPatternValidation(intl),
      }}
      label={intl.formatMessage(MESSAGES.email)}
      as={EmailTextInput}
    />
  );

  const ihnFormOptions = (
    <>
      <BinaryToggle
        automationId="isNotary"
        className={Styles.toggle}
        label={
          <FormattedMessage
            id="63f72508-9d15-4c75-acf6-a2037751d6c3"
            defaultMessage="This user will notarize documents on behalf of my company"
          />
        }
        subLabel={
          <FormattedMessage
            id="c466f4d1-a725-496b-bc36-5169b194f8b3"
            defaultMessage="All notaries must be RON certified and will need to complete an onboarding process prior to taking calls."
          />
        }
        onChange={() => form.setValue("isNotary", !isNotary)}
        value={isNotary}
      />

      {isNotary && (
        <>
          <div className={Styles.notaryStatesWrapper}>
            <NotaryStateSelect form={form} notaryUsStates={notaryUsStates} name="notaryState" />
          </div>

          <CheckboxGroup
            required
            hasError={Boolean(form.formState.errors.notaryLangs)}
            label={
              <FormattedMessage
                id="2a6d68d0-5977-4229-999c-7a5404737390"
                defaultMessage="Which languages does this notary speak fluently?"
              />
            }
          >
            <CheckboxesWrapper>
              {languageOptions.map(({ value, label }) => (
                <CheckboxLabel
                  key={value}
                  checkbox={
                    <Checkbox
                      aria-invalid={isAriaInvalid(form.formState.errors.notaryLangs)}
                      value={value}
                      {...form.register("notaryLangs", { required: true })}
                    />
                  }
                  label={label}
                />
              ))}
            </CheckboxesWrapper>
          </CheckboxGroup>
        </>
      )}
    </>
  );

  const desktopContent = (
    <>
      <FormHeading>{formHeading}</FormHeading>
      <HelperText>
        <FormattedMessage
          id="1aaeb37c-3b2c-4a7f-ad96-e1eaeff112ce"
          defaultMessage="Collaborate with coworkers on your digital transactions on Proof."
        />
      </HelperText>

      <SpacedMultipartFormRow>
        {firstNameInput}
        {lastNameInput}
      </SpacedMultipartFormRow>

      {emailInput}

      <AutomaticFormRow
        highlightLabelOnError
        fullWidth
        form={form}
        name="role"
        registerOptions={{ required: true }}
        label={intl.formatMessage(MESSAGES.role)}
      >
        <Select
          items={filteredRoles.map((role) => ({
            value: role.id,
            label: role.displayName,
          }))}
          aria-invalid={isAriaInvalid(form.formState.errors.role)}
        />
      </AutomaticFormRow>

      {isIhnEnabled && ihnFormOptions}

      {error && <Error />}

      <Footer
        {...coreFooterProps}
        tooltipTargetText={
          <FormattedMessage
            id="13a62d48-aee8-4fb8-bd38-a803870548cc"
            defaultMessage="Why invite coworkers?"
          />
        }
        tooltipText={
          <FormattedMessage
            id="fc5257cb-bd95-48aa-946a-bd4a3f6a1456"
            defaultMessage="Get the most out of Proof for {isTitle, select, true {Title} other {Business}} by collaborating on eClosings with your team. Monitor the status of your team's transactions and set permissions to give the right level of control."
            values={{ isTitle: portal === "title" }}
          />
        }
      />
    </>
  );

  const mobileDeviceBusinessContent = (
    <>
      <AccountSetupHeading />
      <FormHeading mobileDeviceStyle>{formHeading}</FormHeading>
      <MobileDeviceContentWrapper>
        {firstNameInput}
        {lastNameInput}
        {emailInput}
        <RadioGroup label={intl.formatMessage(MESSAGES.role)}>
          {filteredRoles.map((role) => {
            return (
              <OptionBar
                key={role.id}
                label={role.displayName}
                input={
                  <RadioInput
                    value={role.id}
                    {...form.register("role", {
                      required: true,
                    })}
                  />
                }
                subLabel={role.subLabel}
                hasError={Boolean(form.formState.errors.role)}
              />
            );
          })}
        </RadioGroup>
      </MobileDeviceContentWrapper>

      {isIhnEnabled && <MobileDeviceContentWrapper>{ihnFormOptions}</MobileDeviceContentWrapper>}

      {error && <Error />}

      <Footer
        {...coreFooterProps}
        continueButtonText={
          <FormattedMessage
            id="9b426854-0a3b-4f6b-b6d2-8123134642c7"
            defaultMessage="Invite and continue"
          />
        }
        mobileDeviceStyling
      />
    </>
  );

  return (
    <form onSubmit={form.handleSubmit(save)}>
      {isMobileBusiness ? mobileDeviceBusinessContent : desktopContent}
    </form>
  );
}
