import { useState, useEffect, type ReactElement } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { FormattedMessage } from "react-intl";

import { OrgTransactionStates } from "graphql_globals";
import compose from "util/compose";
import { segmentTrack } from "util/segment";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { denormalizeDatetime, normalizeDatetime } from "util/transaction";
import { composeValidators, getFormValues } from "util/form";
import { browserTimeZone } from "util/date";
import { ACTIVATION_TIME } from "constants/transaction";

import DateTimeFormElements, { validationRules } from "./form_elements";

type FormValues = {
  activationToggle: boolean;
  activationDate: Date | null;
  activationTimezone: string | null;
};
type Props = {
  transaction: {
    id: string;
    activationTime: string | null;
    activationTimezone: string | null;
    state: OrgTransactionStates | null;
  };
  onSubmitForm: (
    values: null | {
      activation: string;
      activationTimezone: string;
    },
  ) => Promise<unknown>;
};
type InnerProps = Props &
  InjectedFormProps<FormValues, Props> & {
    formValues: FormValues;
  };

function validate(values: FormValues, props: { fieldPrefix?: string }) {
  return composeValidators(validationRules(props))(values);
}

function TransactionActivationForm(props: InnerProps) {
  const { transaction, initialize, formValues, handleSubmit, onSubmitForm } = props;
  const { activationTimezone, activationTime, state } = transaction;
  const showEditTimes =
    state !== OrgTransactionStates.COMPLETED &&
    state !== OrgTransactionStates.COMPLETED_WITH_REJECTIONS;
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const activationTimezoneFormValue = activationTimezone || browserTimeZone();
    // @ts-expect-error -- converted from javascript with missing second arg
    const activationDateTime = activationTime && denormalizeDatetime(activationTime);

    initialize({
      activationToggle: Boolean(activationTime),
      activationDate: activationDateTime || null,
      activationTimezone: activationTimezoneFormValue,
    });
  }, []);

  const saveActivation = ({ activationToggle, activationDate, activationTimezone }: FormValues) => {
    const { activationTime: previousActivationTime } = transaction;

    const values = activationToggle
      ? {
          activation: normalizeDatetime({
            hour: ACTIVATION_TIME.HOURS,
            minutes: ACTIVATION_TIME.MINUTES,
            date: activationDate!,
            timezone: activationTimezone!,
          })!,
          activationTimezone: activationTimezone!,
        }
      : null;
    onSubmitForm(values)
      .then(() => {
        segmentTrack("[REAL] Admin modified activation time", {
          transactionId: transaction.id,
          previousActivationTime,
          updatedActivationTime: values?.activation,
        });
      })
      .finally(() => {
        setIsEditing(false);
      });
  };

  return (
    <DeprecatedDetailGridRow
      title={
        <FormattedMessage
          id="03b12fc3-cd99-442d-b1aa-6468b9c7d7e8"
          defaultMessage="Transaction Activated"
        />
      }
    >
      <form className="AdminUpdateTransaction-ActivationForm">
        <DateTimeFormElements
          showEditTimes={showEditTimes}
          isEditing={isEditing}
          handleEditAction={() => setIsEditing(true)}
          fieldPrefix="activation"
          time={activationTime!}
          timezone={activationTimezone!}
          handleSave={handleSubmit(saveActivation)}
          formValues={formValues}
        />
      </form>
    </DeprecatedDetailGridRow>
  );
}

export default compose(
  reduxForm({ form: "adminUpdateActivationForm", validate }),
  getFormValues("adminUpdateActivationForm"),
)(TransactionActivationForm) as unknown as (props: Props) => ReactElement;
