import "./index.scss";

import classnames from "classnames";
import { useState, type ChangeEvent } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import Link from "common/core/link";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { useMutation } from "util/graphql";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { StyledTextInput } from "common/form/inputs/text";
import { usePermissions } from "common/core/current_user_role";

import UpdateOrganizationTransactionTitleAgencyMutation from "./update_organization_transaction_title_agency_mutation.graphql";

const MESSAGES = defineMessages({
  placeholder: {
    id: "46ca259b-af44-404f-9236-361debe66516",
    defaultMessage: "Organization ID",
  },
});

type Props = {
  transactionId: string;
  onUpdate: () => void;
  name: string | null | undefined;
  titleAgentId: string | null | undefined;
};

export default function TitleAgencyName({ transactionId, onUpdate, name, titleAgentId }: Props) {
  const intl = useIntl();
  const [showEditForm, updateShowEditForm] = useState(false);
  const [newOrganizationId, updateNewOrganizationId] = useState("");
  const [showError, updateShowError] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const updateOrganizationTransactionTitleAgency = useMutation(
    UpdateOrganizationTransactionTitleAgencyMutation,
  );
  const { hasPermissionFor } = usePermissions();

  const updateTitleAgency = () => {
    updateShowError(false);
    updateIsLoading(true);
    return updateOrganizationTransactionTitleAgency({
      variables: {
        input: {
          organizationId: newOrganizationId,
          organizationTransactionId: transactionId,
        },
      },
    })
      .then(() => {
        onUpdate();
        updateShowEditForm(false);
      })
      .catch(() => {
        updateShowError(true);
      })
      .finally(() => {
        updateIsLoading(false);
      });
  };

  const canEditTitleTransactionAgency = hasPermissionFor("editTransactionTitleAgency");

  const classEditTitles = classnames({
    "TitleAgencyName--content--text-edit-enabled": canEditTitleTransactionAgency,
    "TitleAgencyName--content--text-edit-disabled": !canEditTitleTransactionAgency,
  });

  return (
    <>
      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow
          title={
            <FormattedMessage
              id="98191779-ec4f-4dc0-9f03-a540dcdff5ef"
              defaultMessage="Title Agency"
            />
          }
        >
          <div className="TitleAgencyName--content">
            {name && (
              <div className={classEditTitles}>
                {`${name} `}
                <Link
                  to={`/companies/${titleAgentId}/transactions/${transactionId}/summary`}
                  automationId="title-agency-redirect"
                  underlined={false}
                >
                  <span>{`(${titleAgentId})`}</span>
                </Link>
              </div>
            )}
            {canEditTitleTransactionAgency && (
              <div
                className="TitleAgencyName--content--button"
                onClick={() => updateShowEditForm(true)}
              >
                <FormattedMessage id="193b26f4-8696-4577-a4c6-b201693e8ce4" defaultMessage="Edit" />
              </div>
            )}
          </div>
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>
      {showEditForm && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="c605dca5-2a19-49c3-93e0-d9f145dbe5fa"
              defaultMessage="Update Title Agency"
            />
          }
          buttons={[
            <Button
              key="TitleAgencyName-cancel"
              variant="tertiary"
              buttonColor="dark"
              onClick={() => updateShowEditForm(false)}
              automationId="cancel-button"
            >
              <FormattedMessage id="a88473f5-0e51-4ccd-95f3-c119f1075489" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="TitleAgencyName-agree"
              buttonColor="danger"
              variant="primary"
              onClick={updateTitleAgency}
              isLoading={isLoading}
              automationId="agree-button"
            >
              <FormattedMessage
                id="96896ddd-34bc-445f-b52e-e1c8b1c4ec16"
                defaultMessage="Accept & Change"
              />
            </Button>,
          ]}
        >
          <>
            <FormattedMessage
              id="ac02de3d-17b0-43d8-bd40-00ca7145819e"
              defaultMessage="You are about to change the title organization associated with this transaction. This will transfer the transaction to the organization you input so make sure it is the correct organization. Please take note of the following:"
            />
            <ul className="TitleAgencyName--list">
              <li>
                <FormattedMessage
                  id="f341e8e5-b67b-4810-a5a8-e0ed2cec1f45"
                  defaultMessage="Check the eligibility of the organization you are changing to"
                />
              </li>
              <li>
                <FormattedMessage
                  id="9fe59cda-771f-45cf-a4a7-e3c38ce23833"
                  defaultMessage="Any pending/completed charges are not changed (will need to file incident)"
                />
              </li>
              <li>
                <FormattedMessage
                  id="3da11dec-8b0f-4401-bbe8-9eb6d97f0447"
                  defaultMessage="Collab contacts with emails that ask them to add documents will still be able to at this time. If that is undesired, the Lender needs to remove the contact on the transaction"
                />
              </li>
            </ul>
            {showError && (
              <div className="TitleAgencyName--error">
                <FormattedMessage
                  id="446fa79a-8dea-4f6d-b1c3-3c05d7bf4d1d"
                  defaultMessage="Hmm... we couldn't update the title agency"
                />
              </div>
            )}
            <div className="TitleAgencyName--form">
              <StyledTextInput
                value={newOrganizationId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateNewOrganizationId(e.target.value);
                }}
                placeholder={intl.formatMessage(MESSAGES.placeholder)}
                placeholderAsLabel
              />
            </div>
          </>
        </WorkflowModal>
      )}
    </>
  );
}
