import { FormattedMessage, useIntl } from "react-intl";

import RequiredAsterisk from "common/core/form/required-asterisk";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { useForm } from "common/core/form";
import { TextAreaInput } from "common/core/form/text";
import { defaultRequiredMessage, FormattedFieldError, isAriaInvalid } from "common/core/form/error";
import { useId } from "util/html";
import { FraudStatusInputEnum } from "graphql_globals";

import Styles from "./fraud.module.scss";

type FormValues = {
  reason: string;
};

function ModalTitle(props: { action: FraudStatusInputEnum }) {
  switch (props.action) {
    case FraudStatusInputEnum.FRAUD:
      return (
        <FormattedMessage
          id="f40d89c3-d947-4132-94dc-b0aea77e472c"
          defaultMessage="Flag an identity?"
        />
      );
    case FraudStatusInputEnum.REMOVE_FRAUD:
      return (
        <FormattedMessage
          id="e1dc7c73-0613-4f64-96db-93c5fb22928c"
          defaultMessage="Unflag an identity?"
        />
      );
  }
}

export function ConfirmationModal(props: {
  onContinue: (fv: FormValues) => void;
  onClose: () => void;
  action: FraudStatusInputEnum;
}) {
  const formId = useId();
  const reasonId = useId();
  const intl = useIntl();
  const form = useForm<FormValues>();

  const { handleSubmit } = form;
  const { errors } = form.formState;
  const buttons = [
    <Button key="fraud-modal-cancel" onClick={props.onClose} buttonColor="dark" variant="tertiary">
      <FormattedMessage id="597cbc14-72c2-456b-8d93-7ad10fdda65a" defaultMessage="Cancel" />
    </Button>,
    <Button
      key="fraud-modal-continue"
      type="submit"
      form={formId}
      buttonColor="danger"
      variant="primary"
    >
      {props.action === FraudStatusInputEnum.FRAUD ? (
        <FormattedMessage
          id="958f6645-dbbf-4372-821b-6ea35527f81d"
          defaultMessage="Flag identity alert"
        />
      ) : (
        <FormattedMessage
          id="02bdc0a2-6194-461b-aaab-fd57b70d8ce7"
          defaultMessage="Unflag identity alert"
        />
      )}
    </Button>,
  ];

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose: props.onClose }}
      buttons={buttons}
      title={<ModalTitle action={props.action} />}
      footerSeparator={false}
    >
      <FormattedMessage
        id="3c2d27bb-f8da-43fa-92e4-a441ac7c1e1e"
        defaultMessage="Flagging this identity will not block a transaction from completion"
      />
      <form className={Styles.form} id={formId} onSubmit={handleSubmit(props.onContinue)}>
        <label htmlFor={reasonId}>
          {props.action === FraudStatusInputEnum.FRAUD ? (
            <FormattedMessage
              id="714b2200-37e4-4d09-89f3-0a40767dbecf"
              defaultMessage="Reason for flagging"
            />
          ) : (
            <FormattedMessage
              id="09982886-c2a9-4c80-85b4-9e603e7744e8"
              defaultMessage="Reason for unflagging"
            />
          )}
          <RequiredAsterisk />
        </label>
        <TextAreaInput
          id={reasonId}
          aria-labelledby={reasonId}
          aria-invalid={isAriaInvalid(errors.reason)}
          {...form.register("reason", {
            required: defaultRequiredMessage(intl),
          })}
        />
        <FormattedFieldError inputName="reason" error={errors.reason} />
      </form>
    </WorkflowModal>
  );
}
