import { type ReactElement, useEffect, useState } from "react";
import { type InjectedFormProps, reduxForm } from "redux-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classNames from "classnames";

import compose from "util/compose";
import FormGroup from "common/form/group";
import SaveButton from "common/core/save_button";
import PhoneCountryCodeField from "common/form/fields/phone/country_code";
import { isInternational } from "common/form/inputs/phone/country_code";
import PhoneNumberField from "common/form/fields/phone/number";
import MultipartRow from "common/form/inputs/multipart/row";
import MultipartColumn from "common/form/inputs/multipart/column";
import FormGroupErrors from "common/form/group_errors";
import { useMutation } from "util/graphql";
import { QueryWithLoading } from "util/graphql/query";
import { getFormValues, composeValidators } from "util/form";
import { validatePresence, validatePhoneNumberLength } from "validators/form";

import CustomerSignersQuery, {
  type CustomerSigners_transaction_OrganizationTransaction,
  type CustomerSigners_transaction_OrganizationTransaction_customerSigners as CustomerDetails,
} from "./customer_signers_query.graphql";
import SendOrganizationTransactionSMSMutation from "./send_organization_transaction_sms_mutation.graphql";
import Styles from "../index.module.scss";

const messages = defineMessages({
  countryCode: {
    id: "6f7fc053-d6f9-4450-825b-aa22e7c4bcb5",
    defaultMessage: "Country code",
  },
  phoneNumber: {
    id: "3088cf0e-99e2-4dba-8b91-d28f92be1c19",
    defaultMessage: "Phone number",
  },
});

type FormValues = {
  phoneCountryCode: string;
  phoneNumber: string;
};

type Props = {
  organizationTransactionId: string;
  onSuccess: () => void;
  onFailure: (error: Error) => void;
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props;

type FormProps = {
  customerDetails: CustomerDetails;
};

function ResendSmsForm({
  initialize,
  customerDetails: { phone },
  onSuccess,
  onFailure,
  handleSubmit,
  invalid,
  organizationTransactionId,
  phoneCountryCode,
}: InnerProps & FormValues & FormProps) {
  const intl = useIntl();
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    initialize({
      phoneCountryCode: phone?.countryCode,
      phoneNumber: phone?.number,
    });
  }, []);

  const sendSms = useMutation(SendOrganizationTransactionSMSMutation);

  const sendTextMessage = ({ phoneCountryCode, phoneNumber }: FormValues) => {
    setIsSending(true);

    sendSms({
      variables: {
        mutationInput: {
          organizationTransactionId,
          phone: {
            countryCode: phoneCountryCode,
            number: phoneNumber,
          },
        },
      },
    })
      .then(() => {
        setIsSending(false);
        onSuccess();
      })
      .catch((error) => {
        setIsSending(false);
        onFailure(error);
      });
  };

  return (
    <form
      className={classNames("Form", Styles.resendTransactionForm)}
      onSubmit={handleSubmit(sendTextMessage)}
    >
      <FormGroup fields={["phoneCountryCode", "phoneNumber"]}>
        <label htmlFor="phone">
          <FormattedMessage
            id="dbe4f335-9267-4d55-a07f-72facf72faf8"
            description="message"
            defaultMessage="Phone number"
          />
        </label>
        <MultipartRow>
          <MultipartColumn width={4}>
            <PhoneCountryCodeField
              name="phoneCountryCode"
              aria-label={intl.formatMessage(messages.countryCode)}
              label={intl.formatMessage(messages.countryCode)}
            />
          </MultipartColumn>
          <MultipartColumn width={9}>
            <PhoneNumberField
              name="phoneNumber"
              aria-label={intl.formatMessage(messages.phoneNumber)}
              isInternational={isInternational({ countryCode: phoneCountryCode })}
              label={intl.formatMessage(messages.phoneNumber)}
            />
          </MultipartColumn>
        </MultipartRow>

        <FormGroupErrors fields={["phoneCountryCode", "phoneNumber"]} />
      </FormGroup>

      <div className={Styles.submitButtonWrapper}>
        <SaveButton
          title="Resend SMS"
          updatedTitle="SMS sent"
          disabled={invalid}
          isLoading={isSending}
          automationId="resend-transaction-sms-button"
        />
      </div>
    </form>
  );
}

const validate = (values: FormValues) => {
  return composeValidators(
    validatePresence({ field: "phoneCountryCode", label: "Country code" }),
    validatePhoneNumberLength({
      field: "phoneNumber",
      isInternational: isInternational({ countryCode: values.phoneCountryCode }),
    }),
  )(values);
};

function ResendSms(props: InnerProps & FormValues) {
  const { organizationTransactionId } = props;
  return (
    <QueryWithLoading query={CustomerSignersQuery} variables={{ organizationTransactionId }}>
      {(query) => (
        <ResendSmsForm
          {...props}
          customerDetails={
            (query.data!.transaction! as CustomerSigners_transaction_OrganizationTransaction)
              .customerSigners[0]
          }
        />
      )}
    </QueryWithLoading>
  );
}

export default compose(
  reduxForm({
    form: "resendSms",
    validate,
  }),
  getFormValues("resendSms"),
)(ResendSms) as unknown as (props: Props) => ReactElement;
