import { defineMessages, useIntl } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { MailtoLink } from "common/core/mailto_link";
import { userFullName } from "util/user";

type Props = {
  email: string;
  user?: {
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    email?: string | null;
  } | null;
  mailToEmail?: boolean;
};

const titles = defineMessages({
  signingGroup: {
    id: "fc70f346-e3d2-4fad-bb8e-4d3fa94bd678",
    defaultMessage: "Signing group",
  },
  signer: {
    id: "4f06a322-9c35-4f12-8bf6-e642ce6c3b77",
    defaultMessage: "Signer",
  },
});

export function RecipientGroupDetails({ email, user, mailToEmail = false }: Props) {
  const intl = useIntl();
  // signer is not claimed until we have email and name data on the user
  // TODO: we can remove name check once this is completed: BIZ-5697
  const isClaimed = user?.email && (user.firstName || user.lastName);
  return (
    <>
      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow title={intl.formatMessage(titles.signingGroup)}>
          {mailToEmail ? <MailtoLink emailAddress={email} /> : email}
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>
      {isClaimed && (
        <DeprecatedDetailGridSection>
          <DeprecatedDetailGridRow title={intl.formatMessage(titles.signer)}>
            {userFullName(user)}
          </DeprecatedDetailGridRow>
        </DeprecatedDetailGridSection>
      )}
    </>
  );
}
