export function documentsInput(documentEdges) {
  return documentEdges.map((edge) => {
    const {
      id,
      name,
      signerCanAnnotate: customerCanAnnotate,
      witnessRequired,
      requirement,
    } = edge.document;
    return {
      id,
      name,
      customerCanAnnotate,
      witnessRequired,
      requirement,
    };
  });
}
