import { useCallback } from "react";

import { useIAnav, useOnboardingV2 } from "util/feature_detection";
import {
  NotaryOnboardingStatuses,
  NotaryComplianceStatuses,
  NotaryCapacityType,
  NotaryProfileInvalidFields,
} from "graphql_globals";
import { redirectToSubdomain } from "util/application_redirect";
import AppSubdomains from "constants/app_subdomains";

type NotaryProfile = {
  capacities: {
    type: NotaryCapacityType;
    validation: { invalidFields: NotaryProfileInvalidFields[] };
  }[];
  onboardingStatus: NotaryOnboardingStatuses;
  complianceStatus: NotaryComplianceStatuses;
};

function isByotPaymentMissing({ capacities }: NotaryProfile) {
  return capacities.some((cap) => {
    return (
      cap.type === NotaryCapacityType.BRING_YOUR_OWN_TRANSACTIONS &&
      cap.validation.invalidFields.some((invalidField) => {
        return (
          invalidField === NotaryProfileInvalidFields.INVALID_PAYMENT ||
          invalidField === NotaryProfileInvalidFields.MISSING_STRIPE_CONNECT
        );
      })
    );
  });
}

export function useNotaryCommonSetupRedirect(needsReviewRedirect: boolean = false) {
  const IANavEnabled = useIAnav();
  const onboardingV2 = useOnboardingV2();
  const onboardingPath = onboardingV2
    ? "/settings/notary/onboarding/overview"
    : "/settings/notary/onboarding";
  return useCallback((notaryProfile: NotaryProfile) => {
    if (
      notaryProfile.complianceStatus === NotaryComplianceStatuses.COMPLIANT &&
      isByotPaymentMissing(notaryProfile)
    ) {
      // Notary payment settings is in organization settings in the business app
      const url = IANavEnabled
        ? "/settings/billing/payment-settings"
        : "/settings/notary/profile/PayoutOptions";
      redirectToSubdomain(AppSubdomains.business, url);
    } else {
      // (post ia-nav) Notary onboarding and personal settings are in the signer app
      const url =
        notaryProfile.onboardingStatus !== NotaryOnboardingStatuses.COMPLETED
          ? onboardingPath
          : "/settings/notary/profile";

      if (IANavEnabled) {
        redirectToSubdomain(AppSubdomains.customer, url, { newTab: needsReviewRedirect });
      } else {
        redirectToSubdomain(AppSubdomains.business, url);
      }
    }
  }, []);
}
