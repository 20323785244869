import "./index.scss";

import { useState, useEffect, Fragment, type ReactNode } from "react";
import classnames from "classnames";
import { FormattedMessage, useIntl, defineMessages, type IntlShape } from "react-intl";

import RefinalizeModal from "common/details/bundle/refinalize_modal";
import {
  type NotarialActs,
  DocumentBundleParticipantStatus,
  CompletionStatuses,
} from "graphql_globals";
import { LongFormattedDateTime } from "common/core/format/date";
import { StandardTable } from "common/table";
import Row from "common/table/row";
import ActionButton from "common/core/action_button";
import Button from "common/core/button";
import StatusCircle from "common/core/status_circle";
import Icon from "common/core/icon";
import { userFullName } from "util/user";
import { notarialActLabel } from "util/document";
import {
  DOC_REJECTION_DESCRIPTIONS,
  DOC_COMPLETION_STATUS_LABELS,
  DOC_COMPLETION_STATUS_CIRCLE_TYPES,
} from "constants/document";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { usePermissions } from "common/core/current_user_role";

type DocParticipant = Parameters<typeof userFullName>[0] & {
  representation: string | null;
  userId: string;
  designationDetails: {
    unfulfilledDesignationsCount: number;
    totalDesignationsCount: number;
  };
  completedAt: string | null;
  signingStatus: DocumentBundleParticipantStatus;
};
type Document = {
  id: string;
  name: string | null;
  signAhead: boolean;
  completionStatus: CompletionStatuses | null;
  signedWetSign: boolean;
  participants: (DocParticipant | null)[] | null;
  rejectionAnecdote: string | null;
  rejectionReason: string | null;
  notarialActs: NotarialActs[];
};
type Props<D extends Document> = {
  title: string;
  documents: D[];
  lastOpenedId: string | null;
  reviewSessions: ({
    userId: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null)[];
  onClick: (document: D) => void;
  orderHeaderSpan: ReactNode;
  documentsViewable?: boolean;
  showNotarialActs?: boolean;
  expired: boolean;
  requiresNsaMeeting: boolean;
  organizationName: string | undefined | null;
  showResponsiveView?: boolean;
};
type RowOptions<D extends Document> = {
  expired: boolean;
  reviewSessions: Props<D>["reviewSessions"];
  showNotarialActs: boolean;
  showRefinalize: boolean;
  requiresNsaMeeting: boolean;
  document: D;
  onViewDocument?: (document: D) => void;
  onToggleCompletions: (document: D) => void;
  showCompletionsDropdown: boolean;
  intl: IntlShape;
  numSignersSigned: number;
  numSignersReviewed: number;
  isCompletionsOpen: boolean;
  onRefinalize: (document: D) => void;
};

const messages = defineMessages({
  noRejectionAnecdote: {
    id: "60a02fc3-f666-4fc1-820d-9da84e6321c5",
    defaultMessage: "No rejection reason provided",
  },
});

type StatusParams = {
  document: Document;
  expired: boolean;
  reviewSessions: Props<Document>["reviewSessions"];
  numSignersSigned: number;
  numSignersReviewed: number;
  intl: IntlShape;
  requiresNsaMeeting: boolean;
};

function getStatus({
  document,
  numSignersSigned,
  reviewSessions,
  numSignersReviewed,
  expired,
  requiresNsaMeeting,
}: StatusParams) {
  const { participants, completionStatus, signAhead, rejectionReason } = document;

  const statusSubText =
    requiresNsaMeeting && !signAhead ? null : !expired && signAhead ? (
      <FormattedMessage id="de44fa4b-60a5-46d5-855a-3e9b2c619407" defaultMessage="eSign" />
    ) : !expired ? (
      <FormattedMessage
        id="196e6c7b-1228-466c-8226-fcfa576f6628"
        defaultMessage="In-Person Meeting"
      />
    ) : null;

  const hasReviewSessions = Boolean(reviewSessions.length); // Business NSA transactions do not create review sessions

  // Thought out doc statuses here:
  // https://docs.google.com/spreadsheets/d/19V_smgPqHFI9OwlU9ZrsERM6oblTY9NIi-TiVprlhvY/edit?usp=sharing
  let status =
    DOC_COMPLETION_STATUS_LABELS[completionStatus as keyof typeof DOC_COMPLETION_STATUS_LABELS];
  let statusCircleType =
    DOC_COMPLETION_STATUS_CIRCLE_TYPES[
      completionStatus as keyof typeof DOC_COMPLETION_STATUS_CIRCLE_TYPES
    ];
  const hasParticipants = Boolean(participants!.length); // doc wouldn't have participants if in draft state
  if (rejectionReason) {
    status = DOC_COMPLETION_STATUS_LABELS.REJECTED;
    statusCircleType = DOC_COMPLETION_STATUS_CIRCLE_TYPES.REJECTED;
  } else if (expired) {
    status = DOC_COMPLETION_STATUS_LABELS.EXPIRED;
    statusCircleType = DOC_COMPLETION_STATUS_CIRCLE_TYPES.REJECTED;
  } else if (hasReviewSessions && hasParticipants && numSignersSigned === participants!.length) {
    status = DOC_COMPLETION_STATUS_LABELS.COMPLETE;
    statusCircleType = DOC_COMPLETION_STATUS_CIRCLE_TYPES.COMPLETE;
  } else if (hasReviewSessions && hasParticipants && numSignersReviewed === participants!.length) {
    status = DOC_COMPLETION_STATUS_LABELS.REVIEWED;
    statusCircleType = DOC_COMPLETION_STATUS_CIRCLE_TYPES.REVIEWED;
  } else if (numSignersSigned > 0 && completionStatus !== CompletionStatuses.COMPLETE) {
    status = DOC_COMPLETION_STATUS_LABELS.PARTIALLY_SIGNED;
    statusCircleType = DOC_COMPLETION_STATUS_CIRCLE_TYPES.PARTIALLY_SIGNED;
  } else if (!signAhead && hasParticipants && hasReviewSessions) {
    status = DOC_COMPLETION_STATUS_LABELS.REVIEWED_PRE_MEETING;
    statusCircleType = DOC_COMPLETION_STATUS_CIRCLE_TYPES.REVIEWED_PRE_MEETING;
  }
  return { status, statusCircleType, statusSubText };
}

function createDocumentRow<D extends Document>(options: RowOptions<D>) {
  const {
    expired,
    reviewSessions,
    showNotarialActs,
    showRefinalize,
    onViewDocument,
    intl,
    numSignersSigned,
    numSignersReviewed,
    onRefinalize,
  } = options;
  const {
    id: documentId,
    name,
    signedWetSign,
    rejectionReason,
    rejectionAnecdote,
    notarialActs,
  } = options.document;

  const unformattedRejectionMessage =
    rejectionReason &&
    (
      DOC_REJECTION_DESCRIPTIONS as Record<
        string,
        (typeof DOC_REJECTION_DESCRIPTIONS)[keyof typeof DOC_REJECTION_DESCRIPTIONS] | undefined
      >
    )[rejectionReason];
  const sanitizedRejectionAnecdote =
    rejectionAnecdote ?? intl.formatMessage(messages.noRejectionAnecdote);
  const compositeRejectionReason = unformattedRejectionMessage
    ? `${intl.formatMessage(unformattedRejectionMessage)} - ${sanitizedRejectionAnecdote}`
    : rejectionAnecdote;
  const { status, statusCircleType, statusSubText } = getStatus({
    document: options.document,
    numSignersSigned,
    intl,
    reviewSessions,
    numSignersReviewed,
    expired,
    requiresNsaMeeting: options.requiresNsaMeeting,
  });

  const statusCx = "DocumentList--row--mainText DocumentList--row--status--type";
  return [
    {
      className: classnames("DocumentList--row--title", showNotarialActs && "with-notarial-acts"),
      children: (
        <>
          <div className="DocumentList--row--mainText" data-automation-id="document-name">
            <div className="DocumentList--row--document">
              {onViewDocument ? (
                <ActionButton
                  onClick={() => onViewDocument(options.document)}
                  className="ellipsis"
                  automationId={`document-item-${name}`}
                >
                  {name}
                </ActionButton>
              ) : (
                <span data-automation-id={`document-item-${name}`} className="ellipsis">
                  {name}
                </span>
              )}
              {compositeRejectionReason && (
                <div className="DocumentList--row--document--text__error ellipsis">
                  {compositeRejectionReason}
                </div>
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      className: "DocumentList--row--status",
      children: signedWetSign ? (
        <div className={statusCx}>
          <div className="DocumentList--row--status--type--text">
            <div data-automation-id={`document-status-${documentId}`}>
              <FormattedMessage
                id="645abbbf-1eed-4937-b459-cfb8d6dba356"
                defaultMessage="Wet sign"
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <button
            className={statusCx}
            onClick={() => options.onToggleCompletions(options.document)}
            type="button"
            aria-expanded={options.isCompletionsOpen}
          >
            <div className="DocumentList--row--status--type--text--wrapper">
              <StatusCircle type={statusCircleType} />
              <div className="DocumentList--row--status--type--text">
                <div data-automation-id={`document-status-${documentId}`}>{status}</div>
                {statusSubText && (
                  <div className="DocumentList--row--status--type--text__sub">{statusSubText}</div>
                )}
              </div>
            </div>
            {options.showCompletionsDropdown && (
              <Icon className="icon" name={`caret-${options.isCompletionsOpen ? "up" : "down"}`} />
            )}
          </button>
        </>
      ),
    },
    ...(showNotarialActs
      ? [
          {
            className: "DocumentList--row--notarialActs",
            children: <span>{notarialActs.map(notarialActLabel).join(", ")}</span>,
          },
        ]
      : []),
    ...(showRefinalize
      ? [
          {
            className: classnames("DocumentList--row--refinalize"),
            children: (
              <div className="DocumentList--row--document">
                <ActionButton onClick={() => onRefinalize(options.document)}>
                  <FormattedMessage
                    id="9dc4a601-ea65-4b30-a5c8-c05ef90d8c77"
                    defaultMessage="Refinalize"
                  />
                </ActionButton>
              </div>
            ),
          },
        ]
      : []),
  ];
}

function createCompletionStatusRow<D extends Document>(options: {
  document: D;
  participantStatuses: ReactNode[];
  showNotarialActs: boolean;
}) {
  const { id: documentId, participants } = options.document;
  return [
    {
      className: "DocumentList--row--subText",
      children: participants!.map((participant) => (
        <div key={participant!.userId}>
          {userFullName(participant, participant?.representation)}
        </div>
      )),
    },
    {
      className: "DocumentList--row--subText DocumentList--row--status--progress",
      colSpan: options.showNotarialActs ? 2 : undefined,
      children: options.participantStatuses.map((participantStatus, index) => (
        <div key={index} data-automation-id={`status-${documentId}-${index}`}>
          {participantStatus}
        </div>
      )),
    },
  ];
}

function DocumentList<D extends Document>(props: Props<D>) {
  const {
    showNotarialActs,
    lastOpenedId,
    reviewSessions,
    expired,
    requiresNsaMeeting,
    showResponsiveView,
  } = props;
  const tableHeaders = [props.title, "Status"];
  const { hasPermissionFor } = usePermissions();
  const showRefinalize = hasPermissionFor("refinalizeDocument");

  if (showNotarialActs) {
    tableHeaders.push("Notarial Acts");
  }
  if (showRefinalize) {
    tableHeaders.push("Refinalize");
  }
  const [areCompletionsOpen, setIsCompletionsOpen] = useState<Record<string, boolean | undefined>>(
    {},
  );
  const [documentToRefinalize, setDocumentToRefinalize] = useState<Document | null>(null);
  const intl = useIntl();
  useEffect(() => {
    const lastOpenedRow = document.querySelector(".DocumentList--row--outlined");
    lastOpenedRow?.scrollIntoView({ block: "center" });
  }, []);

  const handleViewDocument = props.documentsViewable ? props.onClick : undefined;
  const handleToggleCompletions = ({ id }: Document) =>
    setIsCompletionsOpen((old) => ({ ...old, [id]: !old[id] }));

  const WrapperComponent = showResponsiveView ? Fragment : StandardTable;
  return (
    <>
      {!showResponsiveView && <div className="DocumentListOrder">{props.orderHeaderSpan}</div>}
      <WrapperComponent
        {...(!showResponsiveView && {
          fullWidth: true,
          className: "DocumentList",
          headings: tableHeaders,
        })}
      >
        {props.documents.map((document, i) => {
          const { id: documentId, participants, signedWetSign } = document;
          const isLastOpened = lastOpenedId === documentId;
          const isCompletionsOpen = Boolean(areCompletionsOpen[documentId]);
          let numSignersReviewed = 0; // Number of signers that have reviewed the bundle at all (not just this document)
          let numSignersSigned = 0; // Number of signers that have signed all of their designations on this document

          const showCompletions = Boolean(participants!.length && !signedWetSign);
          const participantStatuses = participants!.map((participant) => {
            const { userId, signingStatus, designationDetails, completedAt } = participant!;
            const { unfulfilledDesignationsCount, totalDesignationsCount } = designationDetails;

            const reviewSession = reviewSessions.find((session) => {
              return session!.userId === userId;
            });

            if (signingStatus === DocumentBundleParticipantStatus.COMPLETE) {
              numSignersSigned++;
              return showResponsiveView ? (
                <span key={`${userId}-signed`}>
                  <LongFormattedDateTime value={completedAt} />
                </span>
              ) : (
                <span key={`${userId}-signed`}>
                  <FormattedMessage
                    id="f83c4b88-b9b9-4708-bb2e-e2b88019ae1a"
                    defaultMessage="Completed -"
                  />
                  &nbsp;
                  <LongFormattedDateTime value={completedAt} />
                </span>
              );
            }

            if (!reviewSession) {
              return (
                <FormattedMessage
                  key={`${userId}-notreviewed`}
                  id="0fc48d60-e241-461a-9afa-70d9d634f87d"
                  defaultMessage="Not Reviewed"
                />
              );
            }

            if (unfulfilledDesignationsCount > 0 || totalDesignationsCount === 0) {
              numSignersReviewed++;
              return (
                <span key={`${userId}-unfulfilleddes`}>
                  <FormattedMessage
                    id="90e384e7-bfe1-4815-b80e-20599d3a4281"
                    defaultMessage="Reviewed -"
                  />
                  &nbsp;
                  <LongFormattedDateTime value={reviewSession.createdAt} />
                </span>
              );
            }
            return (
              <span key={`${userId}-signed`}>
                <FormattedMessage
                  id="92ca4a80-c7ed-41af-81dd-68cc61777037"
                  defaultMessage="Completed -"
                />
                &nbsp;
                <LongFormattedDateTime value={reviewSession.createdAt} />
              </span>
            );
          });

          const rowCx = classnames("DocumentList--row", {
            "DocumentList--row--outlined": isLastOpened,
            "DocumentList--row--outlined--top": isCompletionsOpen,
          });
          const completionRowCx = classnames("DocumentList--row", {
            "DocumentList--row--outlined": isLastOpened,
            "DocumentList--row--outlined--bottom": isLastOpened,
          });
          if (showResponsiveView) {
            const { status, statusCircleType, statusSubText } = getStatus({
              document,
              numSignersSigned,
              intl,
              reviewSessions,
              numSignersReviewed,
              expired,
              requiresNsaMeeting,
            });
            return (
              <DeprecatedDetailGridSection key={documentId} className={"DocumentList-responsive"}>
                <DeprecatedDetailGridRow {...(i === 0 && { title: props.title })}>
                  <div className="document-wrapper">
                    <div>
                      <div
                        data-automation-id="document-name"
                        className="DocumentList--row--status--type--text sub-row"
                      >
                        {handleViewDocument ? (
                          <ActionButton
                            onClick={() => handleViewDocument(document)}
                            className="document-name ellipsis"
                            automationId={`document-item-${document.name}`}
                          >
                            {document.name}
                          </ActionButton>
                        ) : (
                          <span
                            data-automation-id={`document-item-${document.name}`}
                            className="document-name ellipsis"
                          >
                            {document.name}
                          </span>
                        )}
                      </div>
                      {props.organizationName && (
                        <div className="sub-row all-caps">{props.organizationName}</div>
                      )}
                      {signedWetSign ? (
                        <div className="DocumentList--row--status--type--text sub-row">
                          <div data-automation-id={`document-status-${documentId}`}>
                            <FormattedMessage
                              id="cd39dd56-0bfb-4ada-a266-50a1429f73ef"
                              defaultMessage="Wet Sign"
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="DocumentList--row--status--type--text--wrapper sub-row">
                            <StatusCircle type={statusCircleType} />
                            <div className="DocumentList--row--status--type--text">
                              <div data-automation-id={`document-status-${documentId}`}>
                                {status}
                              </div>
                              {statusSubText && (
                                <div className="DocumentList--row--status--type--text__sub">
                                  {statusSubText}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      {handleViewDocument && (
                        <Button
                          buttonColor="action"
                          variant="secondary"
                          onClick={() => handleViewDocument(document)}
                          className="document-name all-caps"
                          automationId={`document-item-${document.name}-view`}
                        >
                          <FormattedMessage
                            id="244a991e-fb77-4dc7-b363-82202b8f472c"
                            defaultMessage="View"
                          />
                        </Button>
                      )}
                    </div>
                  </div>
                  {showCompletions && (
                    <div className="completions">
                      {participants!.map((participant, i) => (
                        <div key={participant!.userId} className="completion-row">
                          <div>{userFullName(participant, participant?.representation)}</div>
                          <div>{participantStatuses[i]}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </DeprecatedDetailGridRow>
              </DeprecatedDetailGridSection>
            );
          }
          return (
            <Fragment key={documentId}>
              <Row
                className={rowCx}
                cells={createDocumentRow({
                  document,
                  reviewSessions,
                  intl,
                  requiresNsaMeeting,
                  onViewDocument: handleViewDocument,
                  showNotarialActs: Boolean(showNotarialActs),
                  showRefinalize: Boolean(showRefinalize),
                  expired: Boolean(expired),
                  numSignersReviewed,
                  numSignersSigned,
                  showCompletionsDropdown: showCompletions,
                  isCompletionsOpen,
                  onToggleCompletions: handleToggleCompletions,
                  onRefinalize: (document) => {
                    setDocumentToRefinalize(document);
                  },
                })}
              />
              {showCompletions && isCompletionsOpen && (
                <Row
                  className={completionRowCx}
                  cells={createCompletionStatusRow({
                    document,
                    participantStatuses,
                    showNotarialActs: Boolean(showNotarialActs),
                  })}
                />
              )}
            </Fragment>
          );
        })}
      </WrapperComponent>
      {!!documentToRefinalize && (
        <RefinalizeModal
          document={documentToRefinalize}
          onCancel={() => setDocumentToRefinalize(null)}
        />
      )}
    </>
  );
}

export default DocumentList;
