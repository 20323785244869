import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph, Heading, Substyle } from "common/core/typography";
import { Select } from "common/core/form/select";
import { userFullName } from "util/user";
import OverallRiskLevel from "common/details/identity/common";

import type { RiskModalSignerIdentity } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

export type CustomerSigner = {
  userId: string | null;
  signerIdentities: RiskModalSignerIdentity[] | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
};

type Props = {
  customerSigners: CustomerSigner[];
  customerSignerId: string;
  signerIdentityId?: string;
  onChangeAttempt: (signerIdentityId: string) => void;
  onClose: () => void;
};

const MESSAGES = defineMessages({
  attemptLabel: {
    id: "59d75c01-48d0-4e6b-98d3-1bc69441d812",
    defaultMessage: "Attempt {attemptNumber}",
  },
  changeAttemptLabel: {
    id: "43f70a63-d3ab-488f-ad4f-88627e324e5a",
    defaultMessage: "Change attempt",
  },
});

export function RiskModal({
  customerSigners,
  customerSignerId,
  signerIdentityId,
  onChangeAttempt,
  onClose,
}: Props) {
  const intl = useIntl();
  const customerSigner = customerSigners.find((cs) => cs.userId === customerSignerId);
  const signerIdentityIndex = signerIdentityId
    ? (customerSigner?.signerIdentities ?? []).findIndex((si) => si.id === signerIdentityId)
    : 0;
  const signerIdentity = customerSigner!.signerIdentities?.[signerIdentityIndex];

  if (!customerSigner || !signerIdentity) {
    throw new Error(
      `Cannot find signer identity index ${signerIdentityIndex} from customer signer ID ${customerSignerId} opening Risk Modal`,
    );
  }

  return (
    <WorkflowModal
      closeBehavior={{ tag: "with-button", onClose }}
      title={
        <FormattedMessage
          id="eb308df5-8ad6-4938-ab31-8d81a5428959"
          defaultMessage="Identity risk signals"
        />
      }
      footerSeparator={false}
    >
      <div className={Styles.container}>
        <Paragraph textColor="subtle">
          <FormattedMessage
            id="a93abf93-6b70-4a34-b0c7-6c79e1826c4f"
            defaultMessage="The Proof risk score is derived from email address, phone number, and various risk signals during each verification attempt."
          />
        </Paragraph>
        {(customerSigner.signerIdentities?.length ?? 0) > 0 && (
          <Select
            value={signerIdentityId}
            aria-label={intl.formatMessage(MESSAGES.changeAttemptLabel)}
            aria-invalid={false}
            items={customerSigner.signerIdentities!.map((si, index) => ({
              value: si.id,
              label: intl.formatMessage(MESSAGES.attemptLabel, {
                attemptNumber: customerSigner.signerIdentities!.length - index,
              }),
            }))}
            onChange={({ target }) => onChangeAttempt(target.value)}
          />
        )}
        <div className={Styles.heading}>
          <Heading level="h2" textStyle="headingSix">
            <Substyle textColor="subtle">{userFullName(customerSigner)}</Substyle>
          </Heading>
          {signerIdentity.aggregateRiskLevel && (
            <OverallRiskLevel riskLevel={signerIdentity.aggregateRiskLevel} />
          )}
        </div>
        {signerIdentity.riskSignals?.length ? (
          <ul>
            {signerIdentity.riskSignals.map(({ description }, index) => (
              <li key={`risk-signal-${index}`}>{description}</li>
            ))}
          </ul>
        ) : (
          <Paragraph textColor="subtle">
            <FormattedMessage
              id="e97239b9-ab4a-4a68-ac49-3ce899c61879"
              defaultMessage="There are no risk signals associated with this identity verification attempt"
            />
          </Paragraph>
        )}
      </div>
    </WorkflowModal>
  );
}
