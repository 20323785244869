import PropTypes from "prop-types";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";

function ActivationLink(props) {
  const { activationLink } = props;

  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title="Activation Link">
        <div className={"TransactionDetails--summary-charge-status"}>
          {activationLink ? activationLink : "N/A"}
        </div>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default ActivationLink;

ActivationLink.propTypes = {
  activationLink: PropTypes.string,
};
