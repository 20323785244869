import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";

import Styles from "./transaction_message.module.scss";

type Props = {
  transaction: { message: null | string };
};

export default function TransactionMessage({ transaction }: Props) {
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage id="b66aed90-7e6a-481e-a334-48f0685db36b" defaultMessage="Message" />
        }
        className={Styles.row}
      >
        <p className="DetailGrid--scrolling-row">
          {transaction.message?.split(/\n/).map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          }) || (
            <FormattedMessage
              id="50b88db3-f81d-4617-8cea-b11db1852380"
              defaultMessage="No message provided"
            />
          )}
        </p>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}
