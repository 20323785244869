import { useState, useCallback, useMemo, useEffect, type ReactElement } from "react";

import type Channel from "socket/channel";
import { fromSocketEvent, safeSubscribeToChannel } from "socket/util";
import { useSubject } from "util/rxjs/hooks";
import { getUserDevicePreferences, saveUserDevicePreferences } from "util/device_preferences";

export type Devices = {
  phone?: string | null;
  speaker?: string | null;
  microphone?: string | null;
  webcam?: string | null;
};
type ShownState = "open" | "openWithMessaging" | false;
export type SelectedDevicesRenderProps = {
  onChangeDevices: (devices: Devices) => void;
  selectedDevices: Devices;
  showAVSettings: ShownState;
  toggleAVSettings: () => void;
};
type Props = {
  children: (params: SelectedDevicesRenderProps) => ReactElement;
  channel?: Channel;
};

function useChannelAVAlerts(
  channel: Channel | undefined,
  setShowAVSettings: (s: ShownState) => void,
) {
  const unmounted$ = useSubject<null>();
  useEffect(() => () => unmounted$.next(null), []);
  useEffect(() => {
    if (!channel) {
      return () => {};
    }
    safeSubscribeToChannel(
      unmounted$,
      fromSocketEvent(channel, "video_conference.av.failed"),
      () => {
        setShowAVSettings("openWithMessaging");
      },
    );
  }, [channel]);
}

export function useSelectedDevices() {
  const persistedSelectedDevices = useMemo(() => getUserDevicePreferences(), []);
  const [selectedDevices, setSelectedDevices] = useState<Devices>(persistedSelectedDevices);
  const onChangeDevices = useCallback((newDevices: Devices) => {
    setSelectedDevices((oldDevices) => ({ ...oldDevices, ...newDevices }));
  }, []);
  useEffect(() => {
    saveUserDevicePreferences({
      phoneNumber: selectedDevices.phone,
      speakerDeviceId: selectedDevices.speaker,
      microphoneDeviceId: selectedDevices.microphone,
      webcamDeviceId: selectedDevices.webcam,
    });
  }, [selectedDevices]);
  return { onChangeDevices, selectedDevices };
}

function SelectedDevicesController({ children, channel }: Props) {
  const { onChangeDevices, selectedDevices } = useSelectedDevices();
  const [showAVSettings, setShowAVSettings] = useState<ShownState>(
    selectedDevices.phone ? "open" : false,
  );
  useChannelAVAlerts(channel, setShowAVSettings);
  const toggleAVSettings = useCallback(() => setShowAVSettings((av) => (av ? false : "open")), []);
  return children({
    onChangeDevices,
    selectedDevices,
    showAVSettings,
    toggleAVSettings,
  });
}

export default SelectedDevicesController;
