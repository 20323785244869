import "../account_onboarding.scss";

import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps } from "redux-form";

import Button from "common/core/button";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import TextField from "common/form/fields/text";
import AddressSubForm, {
  validationRules as addressValidationRules,
} from "common/form/sub_forms/address";
import SelectField from "common/form/fields/select";
import { composeValidators } from "util/form";
import { normalizeUSPhone } from "util/normalize";
import { validatePresence, validatePhoneNumberLength } from "validators/form";
import type { AddressType } from "graphql_globals";

import type { AccountSetupOrganization as Organization } from "./organization_fragment.graphql";

const ITEMS = [
  {
    value: "1 – 24",
    label: "1 – 24",
  },
  {
    value: "25 - 99",
    label: "25 - 99",
  },
  {
    value: "100 – 249",
    label: "100 – 249",
  },
  {
    value: "250+",
    label: "250+",
  },
];

type Props = {
  loading: boolean;
  onFormSubmit: (values: FormValues) => void;
  hasBusinessName?: boolean;
  organization: Organization;
  expectedVolumeResponse: string | null;
};

type InnerProps = InjectedFormProps<FormValues, Props> & Props;
export type FormValues = {
  organizationName: string | null;
  phoneNumber: string | null;
  altaId: string | null;
  titleAgencyAddress: AddressType | null;
  expectedVolume: string | null;
};

const validate = (values: FormValues) =>
  composeValidators(
    validatePresence({ field: "organizationName", label: "Organization name" }),
    validatePhoneNumberLength({
      isInternational: false,
      field: "phoneNumber",
      label: "Phone number",
    }),
    validatePresence({ field: "expectedVolume", label: "Expected monthly volume" }),
    addressValidationRules(values, {}, "titleAgencyAddress."),
  )(values);

const CX = "TitleAccountOnboardingAccountSetup";
const automationId = "TitleAccountOnboardingAccountSetupSlide";
const formName = "TitleAccountOnboardingAccountSetupForm";

function TitleAccountOnboardingAccountSetupSlide(props: InnerProps) {
  const {
    organization,
    hasBusinessName,
    expectedVolumeResponse,
    loading,
    onFormSubmit,
    initialize,
    handleSubmit,
  } = props;

  useEffect(() => {
    initialize({
      phoneNumber: organization.titleAgencyProfile?.phone,
      altaId: organization.titleAgencyProfile?.altaId,
      titleAgencyAddress: organization.address,
      organizationName:
        hasBusinessName || organization.parentOrganizationId
          ? organization.titleAgencyProfile?.name
          : undefined,
      expectedVolume: expectedVolumeResponse,
    });
  }, []);

  return (
    <div className={CX}>
      <div className={`${CX}--slide-title`}>
        <FormattedMessage
          id="8aedc982-9c86-43d7-a9fd-49c7f177fe2d"
          defaultMessage="Tell us about your company"
        />
      </div>
      <div className={`${CX}--subtitle`}>
        <FormattedMessage
          id="f0b0da55-c39f-430a-8ef0-c76737f6cd87"
          defaultMessage="We need a few more details about your company to get you up and running"
        />
      </div>
      <div className={`${CX}--form`}>
        <FormGroup fields={["organizationName"]} disableFormRowStyle>
          <TextField
            name="organizationName"
            placeholder={
              <FormattedMessage
                id="a44688ab-d1a1-4d43-90ea-b42631009d38"
                defaultMessage="Business Name"
              />
            }
            displayRequiredAsterisk
            useStyledInput
            placeholderAsLabel
            className={`${CX}--organization-name`}
            automationId={`${automationId}-organization-name`}
          />
          <FormGroupErrors fields={["organizationName"]} alert />
        </FormGroup>
        <FormGroup fields={["phoneNumber", "altaId"]} disableFormRowStyle>
          <div className={`${CX}--two-part-input`}>
            <TextField
              name="phoneNumber"
              placeholder={
                <FormattedMessage
                  id="9e8dba9d-2309-454f-b50c-dcc141fa6540"
                  defaultMessage="Phone Number"
                />
              }
              displayRequiredAsterisk
              useStyledInput
              placeholderAsLabel
              normalize={normalizeUSPhone}
              automationId={`${automationId}-phone-number`}
            />
            <TextField
              name="altaId"
              placeholder={
                <FormattedMessage
                  id="78f8d618-0fc1-4cb5-8e58-eb0688aa0d2f"
                  defaultMessage="Alta ID"
                />
              }
              useStyledInput
              placeholderAsLabel
              automationId={`${automationId}-alta-id`}
            />
          </div>
          <FormGroupErrors fields={["phoneNumber"]} alert />
        </FormGroup>

        <FormGroup fields={["expectedVolume"]} disableFormRowStyle>
          <SelectField
            className={`${CX}--expected-volume`}
            name="expectedVolume"
            useStyledInput
            displayRequiredAsterisk
            placeholder={
              <FormattedMessage
                id="a2f0cd5d-43c8-4833-b1df-17a930563652"
                defaultMessage="Expected Monthly Volume"
              />
            }
            items={ITEMS}
            automationId={`${automationId}-expected-volume`}
          />
          <FormGroupErrors fields={["expectedVolume"]} />
        </FormGroup>

        <FormGroup disableFormRowStyle fields={["titleAgencyAddress"]}>
          <AddressSubForm
            formName={formName}
            useStyledInputs
            fieldNamePrefix={"titleAgencyAddress"}
            displayRequiredAsterisk
          />
          <FormGroupErrors fields={["titleAgencyAddress"]} />
        </FormGroup>
      </div>
      <Button
        isLoading={loading}
        className={`${CX}--save-button`}
        buttonColor="action"
        variant="primary"
        onClick={handleSubmit(onFormSubmit)}
        automationId={`${automationId}-save-button`}
      >
        <FormattedMessage
          id="4c3c02e0-6aa4-4302-8fe2-615e00cf4949"
          defaultMessage="Save and Continue"
        />
      </Button>
    </div>
  );
}

const formEnhancer = reduxForm<FormValues, Props>({
  form: formName,
  validate,
});

export default formEnhancer(TitleAccountOnboardingAccountSetupSlide);
