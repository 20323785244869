import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { SigningScheduleTypes } from "graphql_globals";
import Link from "common/core/link";

import Styles from "./signing_schedule_type.module.scss";

type Props = {
  transaction: {
    signingScheduleType: null | SigningScheduleTypes;
  };
  onReschedulerOpen?: () => void;
};

function renderSigningTypeLabel(signType: SigningScheduleTypes) {
  switch (signType) {
    case SigningScheduleTypes.DATE:
      return (
        <FormattedMessage id="1efc0329-56ff-4cae-bb9a-65d3553e3120" defaultMessage="Closing date" />
      );
    case SigningScheduleTypes.WINDOW:
      return (
        <FormattedMessage id="4d79ab28-bd93-4d5c-8702-125c8dd33277" defaultMessage="Date window" />
      );
    default:
      return <FormattedMessage id="4fae1b1b-4f50-4c13-bcf6-63264be9d9d2" defaultMessage="N/A" />;
  }
}

function SigningScheduleType({ transaction, onReschedulerOpen }: Props) {
  const { signingScheduleType } = transaction;
  if (!signingScheduleType) {
    return null;
  }
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage
            id="03ec7c32-6e7d-451a-8cb8-23690bee3fa3"
            defaultMessage="Signing schedule"
          />
        }
      >
        {/* must be div to horizontally stack children w/ parent's flex css property */}
        <div>
          {renderSigningTypeLabel(signingScheduleType)}
          {onReschedulerOpen && (
            <Link
              underlined={false}
              className={Styles.rescheduleButton}
              onClick={onReschedulerOpen}
            >
              <FormattedMessage
                id="735c008a-ab05-42ec-80bf-85ed5004212d"
                defaultMessage="Reschedule"
              />
            </Link>
          )}
        </div>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default SigningScheduleType;
