import { FormattedMessage, useIntl } from "react-intl";

import { format } from "common/core/format/date";
import { Container, Row } from "common/core/responsive";
import { Substyle } from "common/core/typography";
import { IdentityAttributeLabels } from "common/identity/attributes";
import {
  IdentityAttributeName,
  PhotoIdVerificationStatus,
  PhotoIdVerificationVerificationType,
} from "graphql_globals";
import { FormattedValidationSource } from "common/core/format/formatted_validation_source";
import { usePermissions } from "common/core/current_user_role";
import FormattedAddress from "common/core/format/address";
import { Badge } from "common/core/badge";

import type {
  IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_proofRequirement as ProofRequirementInputType,
  IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_signerIdentities as TransactionSignerIdentity,
  IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners_signerIdentities_identityAttributes as IdentityAttribute,
} from "./index.query.graphql";
import { Col, SensitiveValue } from ".";

type BaseBadgeProps = {
  status: PhotoIdVerificationStatus;
  failedVerificationTypes: PhotoIdVerificationVerificationType[];
};
type BiometricBadgeProps = BaseBadgeProps & {
  proofRequirement: ProofRequirementInputType | null;
  hasSelfie: boolean;
};

const VisibleAttributes = [
  IdentityAttributeName.FULL_NAME,
  IdentityAttributeName.DATE_OF_BIRTH,
  IdentityAttributeName.HOME_ADDRESS,
];
const SensitiveAttributes = [
  IdentityAttributeName.DATE_OF_BIRTH,
  IdentityAttributeName.HOME_ADDRESS,
];

function identityAttributesSorter(a: IdentityAttribute, b: IdentityAttribute) {
  const orderedAttributesByName = [
    IdentityAttributeName.FULL_NAME,
    IdentityAttributeName.HOME_ADDRESS,
    IdentityAttributeName.EMAIL,
    IdentityAttributeName.PHONE,
    IdentityAttributeName.DATE_OF_BIRTH,
  ];

  return orderedAttributesByName.indexOf(a.name) - orderedAttributesByName.indexOf(b.name);
}

function identityAttributesFilter(attribute: IdentityAttribute) {
  return VisibleAttributes.includes(attribute.name);
}

function getAttributeValue(attribute: IdentityAttribute) {
  switch (attribute.__typename) {
    case "IdentityAttributeDob":
      return format({ value: attribute.dateOfBirthValue, formatStyle: "MM/dd/yyyy" })!;
    case "IdentityAttributeFullName":
      return attribute.fullNameValue;
    case "IdentityAttributeHomeAddress":
      return attribute.homeAddressValue.line1 ? (
        <FormattedAddress address={attribute.homeAddressValue} />
      ) : (
        <StatusBadge status={PhotoIdVerificationStatus.UNKNOWN} />
      );
  }
}

function getAttributeObfuscatedValue(attribute: IdentityAttribute) {
  switch (attribute.__typename) {
    case "IdentityAttributeDob":
      return "**/**/****";
    case "IdentityAttributeHomeAddress":
      return "****";
    default:
      return getAttributeValue(attribute);
  }
}

function StatusBadge({ status }: { status: PhotoIdVerificationStatus }) {
  const badgeKind =
    status === PhotoIdVerificationStatus.SUCCESS
      ? "success"
      : status === PhotoIdVerificationStatus.FAILURE ||
          status === PhotoIdVerificationStatus.ACTION_REQUIRED
        ? "warning"
        : "infoSubtle";
  return (
    <Badge kind={badgeKind}>
      {(() => {
        switch (status) {
          case PhotoIdVerificationStatus.SUCCESS:
            return (
              <FormattedMessage id="e389f875-d89a-4f8b-b7c0-bca7ccf02c19" defaultMessage="Passed" />
            );
          case PhotoIdVerificationStatus.FAILURE:
            return (
              <FormattedMessage id="f7a8ae7c-d6fc-4eb8-b044-5507953d2d69" defaultMessage="Failed" />
            );
          case PhotoIdVerificationStatus.PENDING:
            return (
              <FormattedMessage
                id="12353853-d673-4df1-bd47-2f49e0e78f81"
                defaultMessage="Pending"
              />
            );
          case PhotoIdVerificationStatus.UNKNOWN:
            return (
              <FormattedMessage id="1d523778-6a09-4053-b122-d6c48e6f5e17" defaultMessage="N/A" />
            );
          case PhotoIdVerificationStatus.ACTION_REQUIRED:
            return (
              <FormattedMessage
                id="b471edac-2434-4ef2-8d04-4dd60e47130d"
                defaultMessage="Action Required"
              />
            );
        }
      })()}
    </Badge>
  );
}

function CredentialAnalysisStatusBadge({ status, failedVerificationTypes }: BaseBadgeProps) {
  if (status === PhotoIdVerificationStatus.FAILURE) {
    return (
      <StatusBadge
        status={
          failedVerificationTypes.includes(PhotoIdVerificationVerificationType.ID)
            ? PhotoIdVerificationStatus.FAILURE
            : PhotoIdVerificationStatus.SUCCESS
        }
      />
    );
  }
  return <StatusBadge status={status} />;
}

function BiometricStatusBadge({
  status,
  failedVerificationTypes,
  proofRequirement,
  hasSelfie,
}: BiometricBadgeProps) {
  if (!proofRequirement?.ca?.selfie && !hasSelfie) {
    return <StatusBadge status={PhotoIdVerificationStatus.UNKNOWN} />;
  }
  if (status === PhotoIdVerificationStatus.FAILURE) {
    return (
      <StatusBadge
        status={
          failedVerificationTypes.includes(PhotoIdVerificationVerificationType.SELFIE)
            ? PhotoIdVerificationStatus.FAILURE
            : PhotoIdVerificationStatus.SUCCESS
        }
      />
    );
  }
  if (status === PhotoIdVerificationStatus.SUCCESS) {
    return <StatusBadge status={PhotoIdVerificationStatus.SUCCESS} />;
  }
  return <StatusBadge status={PhotoIdVerificationStatus.UNKNOWN} />;
}

function Attribute({ attribute }: { attribute: IdentityAttribute }) {
  const intl = useIntl();
  const containsSensitiveData = SensitiveAttributes.includes(attribute.name);

  return (
    <Col>
      <dt>
        <Substyle textColor="subtle" size="small">
          {intl.formatMessage(IdentityAttributeLabels[attribute.name])}
        </Substyle>
      </dt>
      <dd>
        <Substyle size="defaultSize">
          {containsSensitiveData ? (
            <SensitiveValue
              value={getAttributeValue(attribute)}
              obfuscatedValue={getAttributeObfuscatedValue(attribute)}
            />
          ) : (
            getAttributeValue(attribute)
          )}
        </Substyle>
      </dd>
    </Col>
  );
}

export default function IdentityProfile({
  signerIdentity,
  proofRequirement,
}: {
  signerIdentity: TransactionSignerIdentity;
  proofRequirement: ProofRequirementInputType | null;
}) {
  const { hasPermissionFor } = usePermissions();
  const status = signerIdentity.photoId?.statusV2 || PhotoIdVerificationStatus.UNKNOWN;
  const failedVerificationTypes = signerIdentity.photoId?.failedVerificationTypes ?? [];

  return (
    <dl>
      <Container fluid noSpacing>
        <Row>
          {[...(signerIdentity.identityAttributes || [])]
            .filter(identityAttributesFilter)
            .sort(identityAttributesSorter)
            .map((attribute) => (
              <Attribute key={attribute.id} attribute={attribute} />
            ))}
          <Col>
            <dt>
              <Substyle textColor="subtle" size="small">
                <FormattedMessage
                  id="6d5da7fd-4494-45cb-bf98-dd16335d70d3"
                  defaultMessage="Credential analysis"
                />
              </Substyle>
            </dt>
            <dd>
              <CredentialAnalysisStatusBadge
                status={status}
                failedVerificationTypes={failedVerificationTypes}
              />
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle textColor="subtle" size="small">
                <FormattedMessage
                  id="112e2f9c-8081-4cc7-b7c6-69c1d36e2202"
                  defaultMessage="Selfie comparison"
                />
              </Substyle>
            </dt>
            <dd>
              <BiometricStatusBadge
                status={status}
                failedVerificationTypes={failedVerificationTypes}
                proofRequirement={proofRequirement}
                hasSelfie={Boolean(signerIdentity.photoId?.selfiePicture)}
              />
            </dd>
          </Col>
          {proofRequirement && hasPermissionFor("userDetails") && signerIdentity.photoId && (
            <Col>
              <dt>
                <Substyle textColor="subtle" size="small">
                  <FormattedMessage
                    id="ad4da5d3-858a-46fe-9f7c-8f9bb6ce76cd"
                    defaultMessage="Validation source"
                  />
                </Substyle>
              </dt>
              <dd>
                <Substyle size="defaultSize">
                  <FormattedValidationSource validationSource={signerIdentity.photoId.provider} />
                </Substyle>
              </dd>
            </Col>
          )}
        </Row>
      </Container>
    </dl>
  );
}
