import { Navigate } from "react-router-dom";

import { Feature } from "graphql_globals";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery } from "util/graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { useIAnav } from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";
import { TRANSACTION_PATH } from "util/routes";
import { HIDE_GET_STARTED } from "constants/feature_gates";

import { Root } from "../common/root";
import { Header } from "../common/header";
import { TitleWorkflows, ResourcesSection } from "../common/section";
import { TitleAdminGetStarted } from "./admin";
import TitleGetStartedQuery, {
  type TitleGetStarted_viewer_user as User,
  type TitleGetStarted_organization_Organization as Organization,
} from "./title_get_started_query.graphql";

export type NotaryInfo = {
  state: "incomplete" | "needs_review" | "compliant" | "not_compliant";
  trainingEnabled: boolean;
};
type Props = {
  user: User;
  organization: Organization;
};

function GetStartedV2Content({ organization }: { organization: Organization }) {
  return (
    <div>
      <Header isTitle organizationId={organization.id} />
      <Root>
        <TitleWorkflows />
        <ResourcesSection />
      </Root>
    </div>
  );
}

function LoadedTitleGetStarted({ user, organization }: Props) {
  const name = user.firstName!;
  const notaryEnabled = organization.featureList.includes(Feature.ORGANIZATION_NOTARIES);
  const { employeeLimit } = organization.activeTier.options;
  const hasSeats =
    employeeLimit === null
      ? organization.memberships.totalCount < 3
      : employeeLimit - organization.memberships.totalCount > 0;

  const getStartedV2Enabled = useFeatureFlag("get-started-v2");

  if (getStartedV2Enabled) {
    return <GetStartedV2Content organization={organization} />;
  }

  return (
    <TitleAdminGetStarted
      name={name}
      hasSeats={hasSeats}
      notaryProfile={user.notaryProfile}
      hasLogo={Boolean(organization.logoUrl)}
      billingSetup={organization.paymentSpecified!}
      notaryEnabled={notaryEnabled}
    />
  );
}

export function TitleGetStarted() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(TitleGetStartedQuery, {
    variables: { orgId: activeOrganizationId! },
  });
  const isIaNav = useIAnav();
  const hideGetStarted = useFeatureFlag(HIDE_GET_STARTED);

  const organization = data?.organization as Organization | undefined;

  if (hideGetStarted || (!isIaNav && !loading)) {
    return <Navigate to={TRANSACTION_PATH} replace />;
  }

  if (loading || !isIaNav) {
    return <LoadingIndicator />;
  }

  return <LoadedTitleGetStarted user={data!.viewer.user!} organization={organization!} />;
}
