import { CustomFormattedDateTime } from "common/core/format/date";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { EXPIRY_DATE_TIMEZONE_FORMAT } from "constants/transaction";
import type { SigningTimeSchedule_organizationTransaction_OrganizationTransaction as OrganizationTransaction } from "common/details/summary/signing_time_schedule/index.query.graphql";

type Props = {
  transaction: OrganizationTransaction;
};

function TransactionExpiry({ transaction }: Props) {
  const { expiry, expiryTimezone } = transaction;
  if (!expiry) {
    return null;
  }
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title="Transaction Expiration">
        <CustomFormattedDateTime
          value={expiry}
          asTimeZone={expiryTimezone}
          formatStyle={EXPIRY_DATE_TIMEZONE_FORMAT}
        />
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default TransactionExpiry;
