import "./admin_tools.scss";
import { useState, type ChangeEvent, type ComponentProps } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { useId } from "util/html";
import { useQuery, useMutation } from "util/graphql";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import Link from "common/core/link";
import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { StyledTextInput } from "common/form/inputs/text";
import { usePermissions } from "common/core/current_user_role";
import { AsyncJobStatus } from "graphql_globals";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import ForceCompleteTransactionMutation from "./force_complete_transaction_mutation.graphql";
import RetryPostMeetingJobMutation from "./retry_post_meeting_job_mutation.graphql";
import AsyncJobsQuery, {
  type AsyncJobs as AsyncJobsQueryType,
  type AsyncJobs_organizationTransaction_OrganizationTransaction_asyncJobs as AsyncJob,
  type AsyncJobs_organizationTransaction_OrganizationTransaction as AsyncJobTransaction,
} from "./async_jobs_query.graphql";
import ReleaseTransactionJobMutation from "./release_transaction_job_mutation.graphql";

const MEETING_NOT_FOUND_MESSAGE = (
  <FormattedMessage
    id="1d7cbc1f-8ecb-4fb2-b457-46e5e2353375"
    defaultMessage="There is no completed meeting to retry, the meeting might be ongoing or was terminated by the notary."
  />
);
const UNAUTHORIZED_USER_MESSAGE = (
  <FormattedMessage
    id="8285ec19-d270-4852-8e4b-9a844794166f"
    defaultMessage="You are not authorized for administrative tool because you are not a super admin."
  />
);
const RETRY_FAILED_MESSAGE = (
  <FormattedMessage id="ccccab9b-7a03-4183-83f6-242a3dc071fb" defaultMessage="The retry failed." />
);

const messages = defineMessages({
  releaseError: {
    id: "ebefcb09-702f-4914-bd3a-debbcd82fdd0",
    defaultMessage: "Releasing the transaction failed. Please ask the Documents Team for details.",
  },
  releaseLink: {
    id: "a24bf7b9-1e11-4c06-b569-d723859ab0f5",
    defaultMessage: "ReleaseLink",
  },
  disallowedIntegration: {
    id: "9d63b052-4cdb-4fe5-b0ac-bdc3997adace",
    defaultMessage:
      "The transaction cannot be released because it is not from a supported integration.",
  },
  cancelRelease: {
    id: "8cc6151b-ed1e-47cd-8c4f-b756e1b0e8e6",
    defaultMessage: "Cancel Release",
  },
  releaseComplete: {
    id: "a7b49981-2c0c-4ad8-967d-94f29380481a",
    defaultMessage: "Release complete!",
  },
});

type Props = {
  organizationTransactionId: string;
  documentBundleId: string;
};

type RetryState =
  | null
  | { tag: "error"; message: string }
  | { tag: "success" }
  | { tag: "loading" };

function AdminTools(props: Props) {
  const intl = useIntl();
  const retryReasonId = useId();
  const forceCompleteId = useId();

  const [showForceCompleteModal, setForceCompleteModal] = useState(false);
  const [showReleaseTransactionModal, setReleaseTransactionModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isLoadingRelease, setLoadingRelease] = useState(false);

  const [completionReason, setCompletionReason] = useState("");
  const [error, setError] = useState<string | null>(null);

  const forceCompleteTransaction = useMutation(ForceCompleteTransactionMutation);

  const [retry, setRetry] = useState<RetryState>(null);
  const [showRetryMeetingModal, setRetryMeetingModal] = useState(false);
  const [retryReason, setRetryReason] = useState("");

  const retryPostMeetingJob = useMutation(RetryPostMeetingJobMutation);

  const { hasPermissionFor } = usePermissions();
  const organizationTransactionId = props.organizationTransactionId;

  const releaseTransactionJob = useMutation(ReleaseTransactionJobMutation);
  const asyncJobsQueryObservable = useQuery(AsyncJobsQuery, {
    variables: {
      organizationTransactionId,
    },
  }).observable;

  const onCancelRelease = () => {
    setLoadingRelease(false);
    setReleaseTransactionModal(false);
  };
  const onCloseRelease = () => {
    asyncJobsQueryObservable.stopPolling();
    onCancelRelease();
  };

  function checkJob(asyncJob: AsyncJob) {
    switch (asyncJob.status) {
      case AsyncJobStatus.FAILED: {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(messages.releaseError),
        });
        break;
      }
      case AsyncJobStatus.COMPLETED: {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          message: intl.formatMessage(messages.releaseComplete),
        });
        break;
      }
    }
  }

  function filterAsyncJob(asyncJobId: string | undefined) {
    return (object: { data: AsyncJobsQueryType }) => {
      const organizationTransaction = object.data.organizationTransaction;

      if (!organizationTransaction) {
        return null;
      }
      const asyncJobs: AsyncJob[] = (organizationTransaction as AsyncJobTransaction).asyncJobs;

      const filteredAsyncJob = asyncJobs.find((asyncJob) => {
        return (
          asyncJob.id === asyncJobId &&
          asyncJob.status !== AsyncJobStatus.PENDING &&
          asyncJob.name === "ReleaseTransactionBatchJob"
        );
      });

      return filteredAsyncJob || null;
    };
  }

  function releaseErrorToMessage(error: Error): { id: string; defaultMessage: string } {
    switch (error.message) {
      case "disallowed_integration":
        return messages.disallowedIntegration;
      default:
        return messages.releaseError;
    }
  }

  function onClickReleaseConfirm() {
    setLoadingRelease(true);
    releaseTransactionJob({
      variables: {
        input: {
          organizationTransactionId,
        },
      },
    })
      .then(({ data }) => {
        onCancelRelease();
        const releaseTransaction = data!.releaseTransaction;
        if (!releaseTransaction) {
          throw new Error("Missing return value for mutation");
        }
        const asyncJobs = releaseTransaction.organizationTransaction.asyncJobs;
        let asyncJobId = null;
        for (let index = asyncJobs.length - 1; index >= 0; index--) {
          const asyncJob = asyncJobs[index];
          if (
            asyncJob.status === AsyncJobStatus.PENDING &&
            asyncJob.name === "ReleaseTransactionBatchJob"
          ) {
            asyncJobId = asyncJob.id;
            break;
          }
        }
        const transactionId = releaseTransaction.organizationTransaction.id;
        if (asyncJobId) {
          asyncJobsQueryObservable.refetch({
            organizationTransactionId: transactionId,
          });
          asyncJobsQueryObservable
            .map(filterAsyncJob(asyncJobId))
            .filter((asyncJob) => asyncJob !== null)
            .subscribe((asyncJob) => {
              checkJob(asyncJob);
              onCancelRelease();
              asyncJobsQueryObservable.stopPolling();
            });
          asyncJobsQueryObservable.startPolling(2000);
        }
      })
      .catch((error) => {
        onCancelRelease();
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          message: intl.formatMessage(releaseErrorToMessage(error)),
        });
        asyncJobsQueryObservable.stopPolling();
      });
  }

  const buttons: ComponentProps<typeof WorkflowModal>["buttons"] =
    retry?.tag === "success"
      ? [
          <Button
            key="exit"
            onClick={() => {
              setRetryMeetingModal(false);
            }}
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage id="e305177e-bbd2-42eb-8f8a-71aed834370a" defaultMessage="Exit" />
          </Button>,
        ]
      : [
          <Button
            key="cancel"
            disabled={retry?.tag === "loading"}
            onClick={() => {
              setRetryMeetingModal(false);
            }}
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage id="66335b94-f4e4-45ab-9361-abbb9fc88cb9" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="confirm"
            isLoading={retry?.tag === "loading"}
            disabled={retryReason.trim() === ""}
            onClick={() => {
              setRetry({ tag: "loading" });
              retryPostMeetingJob({
                variables: {
                  input: {
                    documentBundleId: props.documentBundleId,
                    retryReason,
                  },
                },
              })
                .then(() => {
                  setRetry({ tag: "success" });
                })
                .catch((e) => {
                  const message = e.graphQLErrors?.[0]?.message;
                  const retryErrMsg =
                    message === "not_found"
                      ? MEETING_NOT_FOUND_MESSAGE
                      : message === "unauthorized"
                        ? UNAUTHORIZED_USER_MESSAGE
                        : null;
                  setRetry({
                    tag: "error",
                    message: retryErrMsg || e.message || RETRY_FAILED_MESSAGE,
                  });
                });
            }}
            buttonColor="action"
            variant="primary"
          >
            <FormattedMessage id="cd342acc-fef6-48c7-a8a4-032b70ee5703" defaultMessage="Confirm" />
          </Button>,
        ];
  return (
    <>
      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow
          title={
            <FormattedMessage
              id="5b4f4b98-0f6b-47db-b483-a2fc4c5510e9"
              defaultMessage="Admin Tools"
            />
          }
        >
          <div className="NotarizationDetails--AdminTools">
            <ul className="NotarizationDetails--AdminTools--list">
              <li className="NotarizationDetails--AdminTools--list--item">
                <Link
                  automationId="retry-meeting-link"
                  onClick={() => {
                    setRetryMeetingModal(true);
                    setRetryReason("");
                    setRetry(null);
                  }}
                  underlined={false}
                >
                  <FormattedMessage
                    id="5a4b82fb-817f-4ddf-b432-18174b9bacbe"
                    defaultMessage="Retry Meeting Processing"
                  />
                </Link>
                <div className="NotarizationDetails--AdminTools--list--item--secondary">
                  <FormattedMessage
                    id="c59fcd8e-0f56-405b-8432-9a65b15e095d"
                    defaultMessage="This tool retries meeting processing"
                  />
                </div>
              </li>
            </ul>
          </div>
        </DeprecatedDetailGridRow>
        {showRetryMeetingModal && (
          <WorkflowModal
            title={
              <FormattedMessage
                id="2526019d-cbb4-4ef1-b9b7-1cd460e7bba2"
                defaultMessage="Confirm Retry"
              />
            }
            buttons={buttons}
            footerSeparator={false}
            large
          >
            {retry?.tag === "error" ? (
              <p className="NotarizationDetails--AdminTools--error">
                <FormattedMessage
                  id="984ad196-0b80-42f9-8dd0-5b105bdcd44e"
                  defaultMessage="Error: {errorMessage}"
                  values={{ errorMessage: retry.message }}
                />
              </p>
            ) : retry?.tag === "success" ? (
              <p>
                <FormattedMessage
                  id="71dbedf8-ad97-4d03-a988-f726e448df4c"
                  defaultMessage="You successfully scheduled retrying the meeting."
                />
              </p>
            ) : (
              <>
                <FormattedMessage
                  id="a88f6d6b-57d1-4089-b54d-14049565b88f"
                  defaultMessage="This feature should be used before the force complete option. It will re-run completing the meeting. This is the first step the on-call engineer would try in this case."
                />
                <div className="NotarizationDetails--AdminTools--input">
                  <label htmlFor={retryReasonId}>
                    <FormattedMessage
                      id="1cf2d736-6410-4d44-85e1-418516cf1eab"
                      defaultMessage="Why are you retrying this meeting's processing?"
                    />
                  </label>
                  <StyledTextInput
                    id={retryReasonId}
                    displayRequiredAsterisk
                    value={retryReason}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setRetryReason(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </WorkflowModal>
        )}
        <DeprecatedDetailGridRow>
          <div className="NotarizationDetails--AdminTools">
            {hasPermissionFor("forceCompleteTransactions") && (
              <ul className="NotarizationDetails--AdminTools--list">
                <li className="NotarizationDetails--AdminTools--list--item">
                  <Link
                    automationId="force-complete-link"
                    onClick={() => {
                      setForceCompleteModal(true);
                      setCompletionReason("");
                    }}
                  >
                    <FormattedMessage
                      id="9dc4a601-ea65-4b30-a5c8-c05ef90d8c77"
                      defaultMessage="Force Complete/Finalize Transaction"
                    />
                  </Link>
                  <div className="NotarizationDetails--AdminTools--list--item--secondary">
                    <FormattedMessage
                      id="3b57e97b-7175-4949-86a7-a1a2132aa7dd"
                      defaultMessage="Rejects all non-completed documents and marks transaction as complete"
                    />
                  </div>
                </li>
              </ul>
            )}
          </div>
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>
      {showForceCompleteModal && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="1601ab60-bf14-45a5-8e3e-b71fc88f8b6d"
              defaultMessage="Confirm Force Complete"
            />
          }
          buttons={[
            <Button
              key="cancel"
              automationId="cancel-force-complete"
              onClick={() => {
                setLoading(false);
                setForceCompleteModal(false);
              }}
              buttonColor="dark"
              variant="tertiary"
            >
              <FormattedMessage id="ce035113-aa6a-40ca-97d0-98c2426d084c" defaultMessage="Cancel" />
            </Button>,
            <Button
              key="confirm"
              automationId="confirm-force-complete"
              onClick={() => {
                setLoading(true);
                forceCompleteTransaction({
                  variables: {
                    input: {
                      organizationTransactionId: props.organizationTransactionId,
                      completionReason,
                    },
                  },
                })
                  .then(() => {
                    setForceCompleteModal(false);
                  })
                  .catch((e) => {
                    setError(e.graphQLErrors?.[0]?.message || e.message);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
              isLoading={isLoading}
              buttonColor="danger"
              variant="primary"
              disabled={completionReason.trim() === ""}
            >
              <FormattedMessage
                id="7bda6c45-7cb5-40af-b44d-f4ebe897fff6"
                defaultMessage="Confirm"
              />
            </Button>,
          ]}
          footerSeparator={false}
          large
        >
          {error && (
            <p className="NotarizationDetails--AdminTools--error">
              <FormattedMessage
                id="2fbd2c45-91e4-4097-869d-08dacf1ffbe7"
                defaultMessage="Error: {error}"
                values={{ error }}
              />
            </p>
          )}
          <FormattedMessage
            id="7c16b1c7-5209-4601-8129-3428f1ac7fd0"
            defaultMessage="This will reject all non-completed documents and prevent any further signings. This can take a few minutes, please refresh the page to see results. Please note:"
          />
          <ul className="NotarizationDetails--AdminTools--bullets">
            <li>
              <FormattedMessage
                id="261fe2be-ba21-4db4-93f7-78582dcc740c"
                defaultMessage="This feature should mainly be used on a multisigner transaction where Signer A completes their portion of the transaction, but signer B didn't complete it in time. However, the organization still wants to use Signer A's documents."
              />
            </li>
            <li>
              <FormattedMessage
                id="eb02ebf5-76d3-4b81-8b80-dd66fbd99694"
                defaultMessage="This will work for both Hybrid and Full RON transactions."
              />
            </li>
            <li>
              <FormattedMessage
                id="cfb85404-c94a-4c95-ab19-9f8dde75389f"
                defaultMessage="You cannot use this feature when a meeting is in progress for the transaction"
              />
            </li>
            <li>
              <FormattedMessage
                id="887e4cd1-e980-4a42-9606-8c2c5f2ee849"
                defaultMessage="This change will be logged for security and tracking purposes in the Activity Log"
              />
            </li>
            <li>
              <FormattedMessage
                id="3dc01bda-b94f-403e-850c-ac69b8f487a3"
                defaultMessage="This will finalize eNotes in their current state"
              />
            </li>
          </ul>
          <div className="NotarizationDetails--AdminTools--input">
            <label htmlFor={forceCompleteId}>
              <FormattedMessage
                id="3c25e18f-0088-4e2c-b88c-ea426ebb9752"
                defaultMessage="Why you are force completing this transaction"
              />
            </label>
            <StyledTextInput
              id={forceCompleteId}
              displayRequiredAsterisk
              value={completionReason}
              data-automation-id="force-complete-reason"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCompletionReason(e.target.value);
              }}
            />
          </div>
        </WorkflowModal>
      )}

      <DeprecatedDetailGridRow>
        <div className="NotarizationDetails--AdminTools">
          {hasPermissionFor("releaseTransactions") && (
            <ul className="NotarizationDetails--AdminTools--list">
              <li className="NotarizationDetails--AdminTools--list--item">
                <Link
                  aria-label={"Release-Link"}
                  automationId="show-release-modal"
                  onClick={() => {
                    setReleaseTransactionModal(true);
                  }}
                >
                  <FormattedMessage
                    id="9dc4a601-ea65-4b30-a5c8-c05ef90d8c77"
                    defaultMessage="Release Transaction"
                  />
                </Link>
                <div className="NotarizationDetails--AdminTools--list--item--secondary">
                  <FormattedMessage
                    id="3b57e97b-7175-4949-86a7-a1a2132aa7dd"
                    defaultMessage="Re-triggers release process for this transaction"
                  />
                </div>
              </li>
            </ul>
          )}
        </div>
      </DeprecatedDetailGridRow>

      {showReleaseTransactionModal && (
        <WorkflowModal
          title={
            <FormattedMessage
              id="9b2f305c-7826-418b-af1f-f1b7bc8d1370"
              defaultMessage="Confirm Release Transaction"
            />
          }
          buttons={[
            <Button
              aria-label={"Confirm-Cancel"}
              key="cancel"
              automationId="cancel-release-transaction"
              onClick={onCloseRelease}
              buttonColor="dark"
              variant="tertiary"
            >
              <FormattedMessage id="ce035113-aa6a-40ca-97d0-98c2426d084c" defaultMessage="Cancel" />
            </Button>,
            <Button
              aria-label={"Confirm-Release"}
              key="confirm"
              automationId="confirm-release-transaction"
              onClick={onClickReleaseConfirm}
              isLoading={isLoadingRelease}
              buttonColor="danger"
              variant="primary"
            >
              <FormattedMessage
                id="1b646da6-116f-447f-be02-656af64e88c0"
                defaultMessage="Confirm"
              />
            </Button>,
          ]}
        >
          <FormattedMessage
            id="fdcb76ff-afb0-4d1f-aa06-cfbe0c708d82"
            defaultMessage="This will re-trigger release process for a transaction! This can take a few minutes. Please note:"
          />
          <ul className="NotarizationDetails--AdminTools--bullets">
            <li>
              <FormattedMessage
                id="0555efed-489e-4a85-801d-ff58b9d8b59e"
                defaultMessage="If the transaction has not been completed. Please force complete first."
              />
            </li>
            <li>
              <FormattedMessage
                id="79d318cf-7472-46f0-8b16-0212308807a1"
                defaultMessage="This will not do anything if transaction is not a Docutech transaction."
              />
            </li>
          </ul>
        </WorkflowModal>
      )}
    </>
  );
}

export default AdminTools;
