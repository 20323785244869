import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Address from "common/user/address";
import { SensitiveValue } from "common/details/identity";

type Props = {
  address: ComponentProps<typeof Address>["address"];
  hideSensitiveData?: boolean;
};

function SignerAddress({ address, hideSensitiveData }: Props) {
  // HACK: the BE doesn't always send a null address object if it's blank, so we use this hack to test for presence
  if (!address?.line1) {
    return null;
  }
  const addressComponent = <Address address={address} />;
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage id="6352b4f7-aefa-4feb-a07d-db7ce4ca4a9e" defaultMessage="Address" />
        }
      >
        <div className="DetailGrid-data--subheading">
          {hideSensitiveData ? (
            <SensitiveValue value={addressComponent} obfuscatedValue={"****"} />
          ) : (
            addressComponent
          )}
        </div>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default SignerAddress;
