import { createContext, useContext, useEffect, useState, type ReactNode } from "react";
import { defineMessages, type IntlShape, useIntl } from "react-intl";
import { defer, merge, filter, switchMap } from "rxjs";

import { segmentTrack } from "util/segment";
import { fromSocketEvent } from "socket/util";
import type Channel from "socket/channel";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import { PhotoIdVerificationStatus } from "graphql_globals";
import { retryWhenWithCaptureException } from "util/rxjs";
import type { PhotoType } from "common/identity/document_viewer/engine";

import RetakeConfirmationModal from "./modal";
import type {
  RetakeManagerMeeting as Meeting,
  RetakeManagerMeeting_meetingParticipants_SignerParticipant as SignerParticipant,
} from "./meeting.fragment.graphql";

type RetakeCanceledSocketData = {
  signer_identity_id: string;
};
type PhotoUpdatedSocketData = {
  meeting_participant_id: string;
  photo_id_status: PhotoIdVerificationStatus;
};
type RetakeOptions = {
  isPrimaryPhoto?: boolean;
  skipConfirmation?: boolean;
  individualRetake: PhotoType | null; // null indicates a full retake
};
type RetakeResult = "success" | "failure";
type RetakeState =
  /* "empty" state -- no retake ongoing */
  | null
  /* confirming modal for participant with retake options */
  | { type: "confirming"; retakeOptions: RetakeOptions; participantId: string }
  /* retake for participant initiated, waiting for cancel or finish */
  | { type: "waiting"; participantId: string };
type Context = {
  inProgress: boolean;
  initiate: (participantId: string, retakeOptions: RetakeOptions) => void;
  cancel: (participantId: string) => void;
};
export type RetakeManager = {
  inProgress: boolean;
  initiate: (options?: Partial<RetakeOptions>) => void;
  cancel: () => void;
};

const RETAKE_LOG_PREFIX = "[Retake Manager]";
const MESSAGES = defineMessages({
  failure: {
    id: "659dae91-1982-4656-b855-e734e885c64a",
    defaultMessage: "Document capture failed, try again",
  },
  success: {
    id: "0c4a27dc-cfd4-41ec-a2a9-e242e0f2e2ac",
    defaultMessage: "Document capture successful",
  },
});
const NOOP = () => {};
const EMPTY_REFETCH = () => Promise.resolve();

const RetakeManagerContext = createContext<Context>(
  Object.freeze({
    inProgress: false,
    initiate: NOOP,
    cancel: NOOP,
  }),
);

function createNotificationForRetakeStatus(status: RetakeResult, intl: IntlShape) {
  switch (status) {
    case "success":
      return pushNotification({
        message: intl.formatMessage(MESSAGES.success),
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
      });
    case "failure":
      return pushNotification({
        message: intl.formatMessage(MESSAGES.failure),
        subtype: NOTIFICATION_SUBTYPES.WARNING,
      });
  }
}

function findSignerParticipant(meeting: Meeting, id: string) {
  return meeting.meetingParticipants.find(
    (p) => p.__typename === "SignerParticipant" && p.id === id,
  ) as SignerParticipant | undefined;
}

function useRetakeHandler(handlerOptions: {
  channel: Channel;
  enabled: boolean;
  refetch?: () => Promise<unknown>;
  currentRetakeParticipant?: SignerParticipant;
}) {
  const { refetch = EMPTY_REFETCH, channel, currentRetakeParticipant } = handlerOptions;
  const disabled = !handlerOptions.enabled;
  const intl = useIntl();
  const [retakeInProgress, setRetakeInProgress] = useState(false);

  useEffect(() => {
    if (disabled || !currentRetakeParticipant) {
      return;
    }
    const canceled$ = fromSocketEvent<RetakeCanceledSocketData>(channel, "retake_canceled").pipe(
      filter((event) => event.signer_identity_id === currentRetakeParticipant.signerIdentityId),
    );
    const updated$ = fromSocketEvent<PhotoUpdatedSocketData>(channel, "photo_updated").pipe(
      filter((event) => {
        return (
          event.meeting_participant_id === currentRetakeParticipant.id &&
          event.photo_id_status !== PhotoIdVerificationStatus.PENDING
        );
      }),
      switchMap((event) => {
        createNotificationForRetakeStatus(
          event.photo_id_status === PhotoIdVerificationStatus.SUCCESS ? "success" : "failure",
          intl,
        );
        return defer(() => refetch());
      }),
      retryWhenWithCaptureException(),
    );
    const sub = merge(canceled$, updated$).subscribe(() => setRetakeInProgress(false));
    return () => sub.unsubscribe();
  }, [channel, disabled, currentRetakeParticipant]);

  const initiateRetake = (
    options: RetakeOptions,
    signerParticipant: SignerParticipant | undefined,
  ) => {
    if (disabled || !signerParticipant) {
      return;
    }
    segmentTrack(`${RETAKE_LOG_PREFIX} Initiated retake`, { participantId: signerParticipant.id });
    setRetakeInProgress(true);
    channel.sendMessage("photo_request", {
      signer_identity_id: signerParticipant.signerIdentityId,
      meeting_participant_id: signerParticipant.id,
      requires_biometrics: signerParticipant.requiresBiometrics,
      is_primary_photo: options.isPrimaryPhoto,
      is_mobile_retake: true,
      individual_retake: options.individualRetake,
    });
  };

  const cancelRetake = () => {
    if (disabled || !currentRetakeParticipant) {
      return;
    }

    segmentTrack(`${RETAKE_LOG_PREFIX} Canceled retake`, {
      participantId: currentRetakeParticipant.id,
    });
    setRetakeInProgress(false);
    channel.sendMessage("cancel_retake", {
      signer_identity_id: currentRetakeParticipant.signerIdentityId,
    });
  };

  return {
    retakeInProgress,
    initiateRetake,
    cancelRetake,
  };
}

export function RetakeManagerProvider(props: {
  channel: Channel;
  meeting: Meeting;
  refetch?: () => Promise<unknown>;
  enabled: boolean;
  children: ReactNode;
}) {
  const { enabled, meeting } = props;
  const [retakeState, setRetakeState] = useState<RetakeState>(null);
  const currentRetakeParticipant = retakeState?.participantId
    ? findSignerParticipant(meeting, retakeState.participantId)
    : undefined;
  const { retakeInProgress, initiateRetake, cancelRetake } = useRetakeHandler({
    currentRetakeParticipant,
    channel: props.channel,
    refetch: props.refetch,
    enabled,
  });

  const handleConfirmRetakeModal = (retakeOptions: RetakeOptions) => {
    if (!currentRetakeParticipant) {
      throw new Error("Unexpectedly missing retake participant");
    }
    initiateRetake(retakeOptions, currentRetakeParticipant);
    setRetakeState({ type: "waiting", participantId: currentRetakeParticipant.id });
  };

  const handleCloseRetakeModal = () => {
    segmentTrack(`${RETAKE_LOG_PREFIX} Confirmation modal closed`);
    setRetakeState(null);
  };

  const handleInitiateRetake = (participantId: string, retakeOptions: RetakeOptions) => {
    if (retakeInProgress) {
      segmentTrack(`${RETAKE_LOG_PREFIX} Attempted opening confirmation modal in invalid state`, {
        retakeInProgress,
        participantId,
      });
      return;
    }

    if (retakeOptions.skipConfirmation) {
      segmentTrack(`${RETAKE_LOG_PREFIX} Retake initiated`, { participantId });
      initiateRetake(retakeOptions, findSignerParticipant(meeting, participantId));
      return setRetakeState({ type: "waiting", participantId });
    }

    segmentTrack(`${RETAKE_LOG_PREFIX} Confirmation modal opened`, { participantId });
    setRetakeState({ type: "confirming", retakeOptions, participantId });
  };

  const handleCancelRetake = (participantId: string) => {
    if (
      retakeState?.type !== "waiting" ||
      retakeState.participantId !== participantId ||
      !retakeInProgress
    ) {
      segmentTrack(`${RETAKE_LOG_PREFIX} Attempted cancelling retake in invalid state`, {
        currentActiveParticipantId: retakeState?.participantId,
        participantId,
        retakeInProgress,
      });
      return;
    }
    cancelRetake();
  };

  const value = enabled
    ? { inProgress: retakeInProgress, initiate: handleInitiateRetake, cancel: handleCancelRetake }
    : { inProgress: false, initiate: NOOP, cancel: NOOP };

  return (
    <RetakeManagerContext.Provider value={value}>
      {retakeState?.type === "confirming" && (
        <RetakeConfirmationModal
          photoType={retakeState.retakeOptions.individualRetake}
          onContinue={() => handleConfirmRetakeModal(retakeState.retakeOptions)}
          onClose={handleCloseRetakeModal}
        />
      )}
      {props.children}
    </RetakeManagerContext.Provider>
  );
}

export function useRetakeManagerContext(
  activeParticipant: { id: string } | null | undefined,
  retakeOptions: Partial<RetakeOptions> = {},
): RetakeManager {
  const cx = useContext(RetakeManagerContext);

  if (!activeParticipant) {
    return { inProgress: false, initiate: NOOP, cancel: NOOP };
  }

  return {
    inProgress: cx.inProgress,
    initiate(options?: Partial<RetakeOptions>) {
      const combinedOptions = {
        individualRetake: null, // default this to null (full retake) when it isn't specified
        ...retakeOptions,
        ...options,
      };
      return cx.initiate(activeParticipant.id, combinedOptions);
    },
    cancel: () => cx.cancel(activeParticipant.id),
  };
}
