import { memo } from "react";

import { DocumentBundleMembershipRole } from "graphql_globals";
import { PDFDocumentContainer } from "common/pdf/pspdfkit/document";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import { ToolPreview } from "common/pdf/tool_preview";
import { useDesignationColor } from "common/meeting/notary/document/pdf/designation";
import { PDFWrapper } from "common/pdf/pspdfkit";
import { usePlaceholderSigners } from "common/pdf/interaction";
import {
  useAnnotationUpdate,
  useAnnotationDelete,
  usePagePress,
  useDesignationUpdate,
  useDesignationAddToGroup,
  useDesignationDelete,
  useDesignationGroupDelete,
  useCheckmarkAnnotationTool,
  useTextAnnotationTool,
  useWhiteboxAnnotationTool,
  useDesignationTool,
  useRadioDesignationTool,
  useCompoundDesignationTool,
  useUpdateDesignationOptionality,
  useDesignationAddToGroupTool,
} from "common/pdf/interaction/prep";

import type {
  OrganizationTemplatesPrepare_viewer_user as User,
  OrganizationTemplatesPrepare_template_OrganizationDocumentTemplate_documentBundle_documents_edges_node as Document,
  OrganizationTemplatesPrepare_template_OrganizationDocumentTemplate_documentBundle_documents_edges_node_designations_edges_node as Designation,
} from "../query.graphql";
import Styles from "./index.module.scss";

type Props = {
  document: Document;
  currentUser: User;
  selectedAnnotationOrDesignationId: string | undefined;
  setSelectedAnnotationOrDesignationId: (annotationOrDesignationId: string | null) => void;
  selectedDesignationGroupId: string | undefined;
  conditionalEditModeDetails?: {
    toggleConditionalEditMode: ((param: boolean, shouldShowToast?: boolean) => void) | undefined;
    conditionalEditMode: boolean;
  };
};

function TemplatePrepPdf({
  document,
  currentUser,
  setSelectedAnnotationOrDesignationId,
  selectedAnnotationOrDesignationId,
  selectedDesignationGroupId,
  conditionalEditModeDetails,
}: Props) {
  const { getDesignationColor } = useDesignationColor();
  const conditionalEditMode = Boolean(conditionalEditModeDetails?.conditionalEditMode);
  const toggleConditionalEditMode = conditionalEditModeDetails?.toggleConditionalEditMode;
  const handleAnnotationUpdate = useAnnotationUpdate(currentUser.id);
  const handleAnnotationDelete = useAnnotationDelete(currentUser.id, document.id);
  const handleDesignationUpdate = useDesignationUpdate();
  const designations = document.designations.edges.map((edge) => edge.node);
  const handleDesignationAddToGroup = useDesignationAddToGroup(document.id, designations);
  function processDesignationAddToGroup(sourceDesignation: Designation) {
    handleDesignationAddToGroup(sourceDesignation);
    toggleConditionalEditMode?.(false);
  }

  const conditionalRules = document.conditionalRules as Record<string, [string]>;
  function canHandleSetConditionalEditMode(designation: Designation) {
    const dependentDesignations = Object.values(conditionalRules).flat();
    const isDependent = dependentDesignations.some((dependent) => dependent === designation.id);
    const isNotary = designation.signerRole.index === "notary";
    const isOptional = designation.optional;

    return !isNotary && !isDependent && !conditionalEditMode && isOptional;
  }

  const handleDesignationDelete = useDesignationDelete(
    document.id,
    document.designations.totalCount,
  );

  const handleDesignationGroupDelete = useDesignationGroupDelete(document.id, designations);

  const countDesignationsInGroup = (designation: Designation, designations: Designation[]) =>
    designations.reduce((acc: number, d: Designation) => {
      if (d.designationGroupId === designation.designationGroupId) {
        acc++;
      }
      return acc;
    }, 0);

  function processDesignationDelete(designation: Designation) {
    // Special deletion case for Radio Buttons:
    // Cannot have less than 2 Radio Buttons in a group
    const numberOfDesignationInGroup = countDesignationsInGroup(designation, designations);
    if (designation.type === "RADIO_CHECKMARK" && numberOfDesignationInGroup <= 2) {
      const designationGroup = document.designationGroups.find(
        (dg) => dg.id === designation.designationGroupId,
      )!;
      handleDesignationGroupDelete(designationGroup);
    } else {
      handleDesignationDelete(designation);
    }
    toggleConditionalEditMode?.(false, false);
  }

  const setDesignationOptional = useUpdateDesignationOptionality();
  function processSetDesignationOptional(designation: Designation, optional: boolean) {
    setDesignationOptional(designation, optional);
    toggleConditionalEditMode?.(false, false);
  }

  const handlePagePress = usePagePress(
    useCheckmarkAnnotationTool(currentUser.id, document.id),
    useTextAnnotationTool(currentUser.id, document.id),
    useWhiteboxAnnotationTool(currentUser.id, document.id),
    useDesignationTool(currentUser.id, document.id),
    useRadioDesignationTool(currentUser.id, document.id),
    useCompoundDesignationTool(currentUser.id, document.id),
    useDesignationAddToGroupTool(currentUser.id, document.id),
  );
  const placeholderSigners = usePlaceholderSigners();

  return (
    <div className={Styles.documentBundle}>
      <PDFDocumentContainer
        document={{
          annotations: document.annotations,
          annotationDesignations: document.designations,
          id: document.id,
          isEnote: document.isEnote,
          s3OriginalAsset: document.src,
          assetVersion: document.assetVersion,
        }}
        onPagePress={handlePagePress}
        onSelectedAnnotationOrDesignationChange={setSelectedAnnotationOrDesignationId}
      />
      {document.annotations.edges.map(({ node }) => {
        return (
          <Annotation
            key={node.id}
            annotation={node}
            onUpdate={handleAnnotationUpdate}
            onDelete={handleAnnotationDelete}
          />
        );
      })}
      {document.designations.edges.map(({ node }) => {
        const { signerRole } = node;
        const isNotary = signerRole.index === "notary";
        const participant =
          signerRole.role === DocumentBundleMembershipRole.WITNESS
            ? { signerRole }
            : [...placeholderSigners].find((cs) => cs.signerRole.index === signerRole.index);
        const isPrimaryConditional =
          Object.keys(conditionalRules).includes(node.id) &&
          (selectedAnnotationOrDesignationId === node.id ||
            conditionalRules[node.id].includes(String(selectedAnnotationOrDesignationId)));
        const dependentDesignations = [
          conditionalRules[String(selectedAnnotationOrDesignationId)],
        ].flat();
        const siblings = new Set();
        for (const key in conditionalRules) {
          if (conditionalRules[key].includes(String(selectedAnnotationOrDesignationId))) {
            conditionalRules[key].forEach(siblings.add, siblings);
          }
        }
        const dashedBorder = dependentDesignations.includes(node.id) || siblings.has(node.id);

        return (
          <AnnotationDesignation
            key={node.id}
            designation={node}
            color={getDesignationColor(node, participant)}
            onUpdate={handleDesignationUpdate}
            onDelete={processDesignationDelete}
            onAddToGroup={node.designationGroupId ? processDesignationAddToGroup : undefined}
            onAddConditional={
              toggleConditionalEditMode && canHandleSetConditionalEditMode(node)
                ? () => toggleConditionalEditMode(true)
                : undefined
            }
            onSetOptional={!isNotary ? () => processSetDesignationOptional(node, true) : undefined}
            isHighlighted={
              (Boolean(selectedDesignationGroupId) &&
                selectedDesignationGroupId === node.designationGroupId) ||
              dashedBorder
            }
            dashedBorder={dashedBorder}
            isPrimaryConditional={isPrimaryConditional}
            conditionalEditMode={conditionalEditMode}
          />
        );
      })}

      <ToolPreview />
    </div>
  );
}

function WithPdfWrapper(props: Props) {
  return (
    <PDFWrapper>
      <TemplatePrepPdf {...props} />
    </PDFWrapper>
  );
}

const Memoized = memo(WithPdfWrapper);
export { Memoized as TemplatePrepPdf };
