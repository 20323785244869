import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import Address from "common/user/address";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridSection } from "common/details/grid/section";

type Props = {
  witness: {
    userId: string | null;
    address: ComponentProps<typeof Address>["address"];
  };
};

function WitnessInfo({ witness }: Props) {
  return (
    <div className="NotarizationDetails-notary_detail">
      <DeprecatedDetailGrid>
        <DeprecatedDetailGridSection>
          <DeprecatedDetailGridRow
            title={
              <FormattedMessage
                id="f9bf788d-84fa-4f3a-a5de-12f605c568a1"
                defaultMessage="Address"
              />
            }
          >
            <Address address={witness.address} />
          </DeprecatedDetailGridRow>
          <DeprecatedDetailGridRow
            title={
              <FormattedMessage
                id="f47f4d1c-428e-47e4-a2d4-e4a153db2393"
                defaultMessage="User Id"
              />
            }
          >
            {witness.userId}
          </DeprecatedDetailGridRow>
        </DeprecatedDetailGridSection>
      </DeprecatedDetailGrid>
    </div>
  );
}

export default WitnessInfo;
