import "./listing.scss";

import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import type { NotaryMeeting_meeting_Meeting_documentBundle_instructions as Instructions } from "common/meeting/notary/meeting_query.graphql";
import { CheckboxWithLabel } from "common/form/inputs/checkbox";

type InstructionProps = {
  isChecked: boolean;
  toggleInstructionConfirmation: (id: string) => void;
  instruction: Instructions;
};

function Instruction({ isChecked, toggleInstructionConfirmation, instruction }: InstructionProps) {
  const { id, creator, text } = instruction;

  const cx = classnames("Meeting--InstructionsListing--Instruction", {
    "Meeting--InstructionsListing--Instruction__read": isChecked,
  });

  return (
    <div className={cx}>
      <header>{creator}</header>
      <div
        className="Meeting--InstructionsListing--Instruction--Text"
        data-automation-id="note-text"
      >
        {text}
      </div>
      <div className="Meeting--InstructionsListing--Instruction--Checkbox">
        <CheckboxWithLabel
          automationId="mark-as-read-checkbox"
          label={
            <FormattedMessage
              id="e90c0227-7f8a-4050-b897-5c5da38fb9f7"
              defaultMessage="Mark as Read"
            />
          }
          checked={isChecked}
          onChange={() => toggleInstructionConfirmation(id)}
        />
      </div>
    </div>
  );
}

type InstructionListingProps = {
  instructions: Instructions[];
  confirmedInstructions: Readonly<Record<string, boolean | undefined>>;
  toggleInstructionConfirmation: (id: string) => void;
};

function InstructionsListing({
  instructions,
  confirmedInstructions,
  toggleInstructionConfirmation,
}: InstructionListingProps) {
  return (
    <div className="Meeting--InstructionsListing">
      <header data-automation-id="notes-header">
        <FormattedMessage id="9139d033-f9e0-491b-8b1d-92a9db9f1a5d" defaultMessage="Notes">
          {(txt) => <h3>{txt}</h3>}
        </FormattedMessage>
      </header>
      <div className="Meeting--InstructionsListing--Instructions">
        {instructions.map((instruction) => (
          <Instruction
            key={instruction.id}
            isChecked={Boolean(confirmedInstructions[instruction.id])}
            instruction={instruction}
            toggleInstructionConfirmation={toggleInstructionConfirmation}
          />
        ))}
      </div>
    </div>
  );
}

export default InstructionsListing;
