import { PureComponent } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

import { captureException } from "util/exception";
import { CALL_STATUSES } from "constants/dial_out";
import { Mutation } from "util/graphql/mutation";

import RequestMeetingDialOutMutation from "./request_meeting_dial_out_mutation.graphql";
import MeetingParticipantDialOutCallFragment from "./meeting_participant_dial_out_call_fragment.graphql";

class RequestAction extends PureComponent {
  requestMeetingDialOut = (phoneNumber) => {
    const { requestMeetingDialOutMutateFn, meetingParticipant } = this.props;

    const input = {
      meetingParticipantId: meetingParticipant.id,
      phoneNumber,
    };
    const dialOutCallId = `SignerCaller-${v4()}`;

    return requestMeetingDialOutMutateFn({
      variables: { input },
      optimisticResponse: {
        requestMeetingDialOut: {
          dialOutCall: {
            id: dialOutCallId,
            callStatus: CALL_STATUSES.QUEUED,
            audioConferenceIdentity: dialOutCallId,
            __typename: "DialOutCall",
          },
          __typename: "RequestMeetingDialOutPayload",
        },
      },
      update(cacheProxy, { data: { requestMeetingDialOut } }) {
        const id = cacheProxy.identify(meetingParticipant);
        const updatedMeetingParticipant = cacheProxy.readFragment({
          id,
          fragment: MeetingParticipantDialOutCallFragment,
          fragmentName: "MeetingParticipantDialOutCall",
        });
        if (!Object.keys(updatedMeetingParticipant || {}).length) {
          captureException(
            new Error("RequestMeetingDialOut mutation failed find meetingParticipant in cache"),
            {
              meetingParticipant,
            },
          );
          return;
        }
        cacheProxy.writeFragment({
          id,
          fragment: MeetingParticipantDialOutCallFragment,
          fragmentName: "MeetingParticipantDialOutCall",
          data: {
            ...updatedMeetingParticipant,
            activeCall: requestMeetingDialOut.dialOutCall,
          },
        });
      },
    });
  };

  render() {
    return this.props.children(this.requestMeetingDialOut);
  }
}

RequestAction.propTypes = {
  meetingParticipant: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  requestMeetingDialOutMutateFn: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

function WrappedRequestAction({ meetingParticipant, children }) {
  return (
    <Mutation mutation={RequestMeetingDialOutMutation}>
      {(requestMeetingDialOutMutateFn) => (
        <RequestAction
          requestMeetingDialOutMutateFn={requestMeetingDialOutMutateFn}
          meetingParticipant={meetingParticipant}
        >
          {children}
        </RequestAction>
      )}
    </Mutation>
  );
}

export default WrappedRequestAction;
