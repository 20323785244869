import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Button from "common/core/button";
import { SelectInput } from "common/form/inputs/select";

import Styles from "./notary.module.scss";
import type { ViewerNotaryUsStates_notaryUsStates as UsState } from "./notary_us_states_fragment.graphql";

type Props = {
  notaryUsStates: (UsState | null)[];
  loading: boolean;
  onSubmit: ({ isNotary, notaryCommissionStateId }: NotarySubmitProps) => void;
};

export type NotarySubmitProps = {
  isNotary: boolean;
  notaryCommissionStateId: string | undefined;
};

function OnboardingSlideAreYouNotary({ notaryUsStates, loading, onSubmit }: Props) {
  const [isNotary, setIsNotary] = useState<boolean | undefined>(undefined);
  const [notaryCommissionStateId, setNotaryCommissionStateId] = useState<undefined | string>(
    notaryUsStates[0]?.value,
  );

  return (
    <>
      <div className={Styles.onboardingNotarySlide}>
        <div className={Styles.title}>
          <FormattedMessage
            id="f96ff96a-3229-4d35-a57e-3e6a81bfd9ff"
            defaultMessage="Tell Us About Yourself"
          />
        </div>
        <div className={Styles.subtitle}>
          <FormattedMessage
            id="55bb1db6-98cc-469f-8025-a83f6c29b299"
            defaultMessage="Will you perform notarizations on behalf of your company?"
          />
        </div>
        <div className={Styles.note}>
          <FormattedMessage
            id="1b29eb22-248f-4d5f-ba3c-17ca5b7e8ef0"
            defaultMessage="If yes, you'll need to complete a set-up process to show that you are RON certified"
          />
        </div>
        <div className={Styles.options}>
          <button
            type="button"
            className={classnames(Styles.bigButton, { [Styles.selected]: isNotary === false })}
            onClick={() => {
              setIsNotary(false);
              setNotaryCommissionStateId(undefined);
            }}
            data-automation-id={"onboarding-slideshow-notary-no"}
          >
            <FormattedMessage
              id="2b4e60ea-f787-4d70-843e-b0b3266ca294"
              defaultMessage="No, I am not a notary"
            />
          </button>
          <button
            type="button"
            className={classnames(Styles.bigButton, { [Styles.selected]: isNotary })}
            onClick={() => setIsNotary(true)}
          >
            <FormattedMessage
              id="955eef2c-3b3a-4cb9-a868-dc88034f8a1e"
              defaultMessage="Yes, I am a notary"
            />
          </button>
        </div>
        {isNotary && (
          <div className={Styles.commission}>
            <div className={Styles.stateSubtitle}>
              <FormattedMessage
                id="099020dc-5d8d-40d6-b0f9-c62ea11b7a1c"
                defaultMessage="What state are you commissioned for?"
              />
            </div>
            <SelectInput
              className={Styles.states}
              value={notaryCommissionStateId}
              items={notaryUsStates}
              data-automation-id="notary-state-field"
              onChange={setNotaryCommissionStateId}
              placeholder={
                <FormattedMessage
                  id="ce082582-2963-43ce-acee-7c04589bbbcd"
                  defaultMessage="Select State"
                />
              }
            />
          </div>
        )}
      </div>
      <Button
        isLoading={loading}
        className={Styles.saveAndContinue}
        buttonColor="action"
        variant="primary"
        disabled={isNotary === undefined}
        onClick={() => {
          onSubmit({ isNotary: !!isNotary, notaryCommissionStateId });
        }}
        automationId="onboarding-slideshow-notary-save-continue"
      >
        <FormattedMessage
          id="646c9070-2232-4d41-8330-29815b3ce76e"
          defaultMessage="Save and Continue"
        />
      </Button>
    </>
  );
}

export default memo(OnboardingSlideAreYouNotary);
