import { FormattedMessage } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";

import Styles from "./index.module.scss";

export function EmptyState(props: { className?: string }) {
  return (
    <div className={props.className || Styles.emptyState}>
      <Heading textStyle="headingFour" level="h3" className={Styles.emptyStateHeading}>
        <FormattedMessage
          id="2b6dcb44-c2e6-43af-92ce-9e51ecbb7d1d"
          defaultMessage="Check back later..."
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="242482a0-e746-4fe9-920d-d9dfdb710b15"
          defaultMessage="Identity information appears here after completion of the verification process."
        />
      </Paragraph>
    </div>
  );
}
