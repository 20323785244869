import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import CustomerId from "common/core/customer_id";

type Props = {
  signerIdentity: ComponentProps<typeof CustomerId>["signerIdentity"];
};

function PhotoId({ signerIdentity }: Props) {
  return (
    <div>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage
            id="2d573e27-297c-49ae-88d9-1860027d0f90"
            defaultMessage="Signer Photo ID"
          />
        }
        className="NotarizationDetails-user-license-row"
      >
        <div className="NotarizationDetails-user-license">
          <CustomerId signerIdentity={signerIdentity} />
        </div>
      </DeprecatedDetailGridRow>
    </div>
  );
}

export default PhotoId;
