import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";

import { useQuery } from "util/graphql";
import { FormattedDate } from "common/core/format/date";
import Modal from "common/modal";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import LoadingIndicator from "common/core/loading_indicator";
import UserFullName from "common/user/user_full_name";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { MailtoLink } from "common/core/mailto_link";
import { RoleLabel, statusLabel } from "util/organization";
import { newPathWithPreservedSearchParams } from "util/location";
import { TRANSACTION_PATH } from "util/routes";
import { usePermissions } from "common/core/current_user_role";

import EmployeeOrganizationMembershipQuery, {
  type EmployeeOrganizationMembership_organizationMembership_OrganizationMembership as OrganizationMembership,
} from "./employee_details_query.graphql";
import Styles from "./employee_details.module.scss";

function EmployeeAccountDetails(props: { organizationMembership: OrganizationMembership }) {
  const { organizationMembership } = props;
  const { activationState, email } = organizationMembership;
  return (
    <DeprecatedDetailGrid>
      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow
          title={
            <FormattedMessage
              id="1f17efbd-020a-41d9-9d37-ec743598fa0c"
              defaultMessage="User Details"
            />
          }
        >
          <div className="DetailGrid-data--heading">
            <UserFullName user={organizationMembership} fallback={email} />
          </div>
          <div className="DetailGrid-data--subheading">
            <FormattedMessage
              id="d70d75ec-28fd-400b-89a2-6e0343787506"
              defaultMessage="Joined {joinedDate}"
              values={{ joinedDate: <FormattedDate value={organizationMembership.joinedDate} /> }}
            />
          </div>
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>

      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow
          title={
            <FormattedMessage
              id="2abc92ad-6cda-44cb-9166-227f0b4553c9"
              defaultMessage="Email Address"
            />
          }
        >
          <MailtoLink emailAddress={email} />
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>

      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow
          title={
            <FormattedMessage id="c03bd4ca-9d55-49fb-81da-a65e6f573dda" defaultMessage="Role" />
          }
        >
          <RoleLabel role={organizationMembership.role} />
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>

      <DeprecatedDetailGridSection>
        <DeprecatedDetailGridRow
          title={
            <FormattedMessage id="e0f3e8ab-4bc4-4fb4-bade-0d0fdf8ab51b" defaultMessage="Status" />
          }
        >
          <span className={activationState.toLowerCase()}>{statusLabel(activationState)}</span>
        </DeprecatedDetailGridRow>
      </DeprecatedDetailGridSection>
    </DeprecatedDetailGrid>
  );
}

function BusinessEmployeeAccount() {
  const { hasPermissionFor } = usePermissions();
  const shouldShowTransactions = hasPermissionFor("accessSiloedTransactions");
  const { orgMembershipId } = useParams();
  const { data, loading } = useQuery(EmployeeOrganizationMembershipQuery, {
    variables: { orgMembershipId: orgMembershipId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const organizationMembership = data?.organizationMembership;

  if (organizationMembership?.__typename !== "OrganizationMembership") {
    throw new Error(`Expected OrganizationMembership, got ${organizationMembership?.__typename}`);
  }

  return (
    <Modal
      title={
        <FormattedMessage
          id="0a1d0cfe-bd23-4cda-b5c1-d6e490109d8e"
          defaultMessage="Team Member Details"
        />
      }
      closeRoute={newPathWithPreservedSearchParams(TRANSACTION_PATH)}
    >
      <EmployeeAccountDetails organizationMembership={organizationMembership} />

      {shouldShowTransactions && (
        <div className={Styles.detailsLink}>
          <Link
            to={`/employee-transactions/${organizationMembership.organizationId}/${organizationMembership.userId}`}
          >
            <FormattedMessage
              id="8fb3a15e-8e0f-409c-b9b9-1f26536c02bf"
              defaultMessage="See Transactions"
            />
          </Link>
        </div>
      )}
    </Modal>
  );
}

export default BusinessEmployeeAccount;
