import { useEffect, useRef } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { fromEvent, debounceTime, merge } from "rxjs";

import { SignatureMethod } from "graphql_globals";
import SROnly from "common/core/screen_reader";
import { useForm } from "common/core/form";
import { OptionBar, RadioGroup, RadioInput } from "common/core/form/option";
import { TextInput } from "common/core/form/text";
import ActionButton from "common/core/action_button";
import { cropCanvas } from "util/canvas_text";
import FONTS, { FONT_SCALE } from "util/signature_fonts";
import { userInitials, userFullName } from "util/user";
import { useDebouncedState } from "util/timeout";
import { b, useId } from "util/html";
import { drawSignatures } from "util/signature_options";

import type { AssetData } from ".";
import Styles from "./type.module.scss";
import ErrorAlert from "./error_alert";

const MESSAGES = defineMessages({
  placeholder: {
    id: "bfb4f50e-d851-438f-9dcc-187a9ee4b045",
    defaultMessage: "Your {isSignature, select, true {name} other {initials}}",
  },
});

type Props = {
  currentUser: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
  };
  assetType: "signature" | "initials";
  setAssetData: (data: AssetData) => void;
  showError: boolean;
  lockRecipientName: boolean;
};
type FontName = (typeof FONTS)[number]["font"];
type FormValues = {
  assetInput: string;
  font: FontName;
};

export default function Type(props: Props) {
  const { currentUser, assetType, setAssetData, showError, lockRecipientName } = props;
  const isSignature = assetType === "signature";
  const text = isSignature ? userFullName(currentUser) : userInitials(currentUser);
  const signatureRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const intl = useIntl();
  const instructionsId = useId();
  const errorId = useId();
  const { watch, register, setValue } = useForm<FormValues>({
    defaultValues: {
      assetInput: text,
    },
  });
  const assetInput = watch("assetInput");
  const selectedFont = watch("font");
  const debouncedInput = useDebouncedState(assetInput, 150);

  const clearData = () => {
    setAssetData(null);
    setValue("font", "");
  };

  useEffect(() => {
    if (debouncedInput) {
      drawSignatures(FONTS, signatureRefs, debouncedInput);
    }
  }, [FONTS, signatureRefs, debouncedInput]);

  const handleClearInput = () => {
    clearData();
    setValue("assetInput", "");
  };

  useEffect(() => {
    if (!selectedFont || !assetInput) {
      clearData();
      return;
    }
    const index = FONTS.findIndex(({ font }) => font === selectedFont);
    const canvas = signatureRefs.current[index];
    if (!canvas) {
      return;
    }

    const textCanvas = cropCanvas(canvas);
    setAssetData({
      image: textCanvas.toDataURL("image/png"),
      method: SignatureMethod.TYPED,
      font: selectedFont,
    });
  }, [assetInput, selectedFont]);

  useEffect(() => {
    const handleResize = () => {
      drawSignatures(FONTS, signatureRefs, debouncedInput);
    };

    const mql = window.matchMedia("(orientation: portrait)");

    const event = merge(fromEvent(window, "resize"), fromEvent(mql, "change")).pipe(
      debounceTime(100),
    );

    const subscription = event.subscribe(handleResize);

    return () => subscription.unsubscribe();
  }, [FONTS, signatureRefs, debouncedInput]);

  return (
    <>
      <div data-automation-id="type-asset-form">
        {!lockRecipientName && (
          <p className={Styles.instructions} id={instructionsId}>
            {isSignature ? (
              <FormattedMessage
                id="04ef1443-c6e5-437d-89bf-832d8c9038b1"
                defaultMessage="Type your name <b>as it is written below the signature line</b>"
                values={{ b }}
              />
            ) : (
              <FormattedMessage
                id="bcf17b11-18de-4e0f-b055-94e08d5c8736"
                defaultMessage="Type your initials"
              />
            )}
          </p>
        )}
        {/* We use a no-op here since form submission isn't required and we listen for formValue changes */}
        <div className={lockRecipientName ? Styles.inputLocked : Styles.inputContainer}>
          {lockRecipientName ? (
            assetInput
          ) : (
            <>
              <TextInput
                aria-invalid={false}
                aria-describedby={instructionsId}
                placeholder={intl.formatMessage(MESSAGES.placeholder, {
                  isSignature,
                })}
                {...register("assetInput")}
              />
              {assetInput && (
                <ActionButton className={Styles.clearButton} onClick={handleClearInput}>
                  <FormattedMessage
                    id="a28ab8b5-66f7-4afb-8bbd-648791be1cfa"
                    defaultMessage="Clear"
                  />
                </ActionButton>
              )}
            </>
          )}
        </div>
        {!lockRecipientName && !debouncedInput ? (
          <div className={Styles.emptyState}>
            <FormattedMessage
              id="6aa4a357-0608-4755-856f-6973eece664a"
              defaultMessage="Type your {isSignature, select, true {full name} other {initials}} above to choose a font"
              values={{ isSignature }}
            />
          </div>
        ) : (
          <RadioGroup
            label={
              <SROnly>
                <FormattedMessage
                  id="caa64c58-9a1d-4047-9cd4-51c946c545ad"
                  defaultMessage="Font options"
                />
              </SROnly>
            }
            aria-describedby={showError ? errorId : ""}
          >
            {FONTS.map(({ font }, index) => (
              <OptionBar
                key={font}
                className={Styles.signatureOption}
                label={
                  <>
                    <SROnly>{font}</SROnly>
                    <canvas
                      style={{
                        width: `${100 * FONT_SCALE}%`,
                        height: `${100 * FONT_SCALE}%`,
                        transform: `scale(${1 / FONT_SCALE})`,
                      }}
                      ref={(el) => (signatureRefs.current[index] = el)}
                    />
                  </>
                }
                input={
                  <RadioInput
                    data-automation-id={font}
                    value={font}
                    {...register("font")}
                    {...(((selectedFont && selectedFont !== font) ||
                      (!selectedFont && FONTS[0].font !== font)) && { tabIndex: -1 })}
                  />
                }
              />
            ))}
          </RadioGroup>
        )}
      </div>
      <ErrorAlert id={errorId}>
        {showError && (
          <FormattedMessage
            id="88c6f074-b18c-4b66-be3f-b0b02a236acf"
            defaultMessage="Please, select a font for your {isSignature, select, true {signature} other {initials}}"
            values={{
              isSignature,
            }}
          />
        )}
      </ErrorAlert>
    </>
  );
}
