import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Container, Row } from "common/core/responsive";
import { Heading, Substyle } from "common/core/typography";
import { format } from "common/core/format/date";
import { PhoneLineTypes } from "graphql_globals";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import { Badge } from "common/core/badge";

import { Col } from ".";
import type { IdentityDetails_documentBundle_DocumentBundle_organizationTransaction_customerSigners as TransactionCustomer } from "./index.query.graphql";
import Styles from "./index.module.scss";

const LineTypeLabels: Record<PhoneLineTypes, ReactNode> = Object.freeze({
  [PhoneLineTypes.FIXEDVOIP]: (
    <FormattedMessage id="bb3cdd99-f0b6-48d9-ac6c-7dcb3cffe0bb" defaultMessage="Fixed VOIP" />
  ),
  [PhoneLineTypes.MOBILE]: (
    <FormattedMessage id="e0f27210-4e45-4444-906e-66ec85ce0182" defaultMessage="Mobile" />
  ),
  [PhoneLineTypes.UAN]: (
    <FormattedMessage id="3c09d157-ca4c-4a5f-8bce-4c3f31328fd9" defaultMessage="UAN" />
  ),
  [PhoneLineTypes.LANDLINE]: (
    <FormattedMessage id="f310ced3-7ee1-40a0-8517-8a74d82f0a37" defaultMessage="Landline" />
  ),
  [PhoneLineTypes.SHAREDCOST]: (
    <FormattedMessage id="44027395-4df5-4e45-8433-6106407ce0b2" defaultMessage="Shared cost" />
  ),
  [PhoneLineTypes.PERSONAL]: (
    <FormattedMessage id="0ad9febc-f472-43a9-8070-82eb9b930224" defaultMessage="Personal" />
  ),
  [PhoneLineTypes.PREMIUM]: (
    <FormattedMessage id="b923635e-5984-4e52-8f1a-fcce4a7b6cf2" defaultMessage="Premium" />
  ),
  [PhoneLineTypes.NONFIXEDVOIP]: (
    <FormattedMessage id="43414eec-a990-403f-8ede-0930a088626d" defaultMessage="Non-fixed VOIP" />
  ),
  [PhoneLineTypes.UNKNOWN]: (
    <FormattedMessage id="8f692510-5c1c-42c3-a2ba-df9644c139fc" defaultMessage="Unknown" />
  ),
  [PhoneLineTypes.PAGER]: (
    <FormattedMessage id="d3762413-3527-44c0-960a-85004dc49add" defaultMessage="Pager" />
  ),
  [PhoneLineTypes.TOLLFREE]: (
    <FormattedMessage id="6a6c1e4f-ee0c-4777-9096-3253279ca652" defaultMessage="Toll free" />
  ),
  [PhoneLineTypes.VOICE_MAIL]: (
    <FormattedMessage id="761dbacc-80ce-4cf7-9288-a684c4ecf6ad" defaultMessage="Voicemail" />
  ),
});

export default function PhoneRiskSection({
  customer: { phoneRiskSignal, phone },
}: {
  customer: TransactionCustomer;
}) {
  return (
    <dl>
      <Container fluid noSpacing className={Styles.phoneRisk}>
        <Heading className={Styles.sectionHeader} textStyle="subtitleSmall" level="h3">
          <Icon name="phone" />
          <FormattedMessage
            id="42499883-bd8c-4fea-99df-451da2079796"
            defaultMessage="Phone risk signals"
          />
          {!phone && (
            <Badge kind="infoSubtle">
              <FormattedMessage
                id="8c62b671-7a43-4af2-9fe9-d5d3f02d83ed"
                defaultMessage="Phone number not provided"
              />
            </Badge>
          )}
        </Heading>
        <Row>
          <Col>
            <dt>
              <Substyle className={Styles.phoneRiskTooltipWrapper} textColor="subtle" size="small">
                <FormattedMessage
                  id="8c209fee-dd56-45aa-acc0-a0815d09bc8a"
                  defaultMessage="Line type"
                />
                <Tooltip
                  target={
                    <Substyle textColor="subtle">
                      <Icon name="faq" />
                    </Substyle>
                  }
                  placement="topLeft"
                >
                  <FormattedMessage
                    id="8ae72631-6c81-4cfd-b828-447f2dce466a"
                    defaultMessage="VoIP could be an indicator of fraud "
                  />
                </Tooltip>
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                {phoneRiskSignal?.phoneLineType
                  ? LineTypeLabels[phoneRiskSignal.phoneLineType]
                  : "--"}
              </Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle textColor="subtle" size="small">
                <FormattedMessage
                  id="89ae78e0-ad89-4b12-840c-55fc11a0032d"
                  defaultMessage="Name of carrier"
                />
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">{phoneRiskSignal?.carrier ?? "--"}</Substyle>
            </dd>
          </Col>
          <Col>
            <dt>
              <Substyle className={Styles.phoneRiskTooltipWrapper} textColor="subtle" size="small">
                <FormattedMessage
                  id="2153e127-9064-48ea-8871-16780579b8d8"
                  defaultMessage="Last SIM swap"
                />
                <Tooltip
                  target={
                    <Substyle textColor="subtle">
                      <Icon name="faq" />
                    </Substyle>
                  }
                  placement="top"
                >
                  <FormattedMessage
                    id="ddc54371-452c-4719-88a6-1d73cf288390"
                    defaultMessage="A recent change in SIM could be an indicator of fraud"
                  />
                </Tooltip>
              </Substyle>
            </dt>
            <dd>
              <Substyle size="defaultSize">
                {phoneRiskSignal?.lastSimSwap
                  ? format({ value: phoneRiskSignal.lastSimSwap, formatStyle: "LL/dd/yyyy" })
                  : "--"}
              </Substyle>
            </dd>
          </Col>
        </Row>
      </Container>
    </dl>
  );
}
