import "./index.scss";

import { useState } from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { OrgTransactionStates, OrganizationTransactionContactRoleType } from "graphql_globals";
import { DeprecatedDetailGrid } from "common/details/grid";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { ROLE_LABELS } from "constants/points_of_contact";
import CollapsibleListItem from "common/core/collapsible_list/item";
import FormattedBoolean from "common/core/format/boolean";
import Link from "common/core/link";
import { captureException } from "util/exception";
import { userFullName } from "util/user";
import { maskPhone } from "common/form/inputs/masked_input";

import RemoveContactModal from "./remove_contact_modal";
import type { ContactForTransactionDetailsContactDetails } from "./index_fragment.graphql";

type Props = {
  contact: ContactForTransactionDetailsContactDetails;
  transaction: { state: OrgTransactionStates | null };
  automationId?: string;
  onSendNotification: () => Promise<unknown>;
  /** If falsy, will not show remove buttons */
  onRemove?: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  phoneNumber: {
    id: "49fb439a-a162-483d-a9e3-a30b14ccd568",
    defaultMessage: "Phone Number",
  },
  email: {
    id: "ef535ee6-5f3e-4c1d-af09-801e4608641b",
    defaultMessage: "Email Address",
  },
  shownToSigner: {
    id: "51dd2e8f-9163-46b3-80e1-1dd701fe1569",
    defaultMessage: "Shown to Signer",
  },
  accessToTransaction: {
    id: "be98436a-5040-4f50-a5ad-fe7bbbe55ec1",
    defaultMessage: "Access to Transaction",
  },
  remove: {
    id: "89d13bfc-a476-4de1-b6f3-36354e21f7dd",
    defaultMessage: "Remove",
  },
  resendAccessEmail: {
    id: "c048783b-d24d-4933-9c85-a22fcab247cc",
    defaultMessage: "Resend access email",
  },
  sendEmailAgain: {
    id: "4c4d5640-7a04-4c2b-8b00-920dde913eaa",
    defaultMessage: "Email sent. Send email again?",
  },
});

function ContactDetails(props: Props) {
  const { contact, onRemove, transaction } = props;
  const intl = useIntl();
  const [removeModalState, setRemoveModalState] = useState<"open" | "loading" | "closed">("closed");
  const [resendEmailModalState, setResendEmailModalState] = useState<"none" | "sending" | "sent">(
    "none",
  );
  const transactionSent =
    transaction.state !== OrgTransactionStates.STARTED &&
    transaction.state !== OrgTransactionStates.SENT_TO_CLOSING_OPS;

  const resendCX = classnames("ContactDetails--contentContainer", {
    disabled: resendEmailModalState === "sending",
  });

  return (
    <>
      <div className="ContactDetails" data-automation-id={props.automationId}>
        <CollapsibleListItem
          header={userFullName(contact)}
          subheader={
            <div className="ContatDetails--subheader">
              <span
                className={classnames(
                  "ContactDetails--role",
                  onRemove && "ContactDetails--role__can-edit",
                )}
              >
                {contact.role === OrganizationTransactionContactRoleType.OTHER
                  ? contact.title
                  : ROLE_LABELS[contact.role]}
              </span>
              {onRemove && (
                <Link
                  className="ContactDetails--remove"
                  automationId="remove-contact"
                  onClick={(e) => {
                    e.stopPropagation();
                    setRemoveModalState("open");
                  }}
                  underlined={false}
                >
                  {intl.formatMessage(MESSAGES.remove)}
                </Link>
              )}
            </div>
          }
          initialDisplay
        >
          <DeprecatedDetailGrid>
            <DeprecatedDetailGridSection>
              <DeprecatedDetailGridRow title={intl.formatMessage(MESSAGES.phoneNumber)}>
                {contact.phoneNumber && (
                  <div className="DetailGrid-data--subheading">
                    {maskPhone(contact.phoneNumber)}
                  </div>
                )}
              </DeprecatedDetailGridRow>
              <DeprecatedDetailGridRow title={intl.formatMessage(MESSAGES.email)}>
                {contact.email && (
                  <div className="DetailGrid-data--subheading">{contact.email}</div>
                )}
              </DeprecatedDetailGridRow>
              <DeprecatedDetailGridRow title={intl.formatMessage(MESSAGES.shownToSigner)}>
                <FormattedBoolean value={contact.shownToSigner} />
              </DeprecatedDetailGridRow>
              <DeprecatedDetailGridRow title={intl.formatMessage(MESSAGES.accessToTransaction)}>
                <div className={resendCX}>
                  <FormattedBoolean value={contact.accessToTransaction} />
                  {transactionSent && contact.accessToTransaction && (
                    <Link
                      className="ContactDetails--contentContainer--text"
                      automationId="resend-access-email"
                      onClick={() => {
                        if (resendEmailModalState === "sending") {
                          return;
                        }
                        setResendEmailModalState("sending");
                        props
                          .onSendNotification()
                          .then(() => {
                            setResendEmailModalState("sent");
                          })
                          .catch(() => {
                            setResendEmailModalState("none");
                            captureException(
                              new Error(
                                "Couldn't resend notification to organization transaction contact",
                              ),
                              { contact },
                            );
                          });
                      }}
                    >
                      {resendEmailModalState === "sent"
                        ? intl.formatMessage(MESSAGES.sendEmailAgain)
                        : intl.formatMessage(MESSAGES.resendAccessEmail)}
                    </Link>
                  )}
                </div>
              </DeprecatedDetailGridRow>
            </DeprecatedDetailGridSection>
          </DeprecatedDetailGrid>
        </CollapsibleListItem>
      </div>

      {removeModalState !== "closed" && (
        <RemoveContactModal
          contact={contact}
          isLoading={removeModalState === "loading"}
          onClose={() => setRemoveModalState("closed")}
          onRemove={() => {
            setRemoveModalState("loading");
            onRemove!()
              .catch(() => {
                captureException(new Error("Couldn't delete organization transaction contact"), {
                  contact,
                  transaction,
                });
              })
              .finally(() => {
                setRemoveModalState("closed");
              });
          }}
        />
      )}
    </>
  );
}

export default ContactDetails;
