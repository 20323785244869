import { useIntl } from "react-intl";

import type { EmailRiskReasonCodes } from "graphql_globals";
import AlertMessage from "common/core/alert_message";

import { emailRiskReasonCodeMessages } from "../risk_util";
import Styles from "./email_risk.module.scss";

type Props = {
  elementId?: string;
  reasonCodes: EmailRiskReasonCodes[];
  visible?: boolean;
};

export default function AdditionalEmailReasonCodes({
  elementId,
  reasonCodes,
  visible = true,
}: Props) {
  const intl = useIntl();

  return (
    <ul
      id={elementId}
      className={Styles.additionalCodesContainer}
      style={visible ? {} : { display: "none" }}
    >
      {reasonCodes.map((reasonCode) => {
        if (!Object.hasOwn(emailRiskReasonCodeMessages, reasonCode)) {
          return null;
        }
        // Cast should be safe because we checked it was a key above
        const message =
          emailRiskReasonCodeMessages[reasonCode as keyof typeof emailRiskReasonCodeMessages];
        return (
          <li key={reasonCode}>
            <AlertMessage kind="warning">{intl.formatMessage(message)}</AlertMessage>
          </li>
        );
      })}
    </ul>
  );
}
