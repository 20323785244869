import { type InjectedFormProps, reduxForm } from "redux-form";
import { useEffect, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import type {
  SigningTimeSchedule_organizationTransaction_OrganizationTransaction as OrganizationTransaction,
  SigningTimeSchedule_viewer as Viewer,
} from "common/details/summary/signing_time_schedule/index.query.graphql";
import compose from "util/compose";
import { validateSigningTimeRestrictions } from "common/mortgage/transactions/signing_time_restrictions/validate";
import { SIGNING_SCHEDULE_TYPES } from "constants/transaction";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { getFormValues } from "util/form";
import Button from "common/core/button";
import {
  deserializeSigningSchedule,
  serializeSigningSchedule,
} from "common/details/notary_details/items/signing_schedule_util";
import type { SigningScheduleTypes } from "graphql_globals";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import {
  RealEstateSigningScheduleTypeToggle,
  BusinessSigningScheduleTypeToggle,
  SIGNING_SCHEDULE_SELECTIONS,
} from "common/transactions/signing_schedule/signing_schedule_type_toggle";

import Styles from "./index.module.scss";
import { SigningWindowReduxForm } from "../signing_window";
import { SingleDateReduxForm } from "../scheduled_closing";
import UpdateSigningScheduleMutation from "./update_organization_transaction_v2_mutation.graphql";

export type FormValues = {
  signingScheduleType: SigningScheduleTypes | null | "NO_RESTRICTIONS";
  expirationTimezone: string | null;
  activationDate: Date | null;
  activationTimezone: string | null;
  expirationDate: Date | null;
  notaryMeetingTime: Date | null;
  notaryMeetingTimezone: string | null;
};

export type Props = {
  onReschedulerClose(): void;
  transaction: OrganizationTransaction;
  viewer: Viewer;
  formValues: FormValues;
  usersOrgCreatedTransaction: boolean;
};

type FormProps = InjectedFormProps<FormValues, Props>;

type InnerProps = FormProps & Props;

const SigningTimeRescheduler = ({
  initialize,
  formValues,
  transaction,
  change,
  handleSubmit,
  onReschedulerClose,
  viewer,
}: InnerProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const localSigningScheduleType = formValues.signingScheduleType;
  useEffect(() => {
    const {
      activationDate,
      activationTimezone,
      expirationDate,
      expirationTimezone,
      notaryMeetingTime,
      notaryMeetingTimezone,
    } = deserializeSigningSchedule({
      activationTimezone: transaction.activationTimezone,
      activationTime: transaction.activationTime,
      expiryTimezone: transaction.expiryTimezone,
      expiry: transaction.expiry,
      notaryMeetingTimezone: transaction.notaryMeetingTimezone,
      notaryMeetingTime: transaction.notaryMeetingTime,
      signingScheduleType: transaction.signingScheduleType,
      userTimezone: viewer.user?.timezone,
    });

    initialize({
      signingScheduleType: transaction.signingScheduleType!,
      expirationTimezone,
      activationDate,
      activationTimezone,
      expirationDate,
      notaryMeetingTime,
      notaryMeetingTimezone,
    });
  }, []);

  const updateSigningSchedule = useMutation(UpdateSigningScheduleMutation);

  const selectScheduleType = useCallback(
    (newValue: string) => {
      change("signingScheduleType", newValue);
    },
    [change],
  );

  const handleRescheduleClosing = (data: FormValues) => {
    const {
      signingScheduleType,
      expirationDate,
      expirationTimezone,
      activationDate,
      notaryMeetingTime,
    } = data;
    const signingScheduleData =
      signingScheduleType === SIGNING_SCHEDULE_SELECTIONS.NO_RESTRICTIONS
        ? {
            expirationDate: null,
            signingScheduleType: null,
            activationDate: null,
            notaryMeetingTime: null,
            expirationTimezone: null,
          }
        : {
            expirationDate,
            signingScheduleType,
            activationDate,
            notaryMeetingTime,
            expirationTimezone,
          };
    const signingSchedule = serializeSigningSchedule(signingScheduleData);
    setIsSubmitting(true);
    updateSigningSchedule({
      variables: {
        input: {
          signingSchedule,
          id: transaction.id,
        },
      },
    })
      .then(
        () => {
          onReschedulerClose();
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: (
              <FormattedMessage
                id="d7737f07-fe65-4a48-9c22-329f7305c3aa"
                defaultMessage="Successfully updated signing schedule"
              />
            ),
            subtype: NOTIFICATION_SUBTYPES.SUCCESS,
          });
        },
        () => {
          pushNotification({
            type: NOTIFICATION_TYPES.DEFAULT,
            message: (
              <FormattedMessage
                id="8818e0c7-1d77-4898-8dd3-6779d400869a"
                defaultMessage="Failed to update signing schedule. Try again later."
              />
            ),
            subtype: NOTIFICATION_SUBTYPES.ERROR,
          });
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title="Signing schedule">
        <div className={Styles.signingRescheduler}>
          {transaction.isMortgage ? (
            <RealEstateSigningScheduleTypeToggle
              windowSelected={localSigningScheduleType === SIGNING_SCHEDULE_TYPES.WINDOW}
              onClick={selectScheduleType}
            />
          ) : (
            <BusinessSigningScheduleTypeToggle
              selectedPill={localSigningScheduleType || "NO_RESTRICTIONS"}
              showScheduledDatePill={false}
              showWindowPill
              onClick={selectScheduleType}
            />
          )}
          {localSigningScheduleType === SIGNING_SCHEDULE_TYPES.WINDOW && (
            <SigningWindowReduxForm formName="rescheduleSigning" />
          )}
          {localSigningScheduleType === SIGNING_SCHEDULE_TYPES.DATE && (
            <SingleDateReduxForm formName="rescheduleSigning" transaction={transaction} />
          )}
          <div className={Styles.signingReschedulerFooter}>
            <Button onClick={onReschedulerClose} variant="tertiary" buttonColor="dark">
              <FormattedMessage id="b8dc031d-72c6-449b-a905-cc5863967c1c" defaultMessage="Cancel" />
            </Button>
            <Button
              disabled={isSubmitting}
              buttonColor="action"
              variant="primary"
              onClick={handleSubmit(handleRescheduleClosing)}
            >
              <FormattedMessage
                id="4dbb7a44-1cb3-4439-ae46-c92072548da9"
                defaultMessage="Save changes"
              />
            </Button>
          </div>
        </div>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
};

export default compose(
  getFormValues<Props>("rescheduleSigning"),
  reduxForm<FormValues, Props>({
    form: "rescheduleSigning",
    validate: (values, props) => validateSigningTimeRestrictions(values, props)(values),
  }),
)(SigningTimeRescheduler);
