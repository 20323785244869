import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import {
  mortgageDashboardDeserializer,
  useSidebarFilters,
  mortgageDashboardSerializer,
} from "common/mortgage/transactions/dashboard/filter";
import {
  Sidebar,
  SidebarSectionHeader,
  SidebarTabButton,
  SidebarTabLink,
  SidebarTabButtonSublabel,
} from "common/sidebar";
import { useDashboardSegmentTrack, useShowEasylinks } from "common/dashboard/util";
import { Feature, type OrganizationTransactionDetailedStatus } from "graphql_globals";
import {
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
} from "common/dashboard/filter_dropdown/common";
import { SEGMENT_EVENTS } from "constants/analytics";
import Icon from "common/core/icon";
import { useProofDefend, useProofDefendUpsell } from "util/feature_detection";
import { TEMPLATES_DASHBOARD_PATH, EASYLINK_DASHBOARD_PATH } from "util/routes";
import { usePermissions } from "common/core/current_user_role";
import { Badge } from "common/core/badge";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./transactions_sidebar.module.scss";
import type { SubTab } from "./subtab";
import type { TitleTransactions_node_Organization as Organization } from "./transactions.query.graphql";

const MESSAGES = defineMessages({
  tools: {
    id: "d8aa08c8-612e-4aef-8e1d-de782f63ac60",
    defaultMessage: "Tools",
  },
});

type SidebarProps = {
  organization: Organization | null;
  inProgressStatuses: OrganizationTransactionDetailedStatus[];
  showDuplicateUI: boolean;
  subTab: SubTab | null;
  draftStatuses: OrganizationTransactionDetailedStatus[];
  actionNeededStatuses: OrganizationTransactionDetailedStatus[];
  section: TransactionSectionNamespace;
  transactionsActive: boolean;
};

export default function TransactionsSidebar({
  organization,
  inProgressStatuses,
  showDuplicateUI,
  subTab,
  draftStatuses,
  actionNeededStatuses,
  section,
  transactionsActive,
}: SidebarProps) {
  const intl = useIntl();
  const dashboardSegmentTrack = useDashboardSegmentTrack();
  const { hasPermissionFor } = usePermissions();
  const proofDefendUpsell = useProofDefendUpsell(organization);
  const proofDefend = useProofDefend(organization);
  const realEstateEasylinkEnabled = useFeatureFlag("real-estate-easylink");
  const {
    deserializedArgs,
    selectAllTransactionTab,
    selectCreatedByMeSubTab,
    selectByDetailedStatusesSubTab,
    selectOpenOrderTab,
    selectTabByName,
    selectIdentityIssueSubTab,
    selectIdentityRiskSubTab,
  } = useSidebarFilters(mortgageDashboardDeserializer, mortgageDashboardSerializer);
  const { subSection } = deserializedArgs;
  const placeOrderEnabled = Boolean(organization?.placeAnOrderEnabled);

  const allTransactionsCount = organization?.allTransactionsCount.totalCount;
  const createdByMeTransactionsCount = organization?.createdByMeTransactionsCount.totalCount;
  const draftTransactionsCount = organization?.draftTransactionsCount.totalCount;
  const inProgressTransactionsCount = organization?.inProgressTransactionsCount.totalCount;
  const openOrderTransactionsCount = organization?.openOrderTransactionsCount.totalCount;
  const actionNeededTransactionsCount = organization?.actionNeededTransactionsCount.totalCount;
  const identityIssuesTransactionCount = organization?.identityIssuesTransactionsCount.totalCount;
  const identityRiskTransactionCount = organization?.identityRiskTransactionsCount.totalCount;
  const duplicateTransactionsCount = organization?.duplicateTransactionsCount.totalCount;
  const archivedTransactionsCount = organization?.archivedTransactionsCount.totalCount;
  const showTemplates =
    organization?.featureList.includes(Feature.DOCUMENT_TEMPLATES) &&
    hasPermissionFor("manageTitleTemplates");
  const showEasylinks =
    useShowEasylinks(organization?.featureList || []) && realEstateEasylinkEnabled;

  const isAllTransactionsSelected = section === TransactionSectionNamespace.ALL && !subTab;
  const isDraftSelected = subTab === "draft";
  const isOnProgressSelected = subTab === "in_progress";
  const isActionNeededSelected = subTab === "action_needed";

  return (
    <Sidebar
      title={
        <FormattedMessage id="b0eba33a-46cb-4f7f-b8c1-596434d5cd6e" defaultMessage="My closings" />
      }
    >
      <SidebarTabButton
        active={transactionsActive && isAllTransactionsSelected}
        onClick={() => {
          if (!isAllTransactionsSelected || subSection !== TransactionSubsectionNamespace.DEFAULT) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_TRANSACTIONS",
            });
            selectAllTransactionTab();
          }
        }}
        automationId="transaction-filter-all"
        isHeader
      >
        <FormattedMessage
          id="e8b1c570-7e3b-4f19-81ef-d1f96c6351d6"
          defaultMessage="All transactions"
        />
        <SidebarTabButtonSublabel>{allTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && subTab === TransactionSubsectionNamespace.CREATED_BY_ME}
        onClick={() => {
          if (subTab !== TransactionSubsectionNamespace.CREATED_BY_ME) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_CREATED_BY_ME",
            });
            selectCreatedByMeSubTab();
          }
        }}
        isSubHeader
      >
        <FormattedMessage
          id="863364bd-0fb7-464a-b9a3-eef2a7da9308"
          defaultMessage="Created by me"
        />
        <SidebarTabButtonSublabel>{createdByMeTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && isDraftSelected}
        onClick={() => {
          if (!isDraftSelected) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_DRAFTS",
            });
            selectByDetailedStatusesSubTab(draftStatuses);
          }
        }}
        isSubHeader
      >
        <FormattedMessage id="02a12b9b-fd15-42e1-8c97-a2b7cb33011a" defaultMessage="Drafts" />
        <SidebarTabButtonSublabel>{draftTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={isOnProgressSelected}
        onClick={() => {
          if (!isOnProgressSelected) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_IN_PROGRESS",
            });
            selectByDetailedStatusesSubTab(inProgressStatuses);
          }
        }}
        isSubHeader
      >
        <FormattedMessage id="93d884b0-46e2-4670-b055-bb78b6e3fe29" defaultMessage="In progress" />
        <SidebarTabButtonSublabel>{inProgressTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && subTab === TransactionSubsectionNamespace.KBA_ISSUES}
        onClick={() => {
          if (subTab !== TransactionSubsectionNamespace.KBA_ISSUES) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_IN_PROGRESS",
            });
            selectIdentityIssueSubTab();
          }
        }}
        isSubHeader
        automationId="kba-issues-subtab"
      >
        {Boolean(identityIssuesTransactionCount) && (
          <Icon className={Styles.warningIcon} name="warning" />
        )}
        <FormattedMessage id="2733b7ca-9813-4ba7-bb23-e1da5f93d7f9" defaultMessage="KBA issues" />
        <SidebarTabButtonSublabel>{identityIssuesTransactionCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      {(proofDefend || proofDefendUpsell) && (
        <SidebarTabButton
          active={transactionsActive && subTab === TransactionSubsectionNamespace.IDENTITY_RISK}
          onClick={() => {
            if (subTab === TransactionSubsectionNamespace.IDENTITY_RISK) {
              return;
            }
            if (proofDefend || proofDefendUpsell) {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: "SIDEBAR_ALL_IDENTITY_RISK",
              });
              selectIdentityRiskSubTab();
            }
          }}
          isSubHeader
          automationId="identity-risk-subtab"
        >
          <FormattedMessage
            id="662f705c-630f-4607-af83-62f4061307ba"
            defaultMessage="Identity risk"
          />
          {proofDefendUpsell ? (
            <SidebarTabButtonSublabel>
              <Badge kind="new">
                <FormattedMessage
                  id="938129f4-6cce-49cf-a485-8ff81d6314c1"
                  defaultMessage="Upgrade"
                />
              </Badge>
            </SidebarTabButtonSublabel>
          ) : (
            <SidebarTabButtonSublabel>{identityRiskTransactionCount}</SidebarTabButtonSublabel>
          )}
        </SidebarTabButton>
      )}
      <SidebarTabButton
        active={transactionsActive && isActionNeededSelected}
        onClick={() => {
          if (!isActionNeededSelected) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ACTION_NEEDED",
            });
            selectByDetailedStatusesSubTab(actionNeededStatuses);
          }
        }}
        isSubHeader
      >
        <FormattedMessage
          id="e1755cd3-3fac-4488-9db7-c0e06b3e4181"
          defaultMessage="Action needed"
        />
        {Boolean(actionNeededTransactionsCount) && (
          <SidebarTabButtonSublabel pill={{ color: "orange" }}>
            {actionNeededTransactionsCount}
          </SidebarTabButtonSublabel>
        )}
      </SidebarTabButton>
      {placeOrderEnabled && Boolean(openOrderTransactionsCount) && (
        <SidebarTabButton
          automationId="transaction-filter-open-orders"
          active={transactionsActive && section === TransactionSectionNamespace.OPEN_ORDER}
          onClick={() => {
            if (section !== TransactionSectionNamespace.OPEN_ORDER) {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: "OPEN_ORDER_SECTION",
              });
              selectOpenOrderTab();
            }
          }}
          isHeader
        >
          <FormattedMessage
            id="8c426841-8451-4139-8a38-f079dcb58fcf"
            defaultMessage="Open orders"
          />
          <SidebarTabButtonSublabel>{openOrderTransactionsCount}</SidebarTabButtonSublabel>
        </SidebarTabButton>
      )}
      <SidebarTabButton
        automationId="transaction-filter-archived"
        active={transactionsActive && section === TransactionSectionNamespace.ARCHIVED}
        onClick={() => {
          selectTabByName(TransactionSectionNamespace.ARCHIVED);
        }}
        isHeader
      >
        <FormattedMessage id="793e7783-9f3f-42dc-b6ca-63cfd084e84f" defaultMessage="Archived" />
        <SidebarTabButtonSublabel>{archivedTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      {showDuplicateUI && (
        <SidebarTabButton
          active={transactionsActive && section === TransactionSectionNamespace.DUPLICATED}
          onClick={() => {
            if (section !== TransactionSectionNamespace.DUPLICATED) {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: "SIDEBAR_ALL_DUPLICATES",
              });
              selectTabByName(TransactionSectionNamespace.DUPLICATED);
            }
          }}
          isHeader
        >
          <FormattedMessage id="d57f2e8e-f30e-497c-a5b0-259890843d00" defaultMessage="Duplicated" />
          <SidebarTabButtonSublabel>{duplicateTransactionsCount}</SidebarTabButtonSublabel>
        </SidebarTabButton>
      )}
      {(showEasylinks || showTemplates) && (
        <>
          <SidebarSectionHeader title={intl.formatMessage(MESSAGES.tools)} />
          {showEasylinks && (
            <SidebarTabLink to={EASYLINK_DASHBOARD_PATH}>
              <div>
                <FormattedMessage
                  id="ab87f464-71d1-4fe3-9210-dce300dc3462"
                  defaultMessage="EasyLinks"
                />
                {/* Remove in BIZ-6131 */}
                <Badge kind="new" className={Styles.newBadge}>
                  <FormattedMessage
                    id="ed3ff8ab-42b7-46d9-8181-1e54bd77eea5"
                    defaultMessage="New"
                  />
                </Badge>
              </div>
              <SidebarTabButtonSublabel>
                {organization?.easylinks.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
          {showTemplates && (
            <SidebarTabLink to={TEMPLATES_DASHBOARD_PATH}>
              <FormattedMessage
                id="fffce34a-8774-4ef1-8d5d-cb10ff06e77b"
                defaultMessage="Document templates"
              />
              <SidebarTabButtonSublabel>
                {organization?.templates.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
        </>
      )}
    </Sidebar>
  );
}
