import { type ComponentProps, type ReactNode, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";

import Styles from "./common.module.scss";

type BulkActionButtonProps = Pick<
  ComponentProps<typeof Button>,
  "buttonColor" | "onClick" | "disabled" | "disabledHint" | "withIcon" | "className"
>;

type Props = {
  automationSuffix: string;
  label: ReactNode;
} & BulkActionButtonProps;

export function useBulkActions(items: { id: string }[]) {
  const [selectedItemIdsSet, setSelectedItemIdsSet] = useState<Set<string>>(new Set());

  const allItemIds = useMemo(() => items.map((item) => item.id), [items]);

  const clearAllItems = () => {
    setSelectedItemIdsSet(new Set());
  };

  const toggleItem = (itemId: string) => {
    setSelectedItemIdsSet((prev) => {
      const items = new Set(prev);
      items.has(itemId) ? items.delete(itemId) : items.add(itemId);
      return items;
    });
  };

  const toggleAllItems = () => {
    const items = selectedItemIdsSet.size < allItemIds.length ? new Set(allItemIds) : new Set([]);
    setSelectedItemIdsSet(items);
  };

  const getSelectAllCheckboxState = (): "unchecked" | "checked" | "partial" => {
    if (selectedItemIdsSet.size === 0) {
      return "unchecked";
    }

    if (selectedItemIdsSet.size < allItemIds.length) {
      return "partial";
    }

    return "checked";
  };

  const selectedItemIdsArray = Array.from(selectedItemIdsSet);
  const selectedItemCount = selectedItemIdsArray.length;

  useEffect(() => {
    clearAllItems();
  }, [allItemIds]);

  return {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsSet,
    selectedItemIdsArray,
    setSelectedItemIds: setSelectedItemIdsSet,
    selectAllCheckboxState: getSelectAllCheckboxState(),
  };
}

export function BulkActionButton({ automationSuffix, label, ...props }: Props) {
  return (
    <Button
      variant="tertiary"
      buttonSize="condensed"
      automationId={`bulk-actions-${automationSuffix}`}
      {...props}
    >
      {label}
    </Button>
  );
}

export function BulkActionClearButton({ onClick }: { onClick: () => void }) {
  return (
    <BulkActionButton
      className={Styles.clearButton}
      automationSuffix="clear"
      withIcon={{ name: "x-filled", placement: "left" }}
      label={
        <FormattedMessage
          id="ee123913-9478-47f4-89b7-8d9228b7b68a"
          defaultMessage="Clear selection"
        />
      }
      onClick={onClick}
    />
  );
}

export function BulkActionSeparator() {
  return <div className={Styles.separator}></div>;
}
