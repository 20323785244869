import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { AnnotationSubtype } from "graphql_globals";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { segmentTrack } from "util/segment";

import type { NotaryMeetingDocumentPsp_documentBundle_documents_edges_node_annotations_edges_node as AnnotationBase } from "./index_fragment.graphql";

type DocumentBundle = {
  organizationTransaction: { streetAddress: null | { state: string } };
};
type ModalMessageKind = "ca-xxx";
type ModalState =
  | { visible: false; kind: "never-seen" | "dismissed" }
  | { visible: true; kind: ModalMessageKind };
type UpdateWarningAnnotation = Pick<AnnotationBase, "__typename" | "subtype"> & {
  text?: string | null;
};

export function AnnotationUpdateWarningModal(props: {
  messageKind: ModalMessageKind;
  meetingId: string;
  onClose: () => void;
}) {
  useEffect(() => {
    segmentTrack("Notary shown annotation warning", {
      meetingId: props.meetingId,
      warningKind: props.messageKind,
    });
  }, []);
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="1d9729c4-8aa6-40ab-9353-1a0cd4ab1410"
          defaultMessage="Editing a pre-printed notarial statement?"
        />
      }
      buttons={[
        <Button key="dismiss" variant="secondary" buttonColor="action" onClick={props.onClose}>
          <FormattedMessage id="6910b2d2-ac8d-43b5-af1e-422106fd4dc3" defaultMessage="Dismiss" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      {/* Only one kind of message right now... */}
      <FormattedMessage
        id="dbecbead-16ee-4859-92a3-a17490d19a24"
        defaultMessage="It looks like you may be editing a pre-printed notarial statement. Attaching a looseleaf instead may reduce the chance of rejection."
      />
    </WorkflowModal>
  );
}

function isSuspectEdit(documentBundle: DocumentBundle, text: string): ModalMessageKind | undefined {
  const orgTransactionPropertyState =
    documentBundle.organizationTransaction.streetAddress?.state.toLowerCase();
  const isPropertyAddressCalifornia =
    orgTransactionPropertyState === "california" || orgTransactionPropertyState === "ca";
  // We don't want notaries XXXXX (whiteout-ing) pre printed statements on CA mortgage
  // documents. We don't know if that's _exactly_ what they're doing, so this is just
  // a best guess that hopefully is not too annoying.
  if (isPropertyAddressCalifornia && /xxxx/i.test(text)) {
    return "ca-xxx";
  }
}

/**
 * This hook adds helpful warnings if we think the notary is modifying an annotation in
 * some way we think they shouldn't be.
 */
export function useAnnotationUpdateWarning(documentBundle: Parameters<typeof isSuspectEdit>[0]) {
  const [modalState, setModalState] = useState<ModalState>({ visible: false, kind: "never-seen" });
  const updateHandler = (
    annotation: UpdateWarningAnnotation,
    evt: { type: "edittext" | "resize" | "move" },
  ) => {
    if (modalState.visible || modalState.kind === "dismissed") {
      return;
    }
    const editTextWarningKind =
      evt.type === "edittext" &&
      annotation.text &&
      annotation.__typename === "TextAnnotation" &&
      annotation.subtype === AnnotationSubtype.FREE_TEXT &&
      isSuspectEdit(documentBundle, annotation.text);
    if (editTextWarningKind) {
      setModalState({ visible: true, kind: editTextWarningKind });
    }
  };
  return {
    updateHandler,
    modalMessageKind: modalState.visible ? modalState.kind : null,
    handleCloseModal: () => setModalState({ visible: false, kind: "dismissed" }),
  };
}
