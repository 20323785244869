import "./index.scss";

// Eventually this could replace the notary/admin "panel" common
export default function AppContainer({ children }) {
  return (
    <main className="AppContainer" id="main-content">
      {children}
    </main>
  );
}
