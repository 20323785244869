import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";

type Props = {
  transaction: { messageSubject: string | null };
};

export default function TransactionMessageSubject({ transaction }: Props) {
  return (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow
        title={
          <FormattedMessage
            id="c372a52e-d134-4b3d-a8f4-d1ea0c913dfc"
            defaultMessage="Subject Line"
          />
        }
      >
        <p>
          {transaction.messageSubject || (
            <FormattedMessage
              id="bbde017e-9dd1-4c74-82b0-b1d8a82ee1ed"
              defaultMessage="No subject provided"
            />
          )}
        </p>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}
