import { useRef, useState, useEffect } from "react";
import {
  defer,
  of,
  Observable,
  map,
  filter,
  tap,
  startWith,
  combineLatest,
  distinctUntilChanged,
  switchMap,
  EMPTY,
} from "rxjs";
import type { AudioVideoFacade, MeetingSession } from "amazon-chime-sdk-js";

import { MeetingParticipantRoles } from "graphql_globals";
import { retryBackoffWithCaptureException } from "util/rxjs";
import type { Devices } from "common/selected_devices_controller";
import type { VideoBackgroundSettings } from "common/video_conference";
import { useBehaviorSubject } from "util/rxjs/hooks";
import { fromSocketEvent } from "socket/util";
import { SEGMENT_EVENTS } from "constants/analytics";
import { segmentTrack } from "util/segment";
import type Channel from "socket/channel";

import { RETRY_BACKOFF_CONFIG } from ".";
import { getVideoInputDevice, isVideoBackgroundEqual } from "./background";

type LocalTracksProps = {
  selectedDevices: Devices;
  session: MeetingSession;
  muted: boolean;
  publishVideo: boolean;
  signerResetConnectionParams?: { userId: string; channel: Channel };
  participantRole: MeetingParticipantRoles;
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  sdkModule: typeof import("amazon-chime-sdk-js");
  videoBackground: VideoBackgroundSettings | undefined;
};
type ComposeOptions = {
  isSpectator: boolean;
  session: MeetingSession;
  sdkModule: LocalTracksProps["sdkModule"];
  props$: Observable<LocalTracksProps>;
  videoElem: HTMLVideoElement;
  audioElem: HTMLAudioElement;
};

const SKIP_DEVICE_SETUP = of<true>(true);
const noop = () => {};

function getAudioOutput(options: ComposeOptions): Observable<true> {
  const { props$, session } = options;
  const { audioVideo } = session;
  return props$.pipe(
    map((props) => props.selectedDevices.speaker),
    distinctUntilChanged(),
    switchMap((speakerId) => {
      const devices$ = speakerId ? defer(() => audioVideo.listAudioOutputDevices()) : of([]);
      return devices$.pipe(
        switchMap((allDevices) => {
          const foundSpeaker = allDevices.find((d) => d.deviceId === speakerId);
          return foundSpeaker
            ? defer(() =>
                audioVideo.chooseAudioOutput(foundSpeaker.deviceId).then(() => true as const),
              )
            : SKIP_DEVICE_SETUP;
        }),
      );
    }),
  );
}

function getAudioInput(options: ComposeOptions): Observable<true> {
  const { props$, session, isSpectator } = options;
  const { audioVideo } = session;
  return props$.pipe(
    map((props) => props.selectedDevices.microphone),
    distinctUntilChanged(),
    switchMap((microphoneId) => {
      return defer(() => audioVideo.listAudioInputDevices()).pipe(
        switchMap((allDevices) => {
          if (!allDevices.length) {
            return EMPTY;
          }
          const { deviceId } = allDevices.find((d) => d.deviceId === microphoneId) || allDevices[0];
          return new Observable<true>((observer) => {
            audioVideo
              .startAudioInput(isSpectator ? null : deviceId)
              .then(() => observer.next(true))
              .catch((e) => observer.error(e));

            if (isSpectator) {
              // We already set the input to be null but just for extra safety:
              audioVideo.realtimeMuteLocalAudio();
              return;
            }

            const muteSub = props$
              .pipe(
                map((props) => Boolean(props.muted)),
                distinctUntilChanged(),
              )
              .subscribe((muted) => {
                if (muted) {
                  audioVideo.realtimeMuteLocalAudio();
                } else {
                  audioVideo.realtimeUnmuteLocalAudio();
                }
              });
            return () => {
              muteSub.unsubscribe();
            };
          });
        }),
      );
    }),
    tap({
      unsubscribe() {
        audioVideo.stopAudioInput().catch(noop);
      },
    }),
  );
}

function getVideo(options: ComposeOptions): Observable<true> {
  if (options.isSpectator) {
    return SKIP_DEVICE_SETUP; // spectators do not send video
  }
  const { props$, session, sdkModule } = options;
  const { audioVideo } = session;
  const privacyVideoAspectRatio$ = props$.pipe(
    map((props) => props.participantRole === MeetingParticipantRoles.NOTARY),
    distinctUntilChanged(),
  );
  const videoDeviceReady$ = props$.pipe(
    map((props) => props.selectedDevices.webcam),
    distinctUntilChanged(),
    switchMap((webcamId) => {
      return defer(() => audioVideo.listVideoInputDevices()).pipe(
        switchMap((allDevices) => {
          if (!allDevices.length) {
            return EMPTY;
          }
          const { deviceId } = allDevices.find((d) => d.deviceId === webcamId) || allDevices[0];
          const videoBackground$ = props$.pipe(
            map((props) => props.videoBackground),
            distinctUntilChanged(isVideoBackgroundEqual),
          );
          const videoConfig$ = combineLatest([privacyVideoAspectRatio$, videoBackground$]);
          return videoConfig$.pipe(
            switchMap(([privacyVideoAspectRatio, videoBackground]) => {
              return new Observable<true>((observer) => {
                let active = true;
                getVideoInputDevice({
                  deviceId,
                  privacyVideoAspectRatio,
                  videoBackground,
                  sdkModule,
                })
                  .then((device) => {
                    return active ? audioVideo.startVideoInput(device) : null;
                  })
                  .then(() => {
                    return active
                      ? audioVideo.startVideoPreviewForVideoInput(options.videoElem)
                      : null;
                  })
                  .then(() => observer.next(true))
                  .catch((e) => observer.error(e));
                return () => {
                  active = false;
                };
              });
            }),
          );
        }),
      );
    }),
    tap({
      unsubscribe() {
        audioVideo.stopVideoInput().catch(noop);
        audioVideo.stopVideoPreviewForVideoInput(options.videoElem);
      },
    }),
  );
  return props$.pipe(
    map((props) => Boolean(props.publishVideo)),
    distinctUntilChanged(),
    switchMap((publish) => (publish ? videoDeviceReady$ : SKIP_DEVICE_SETUP)),
  );
}

function useResetSignerAVConnection(params: LocalTracksProps["signerResetConnectionParams"]) {
  const [resetActive, setResetActive] = useState(false);
  const { userId, channel } = params || {};
  useEffect(() => {
    if (!userId || !channel) {
      return;
    }
    const sub = fromSocketEvent<{ user_id: string }>(channel, "meeting.reset_connection")
      .pipe(
        filter((event) => event.user_id === userId),
        tap(() => segmentTrack(SEGMENT_EVENTS.SIGNER_RECEIVED_RESET_CONNECTION)),
        switchMap(() => {
          // This is quite the hack. Chime doesn't like to restart connections too
          // quickly for whatever reason. This allows us to delay the restart by
          // shutting off the connection and then restarting some delay later.
          return new Observable<boolean>((observer) => {
            observer.next(true);
            const timeoutId = window.setTimeout(() => observer.next(false), 1_000);
            return () => window.clearTimeout(timeoutId);
          });
        }),
        startWith(false),
      )
      .subscribe(setResetActive);
    return () => sub.unsubscribe();
  }, [userId, channel]);
  return resetActive;
}

function getBoundAudioElement(audioVideo: AudioVideoFacade, element: HTMLAudioElement) {
  return defer(() => audioVideo.bindAudioElement(element)).pipe(
    tap({
      unsubscribe() {
        audioVideo.unbindAudioElement();
      },
    }),
  );
}

export function LocalTracks(props: LocalTracksProps) {
  const { session, sdkModule } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const props$ = useBehaviorSubject(props);
  useEffect(() => {
    props$.next(props);
  });

  const resetActive = useResetSignerAVConnection(props.signerResetConnectionParams);

  const isSpectator = props.participantRole === MeetingParticipantRoles.SPECTATOR;
  useEffect(() => {
    const { audioVideo } = session;
    if (resetActive) {
      return;
    }
    if (isSpectator) {
      // This line is recommended by the SDK and prevents browser permission prompts.
      audioVideo.setDeviceLabelTrigger(() => Promise.resolve(new MediaStream()));
    }
    const options: ComposeOptions = {
      isSpectator,
      session,
      props$,
      sdkModule,
      videoElem: videoRef.current!,
      audioElem: audioRef.current!,
    };
    const sub = combineLatest([
      getBoundAudioElement(audioVideo, audioRef.current!),
      getAudioInput(options),
      getAudioOutput(options),
      getVideo(options),
    ])
      .pipe(
        tap({
          next() {
            audioVideo.start();
            if (!isSpectator) {
              audioVideo.startLocalVideoTile();
            }
          },
          finalize() {
            if (!isSpectator) {
              audioVideo.stopLocalVideoTile();
            }
            audioVideo.stop();
          },
        }),
        retryBackoffWithCaptureException(RETRY_BACKOFF_CONFIG),
      )
      .subscribe();
    return () => sub.unsubscribe();
  }, [props$, isSpectator, session, resetActive]);

  return (
    <>
      <audio ref={audioRef} />
      {!isSpectator && <video ref={videoRef} />}
    </>
  );
}
