import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";

import Styles from "./liveness_check.module.scss";

type Props = {
  selfiePicture: string;
};

export function LivenessCheck({ selfiePicture }: Props) {
  return (
    <DeprecatedDetailGridRow
      className={Styles.row}
      title={
        <FormattedMessage
          id="24a5c752-9f1f-4edd-8df5-1973e64e307d"
          defaultMessage="Liveness Check"
        />
      }
    >
      <img className={Styles.selfie} src={selfiePicture} alt="selfie" />
    </DeprecatedDetailGridRow>
  );
}
