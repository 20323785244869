import { useCallback, useState, useRef, type ReactElement } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import LoadingIndicator from "common/core/loading_indicator";
import Carousel, { Slide } from "common/modals/carousel";
import { useQuery, useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { segmentTrack } from "util/segment";
import { usePermissions } from "common/core/current_user_role";
import CreateSurveyResponseMutation from "util/apollo_graphql/mutations/create_survey_response/mutation.graphql";
import { useActiveOrganization } from "common/account/active_organization";
import { SurveyQuestions, Feature, Language } from "graphql_globals";
import CreateOrganizationNotaryMutation from "common/onboarding_slides/create_organization_notary_mutation.graphql";
import TagUserMutationGraph from "common/onboarding_slides/tag_user_mutation.graphql";
import NotarySlide, { type NotarySubmitProps } from "common/onboarding_slides/notary";
import { SEGMENT_EVENTS } from "constants/analytics";
import { GET_STARTED_PATH } from "common/proof_frame/path";
import { userFullName } from "util/user";
import { ASSIGNABLE_ROLE_NAMES } from "constants/assignable_roles";
import Svg from "common/core/svg";
import ProofTitlePortalLight from "assets/images/logos/portal-logos/proof-title-light.svg";
import { useViewer } from "util/viewer_wrapper";
import { useFeatureFlag } from "common/feature_gating";
import { OnboardingWrapper, useOnboardingSteps } from "common/onboarding";
import { TITLE_IHN_STEPS, TITLE_ODN_STEPS, STEPS } from "common/onboarding/steps";
import { PaymentStep } from "common/onboarding/steps/payment";
import { InviteCoworkerStep } from "common/onboarding/steps/invite_coworker";

import type { TitleRouterViewer_viewer as TitleRouterViewer } from "../../router/viewer_query.graphql";
import UpdateTitleAgencyMutation from "../update_title_agency_mutation.graphql";
import DeprecatedTitleAccountOnboardingQuery, {
  type TitleAccountOnboarding_node_Organization as Organization,
  type TitleAccountOnboarding_viewer_user as User,
  type TitleAccountOnboarding_viewer as Viewer,
} from "./deprecated_index.graphql";
import PlaceOrderOnboardingEligibilities, {
  type FormValues as PlaceOrderFormValues,
} from "../place_order_onboarding/slides/eligibilities";
import TitleAccountOnboardingAccountSetupSlide, {
  type FormValues as AccountSetupFormValues,
} from "./slides/account_setup";
import { EligibilityStep } from "./steps/eligibility";
import TitleAccountOnboardingQuery from "./index.graphql";
import { NotaryStep } from "./steps/notary";

type DeprecatedProps = DeprecatedWrapperProps & {
  organization: Organization;
  user: User;
  viewer: Viewer;
};
type DeprecatedWrapperProps = {
  showQueryLoader?: boolean;
  children?: ReactElement;
  ignoreOnboarding?: boolean;
};

type WrapperProps = {
  children: ReactElement;
};

const MESSAGES = defineMessages({
  portalLogoAlt: {
    id: "7235b63f-b177-4685-a13b-d7b89bd1cad7",
    defaultMessage: "Proof for title",
  },
});

const SLIDESHOW_NOTARY_TAG = "title_onboarding_slideshow_notary";

function DeprecatedLoadedTitleAccountOnboarding(props: DeprecatedProps) {
  const { children, organization, user, viewer } = props;
  const { assignableRoles } = organization;
  const { hasPermissionFor } = usePermissions();
  const [isSubmittingResponses, setIsSubmittingResponses] = useState(false);
  const [isSavingAccountDetails, setIsSavingAccountDetails] = useState(false);
  const [isSavingEligibilityData, setIsSavingEligibilityData] = useState(false);
  const [isOnboardingDismissed, setIsOnboardingDismissed] = useState(false);

  const notarySlideTag = user.tags?.find((tag) => tag?.tag === SLIDESHOW_NOTARY_TAG);
  const onBoardingCardShowed = useRef(Boolean(notarySlideTag));
  const showAccountOnboardingCards = useRef(!organization.isSetup.complete);

  const navigate = useNavigate();

  const createSurveyResponseMutateFn = useMutation(CreateSurveyResponseMutation);
  const updateTitleAgencyMutateFn = useMutation(UpdateTitleAgencyMutation);

  const handleUpdateOrganization = useCallback(
    (formValues: AccountSetupFormValues) => {
      setIsSavingAccountDetails(true);

      const address = formValues.titleAgencyAddress
        ? { ...formValues.titleAgencyAddress, __typename: undefined }
        : null;

      return Promise.all([
        updateTitleAgencyMutateFn({
          variables: {
            input: {
              organizationId: organization.id,
              organizationName: formValues.organizationName,
              address,
              phone: formValues.phoneNumber,
              altaId: formValues.altaId,
            },
          },
        }),
        createSurveyResponseMutateFn({
          variables: {
            mutationInput: {
              question: SurveyQuestions.SEND_NOTARIZATION_FREQUENCY,
              responses: [formValues.expectedVolume!],
            },
          },
        }),
      ])
        .catch((error) => {
          captureException(error, { formValues });
        })
        .then(() => {
          segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_ACCOUNT_SETUP_COMPLETED, {
            pricing_plan_id: organization.activeTier.planId,
          });
        })
        .finally(() => {
          setIsSavingAccountDetails(false);
        });
    },
    [organization],
  );

  type ListOption = {
    label: string;
    value: string;
  };

  const handleUpdateEligibilities = useCallback(
    (formValues: PlaceOrderFormValues) => {
      const usStateIds = formValues.states.map((s: ListOption) => s.value);
      const titleUnderwriterIds = formValues.titleUnderwriters.map((u: ListOption) => u.value);

      setIsSavingEligibilityData(true);

      return updateTitleAgencyMutateFn({
        variables: {
          input: {
            organizationId: organization.id,
            usStateIds,
            titleUnderwriterIds,
          },
        },
      })
        .catch(() => {
          captureException(new Error("Error updating title agency with eligibility information"), {
            organizationId: organization.id,
            usStateIds,
            titleUnderwriterIds,
          });
        })
        .then(() => {
          segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_PLACE_ORDER_ONBOARDING_COMPLETED);
        })
        .finally(() => {
          setIsSavingEligibilityData(false);
        });
    },
    [organization],
  );

  const tagUserMutateFn = useMutation(TagUserMutationGraph);

  const createOrganizationNotaryMutateFn = useMutation(CreateOrganizationNotaryMutation);

  const handleNotarySubmit = ({ isNotary, notaryCommissionStateId }: NotarySubmitProps) => {
    setIsSubmittingResponses(true);
    if (isNotary) {
      createOrganizationNotaryMutateFn({
        variables: {
          input: {
            organizationId: organization.id,
            email: user.email!,
            roleSchedules: [
              {
                userRoleId: assignableRoles.find(
                  (role) => role.name === ASSIGNABLE_ROLE_NAMES.ADMIN,
                )?.id,
              },
            ],
            languages: [Language.EN],
            usStateId: notaryCommissionStateId!,
          },
        },
      });
    }

    return tagUserMutateFn({
      variables: {
        input: {
          userId: user.id,
          tag: SLIDESHOW_NOTARY_TAG,
        },
        tags: [SLIDESHOW_NOTARY_TAG],
      },
    }).finally(() => {
      setIsSubmittingResponses(false);
    });
  };

  const expectedVolume = user.expectedVolumeResponse?.[0]?.responses ?? [];

  const usStatesList =
    viewer.usStates?.map((usState) => ({
      label: usState!.name,
      value: usState!.id,
    })) ?? [];

  const notaryUsStates = viewer.notaryUsStates;
  // When a Title agency is created on the backend, it will default to the user's First and Last name so this makes
  // sure they've actually set a business name. Otherwise will be blank.
  // Can remove with REAL-9164 when Title agency name is required on sign up
  const hasBusinessName = organization.titleAgencyProfile?.name !== userFullName(user);

  const titleUnderwritersList = viewer.publicTitleUnderwriters.map(({ name, id }) => ({
    label: name!,
    value: id,
  }));

  const canInviteNotaries = hasPermissionFor("createOrgNotaries");
  const usesOrganizationNotaries = organization.featureList.includes(Feature.ORGANIZATION_NOTARIES);

  const showNotaryOnboardingCard =
    usesOrganizationNotaries && canInviteNotaries && !onBoardingCardShowed.current;

  return (showAccountOnboardingCards.current || showNotaryOnboardingCard) &&
    !isOnboardingDismissed ? (
    <>
      <Carousel
        numberOfSlides={
          2 * Number(showAccountOnboardingCards.current) + Number(showNotaryOnboardingCard)
        }
        disableNavigation={
          isSubmittingResponses || isSavingAccountDetails || isSavingEligibilityData
        }
        analyticsPrefix={SEGMENT_EVENTS.TITLE_AGENCY_ACCOUNT_SETUP_PREFIX}
        analyticsProps={{ pricing_plan_id: organization.activeTier.planId }}
        onFinalSlideNext={() => {
          if (organization.isSetup.complete) {
            setIsOnboardingDismissed(true);
            navigate(GET_STARTED_PATH);
          }
        }}
      >
        {({ onNext }) => (
          <>
            {showAccountOnboardingCards.current && (
              <Slide canSkip={false}>
                <TitleAccountOnboardingAccountSetupSlide
                  organization={organization}
                  expectedVolumeResponse={expectedVolume[0]}
                  loading={isSavingAccountDetails}
                  onFormSubmit={(formValues) =>
                    handleUpdateOrganization(formValues).finally(onNext)
                  }
                  hasBusinessName={hasBusinessName}
                />
              </Slide>
            )}
            {showAccountOnboardingCards.current && (
              <Slide>
                <PlaceOrderOnboardingEligibilities
                  usStatesList={usStatesList}
                  titleUnderwritersList={titleUnderwritersList}
                  hideCloseButton
                  organization={organization}
                  onFormSubmit={(formValues) =>
                    handleUpdateEligibilities(formValues).finally(onNext)
                  }
                  loading={isSavingEligibilityData}
                />
              </Slide>
            )}

            {showNotaryOnboardingCard && (
              <Slide>
                <NotarySlide
                  loading={isSavingAccountDetails}
                  notaryUsStates={notaryUsStates}
                  onSubmit={({ isNotary, notaryCommissionStateId }: NotarySubmitProps) => {
                    handleNotarySubmit({ isNotary, notaryCommissionStateId }).finally(onNext);
                  }}
                />
              </Slide>
            )}
          </>
        )}
      </Carousel>
      {children}
    </>
  ) : (
    <>{children}</>
  );
}

export function TitleAccountOnboarding() {
  const { viewer, refetch } = useViewer<TitleRouterViewer>();
  const organization = viewer.user?.organization;
  const intl = useIntl();

  const [activeOrganizationId] = useActiveOrganization();

  const { loading, data } = useQuery(TitleAccountOnboardingQuery, {
    variables: {
      organizationId: activeOrganizationId!,
    },
  });

  const steps = organization?.featureList.includes(Feature.ORGANIZATION_NOTARIES)
    ? TITLE_IHN_STEPS
    : TITLE_ODN_STEPS;

  const { currentStep, stepSvg, onNext } = useOnboardingSteps(steps, refetch);

  const renderCurrentStep = () => {
    if (!data || loading) {
      return <LoadingIndicator />;
    }

    if (data.organization?.__typename !== "Organization") {
      throw new Error(`Expected organization, got ${data.organization?.__typename}.`);
    }

    const { organization, viewer } = data;
    const { usStates: viewerStates, publicTitleUnderwriters, notaryUsStates, user } = viewer;
    const {
      id,
      usStates: orgStates,
      publicTitleAgency,
      defaultPayer,
      paymentSpecified,
      defaultPaymentSource,
      assignableRoles,
      featureList,
    } = organization;

    const isIhnEnabled = featureList.includes(Feature.ORGANIZATION_NOTARIES);

    switch (currentStep) {
      case STEPS.ELIGIBILITY:
        return (
          <EligibilityStep
            organizationId={id}
            usStateOptions={viewerStates}
            publicTitleUnderwriterOptions={publicTitleUnderwriters}
            usStatesFormData={orgStates}
            titleUnderwritersFormData={publicTitleAgency?.titleUnderwriters}
            onNext={onNext}
          />
        );
      case STEPS.PAYMENT:
        return (
          <PaymentStep
            portal="title"
            organizationId={id}
            onNext={onNext}
            defaultPayerFormData={paymentSpecified ? defaultPayer : null}
            defaultPaymentSourceFormData={defaultPaymentSource}
          />
        );
      case STEPS.IHN:
        return (
          <NotaryStep
            organizationId={id}
            onNext={onNext}
            notaryUsStates={notaryUsStates}
            roles={assignableRoles}
            userEmail={user?.email}
          />
        );
      case STEPS.INVITE:
        return (
          <InviteCoworkerStep
            portal="title"
            organizationId={id}
            onNext={onNext}
            onSkip={() =>
              segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_INVITE_ONBOARDING_SUCCEEDED, {
                skipped: true,
              })
            }
            notaryUsStates={notaryUsStates}
            roles={assignableRoles}
            isIhnEnabled={isIhnEnabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <OnboardingWrapper
      logo={<Svg src={ProofTitlePortalLight} alt={intl.formatMessage(MESSAGES.portalLogoAlt)} />}
      image={stepSvg}
      content={renderCurrentStep()}
      currentStep={currentStep}
      steps={steps}
    />
  );
}

export function TitleAccountOnboardingWrapper(props: WrapperProps) {
  const { children } = props;
  const { hasPermissionFor } = usePermissions();
  const { viewer } = useViewer<TitleRouterViewer>();
  const isSetup = viewer.user?.organization?.isSetup.complete;

  const redirectToOnboarding = !isSetup && hasPermissionFor("editOrganizationDetails");

  return redirectToOnboarding ? <Navigate to="/onboarding" /> : children;
}

export function DeprecatedTitleAccountOnboardingWrapper(props: DeprecatedWrapperProps) {
  const { showQueryLoader, children } = props;
  const titleOnboardingV2Enabled = useFeatureFlag("title-onboarding-v2");

  const [activeOrganizationId] = useActiveOrganization();
  const { loading, data } = useQuery(DeprecatedTitleAccountOnboardingQuery, {
    variables: {
      surveyQuestion: SurveyQuestions.MOST_IMPORTANT_FEATURES,
      organizationId: activeOrganizationId!,
      slideshowTags: [SLIDESHOW_NOTARY_TAG],
    },
  });

  if (!data || titleOnboardingV2Enabled) {
    return children || null;
  }

  if (loading) {
    return (
      <>
        {showQueryLoader && <LoadingIndicator />}
        {children}
      </>
    );
  }

  const organization = data.node;
  if (organization?.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization?.__typename}.`);
  }

  return (
    <DeprecatedLoadedTitleAccountOnboarding
      {...props}
      organization={organization}
      user={data.viewer.user!}
      viewer={data.viewer}
    />
  );
}
