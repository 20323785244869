import { FormattedMessage } from "react-intl";

import Link from "common/core/link";
import ProductTrainingImage from "assets/images/get_started/product-training.svg";

import { HowToItem } from "../common/how_to_item";

export function TitleOnboardingHowToItem() {
  return (
    <HowToItem
      img={{ src: ProductTrainingImage, alt: "" }}
      title={
        <FormattedMessage
          id="84889a64-7d79-4855-b11c-2948875b6bad"
          defaultMessage="Watch our on-demand training"
        />
      }
      info={
        <FormattedMessage
          id="134eacff-ad8b-4699-9412-afcfdb3e18a4"
          defaultMessage="Learn how to get set up and start using Proof to execute and close your own document packages."
        />
      }
      links={
        <>
          <Link href="https://www.notarize.com/academy/title-agent/product-onboarding">
            <FormattedMessage
              id="59c6accd-694e-405e-aae1-55268e22ba35"
              defaultMessage="Watch now"
            />
          </Link>
        </>
      }
    />
  );
}
