import { FormattedMessage } from "react-intl";

import DateTimeField from "common/form/fields/datetime";
import { LongFormattedDateTime } from "common/core/format/date";
import { composeValidators } from "util/form";
import { validatePresence, validateIf, validateTime, validateOption } from "validators/form";

type Props = {
  fieldPrefix: string;
  showEditTimes?: boolean;
  isEditing: boolean;
  handleEditAction: () => void;
  time: string;
  timezone: string;
  handleSave: () => void;
  formValues: Record<string, unknown>;
};

export function validationRules(props: { fieldPrefix?: string }) {
  return composeValidators(
    // formats
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validateTime({
        field: `${props.fieldPrefix}Hour`,
        label: "Time",
        hourField: `${props.fieldPrefix}Hour`,
        minuteField: `${props.fieldPrefix}Minutes`,
        meridiemField: `${props.fieldPrefix}Meridiem`,
      }),
    }),

    // presence
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Hour`, label: "Hour" }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Minutes`, label: "Minute" }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validateOption({
        field: `${props.fieldPrefix}Meridiem`,
        label: "AM/PM",
        format: { AM: "AM", PM: "PM" },
      }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Date`, label: "Date" }),
    }),
    validateIf({
      field: `${props.fieldPrefix}Toggle`,
      condition: (val) => Boolean(val),
      validation: validatePresence({ field: `${props.fieldPrefix}Timezone`, label: "Timezone" }),
    }),
  );
}

function DateTimeFormElements({
  showEditTimes = true,
  isEditing,
  handleEditAction,
  fieldPrefix,
  time,
  timezone,
  handleSave,
  formValues,
}: Props) {
  const formFields = isEditing ? (
    // @ts-expect-error -- this is typed incorrectly from javascript
    <DateTimeField
      formValues={formValues}
      fieldPrefix={fieldPrefix}
      containerClassname="form-fields"
      automationPrefix={fieldPrefix}
    />
  ) : null;

  const formAction = isEditing ? (
    <div
      className="form-action form-action--done"
      data-automation-id="done-link"
      onClick={handleSave}
    >
      <FormattedMessage id="44cb6e39-2590-43df-ae10-cae856f892b7" defaultMessage="Done" />
    </div>
  ) : (
    <div
      className="form-action form-action--edit"
      data-automation-id="edit-link"
      onClick={handleEditAction}
    >
      <FormattedMessage id="fa995ad4-a04c-4a49-8651-67823b8d611a" defaultMessage="Edit" />
    </div>
  );

  return (
    <div>
      <div className="form-control">
        <div className="datetime-label">
          <LongFormattedDateTime value={time} asTimeZone={timezone} />
        </div>
        {showEditTimes && formAction}
      </div>
      {formFields}
    </div>
  );
}

export default DateTimeFormElements;
