import { type ReactNode } from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import Modal from "common/modal";

import { type OrganizationTransactionDetailsHeader as OrganizationTransaction } from "../header/index_fragment.graphql";
import Styles from "./index.module.scss";
import { TransactionDetailsHeader } from "../header";

type TransactionDetailsWrapperProps = {
  children: ReactNode;
  className?: string;
  closeRoute: string;
  transaction: OrganizationTransaction;
  transactionDetailsTabs: ReactNode;
  transactionDetailsActions?: ReactNode;
  showActionErrorMessage?: boolean;
};

const MESSAGES = defineMessages({
  title: {
    id: "d6fd35c3-d1ba-45e0-baa7-dc6bb277cd74",
    defaultMessage: "Transaction Details",
  },
});
/**
 * @description
 * This is a component that wraps the transaction details and handles its styles and layout.
 * It displays the transaction details in a modal or page depending on if the txn details redesign
 * flag is on or off.
 */
export function TransactionDetailsWrapper({
  children,
  className,
  closeRoute,
  transaction,
  transactionDetailsTabs,
  transactionDetailsActions,
  showActionErrorMessage,
}: TransactionDetailsWrapperProps) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  // if the txn details redesign flag is on, show it on it's own page
  // if the txn details redesign flag is off, show it in the old modal
  return isTxnDetailsRedesign ? (
    <div className={classnames(Styles.layout, className)}>
      <TransactionDetailsHeader
        transaction={transaction}
        transactionDetailsActions={transactionDetailsActions}
        showActionErrorMessage={showActionErrorMessage}
      />
      <div className={Styles.detailsTabs}>{transactionDetailsTabs}</div>
      <div className={Styles.detailsContainer}>
        <div className={Styles.detailsWrapper}>{children}</div>
      </div>
    </div>
  ) : (
    <Modal
      title={intl.formatMessage(MESSAGES.title)}
      className={classnames(Styles.deprecatedModal, className)}
      closeRoute={closeRoute}
    >
      {transactionDetailsTabs}
      {children}
    </Modal>
  );
}
